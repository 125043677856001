import styled from 'styled-components';
import Icon from 'components/UI/Icon';

export const Container = styled.div`
  position: relative;
  z-index: 9999;
`;

export const UserName = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #333333;
`

export const MenuIcon = styled.button`
  position: relative;
  display: grid;
  align-items: center;
  height: 50px;
  width: 50px;
  background: #ffffff;
  border-radius: 6px;
  transition: 200ms;
  box-shadow: 0px 0px 10px ${({ shadow }) => (shadow ? ' #5a7fcc' : 'transparent')};
  border: 1px solid #5a7fcc;

  &:hover {
    box-shadow: 0px 0px 10px #5a7fcc;
  }
`;

export const Menu = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 3px 20px rgba(108, 139, 249, 0.6);
  border-radius: 6px;
`;

export const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #5a7fcc;
  padding: 15px 20px;
`;

export const MenuContent = styled.div`
  padding: 20px;
`
export const MenuButton = styled.button`
  display: grid;
  grid-template-columns: auto 200px auto;
  grid-gap: 0 10px;
  justify-items: start;
  align-items: center;
  padding: 5px 0;
  margin: 5px 0;
  width: max-content;
  transition: 0.2s;

  &:hover {
    color: ${({ theme }) => theme.color.darkGray};
  }

  &:disabled {
    color: ${({ theme }) => theme.color.gray};
  }
`;
export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.blue};
`
export const MenuButtonText = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-gap: 10px;
`
export const Indicator = styled.div`
  border-radius: 100%;
  background-color: ${({ theme }) => theme.color.red};
  min-width: 6px;
  min-height: 6px;
`
export const ExtendedMenuIcon = styled(Icon)``;
