import { combineReducers } from 'redux';

import { reducer as auth } from './ducks/auth.duck';
import { reducer as user } from './ducks/user.duck';
import { reducer as brigades } from './ducks/brigades.duck';
import { reducer as calc } from './ducks/calc.duck';
import { reducer as clientObjects } from './ducks/clientObjects.duck';
import { reducer as clients } from './ducks/clients.duck';
import { reducer as company } from './ducks/company.duck';
import { reducer as contractorTypes } from './ducks/contractorTypes.duck';
import { reducer as employees } from './ducks/employees.duck';
import { reducer as freelancers } from './ducks/freelancers.duck';
import { reducer as handbook } from './ducks/handbook.duck';
import { reducer as invoiceData } from './ducks/invoiceData.duck';
import { reducer as legalEntities } from './ducks/legalEntities.duck';
import { reducer as modal } from './ducks/modal.duck';
import { reducer as orderItems } from './ducks/orderItems.duck';
import { reducer as orders } from './ducks/orders.duck';
import { reducer as orderSchedule } from './ducks/orderSchedule.duck';
import { reducer as positions } from './ducks/positions.duck';
import { reducer as recycleBin } from './ducks/recycleBin.duck';
import { reducer as schedule } from './ducks/schedule.duck';
import { reducer as knowledge } from './ducks/knowledge.duck';
import { reducer as tags } from './ducks/tags.duck';
import { reducer as ordersSchedule } from './ducks/ordersSchedule.duck';
import { reducer as statuses } from './ducks/statuses.duck';
import { reducer as branches } from './ducks/branches.duck';
import { reducer as managerSchedule } from './ducks/managerSchedule.duck';
import { reducer as userNotifications } from './ducks/userNotifications.duck';
import { reducer as application } from './ducks/application.duck';
import { reducer as role } from './ducks/role.duck';
import { reducer as payment } from './ducks/payment.duck';
import { reducer as TMCSettings } from './ducks/TMCSettings.duck';
import { reducer as purchase } from './ducks/purchase.duck';
import { reducer as orderObjectCard } from './ducks/orderObjectCard.duck';
import { reducer as marginReport } from './ducks/marginReport.duck';
import { reducer as clientDocuments } from './ducks/clientDocuments.duck';
import { reducer as notificationTemplates } from './ducks/notificationsSettings.duck';
import { reducer as entityTariffs } from './ducks/entityTariffs.duck.ts';

const reducers = combineReducers({
  auth,
  user,
  brigades,
  calc,
  clientObjects,
  clients,
  company,
  contractorTypes,
  employees,
  freelancers,
  handbook,
  invoiceData,
  legalEntities,
  modal,
  orderItems,
  orders,
  orderSchedule,
  positions,
  recycleBin,
  schedule,
  knowledge,
  tags,
  ordersSchedule,
  statuses,
  branches,
  managerSchedule,
  userNotifications,
  application,
  role,
  payment,
  TMCSettings,
  purchase,
  orderObjectCard,
  marginReport,
  clientDocuments,
  notificationTemplates,
  entityTariffs,
});

export default function rootReducer(state, action) {
  if (action.type === 'auth/logoutSuccess') {
    return reducers(undefined, action);
  }
  return reducers(state, action)
}
