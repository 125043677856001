import {
  createOrderItem,
  updateOrderItem,
  deleteOrderItem,
  calculateOrderItems,
  updateOrderItemsGroup,
  updateOrderItemsData,
  getTime,
  updateOrderItemsTemp,
} from 'api/orderItems.api';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';
// Ducks
import { effects as ordersEffects } from './orders.duck';
import { effects as modalEffects } from './modal.duck';

export const options = {
  name: 'orderItems',
  initialState: {
    errorMessage: '',
    loading: false,
  },
  actions: {
    // Create
    createOrderItemRequest: () => state => ({
      ...state,
      loading: true,
    }),
    createOrderItemSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    createOrderItemFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    // Update
    updateOrderItemRequest: () => state => ({
      ...state,
      loading: true,
    }),
    updateOrderItemSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    updateOrderItemFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    // Delete
    deleteOrderItemRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteOrderItemSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    deleteOrderItemFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    // Calculate
    calculateOrderItemsRequest: () => state => ({
      ...state,
      loading: true,
    }),
    calculateOrderItemsSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    calculateOrderItemsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    updateOrderItemsGroupRequest: () => state => ({
      ...state,
      loading: true,
    }),
    updateOrderItemsGroupSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    updateOrderItemsGroupFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    updateOrderItemsDataRequest: () => state => ({
      ...state,
      loading: true,
    }),
    updateOrderItemsDataSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    updateOrderItemsDataFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    updateOrderItemsTempRequest: () => state => ({
      ...state,
      loading: true,
    }),
    updateOrderItemsTempSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    updateOrderItemsTempFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
  },
  effects: {
    createOrderItem: ({ orderId, ...data }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.createOrderItemRequest());
      try {
        const response = await createOrderItem(orderId, data);
        dispatch(duckActions.createOrderItemSuccess());
        getTime(response.data.id)
        toast.success('Вид услуги создан');
        await dispatch(ordersEffects.fetchOneOrder(orderId));
      } catch (error) {
        dispatch(duckActions.createOrderItemFailure(error));
      }
    },
    updateOrderItem: ({ id, orderId, ...data }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.updateOrderItemRequest());
      try {
        await updateOrderItem(id, data);
        getTime(id)
        dispatch(duckActions.updateOrderItemSuccess());
        toast.success('Вид услуги изменен');
        await dispatch(ordersEffects.fetchOneOrder(orderId));
      } catch (error) {
        dispatch(duckActions.updateOrderItemFailure(error));
      }
    },
    deleteOrderItem: ({ id, orderId }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deleteOrderItemRequest());
      try {
        const response = await deleteOrderItem(id);
        dispatch(duckActions.deleteOrderItemSuccess(response));
        toast.success('Вид услуги удален');
        if (orderId) await dispatch(ordersEffects.fetchOneOrder(orderId));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteOrderItemFailure(error));
      }
    },
    calculateOrderItems: ({ id, orderId, ...data }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.calculateOrderItemsRequest());
      try {
        const response = await calculateOrderItems(id, data);
        dispatch(duckActions.calculateOrderItemsSuccess(response));
        toast.success('Изменения сохранены');
        if (orderId) await dispatch(ordersEffects.fetchTotalOrderResult(orderId));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.calculateOrderItemsFailure(error));
      }
    },
    updateOrderItemsGroup: ({ id, orderId, data }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.updateOrderItemsGroupRequest());
      try {
        await updateOrderItemsGroup(id, data);
        dispatch(duckActions.updateOrderItemsGroupSuccess());
        if (orderId) await dispatch(ordersEffects.fetchTotalOrderResult(orderId));
        toast.success('Изменения сохранены');
      } catch (error) {
        dispatch(duckActions.updateOrderItemsGroupFailure(error));
      }
    },
    updateOrderItemsData: ({ id, orderId, result }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.updateOrderItemsDataRequest());
      try {
        await updateOrderItemsData(id, result);
        dispatch(duckActions.updateOrderItemsDataSuccess());
        if (orderId) await dispatch(ordersEffects.fetchTotalOrderResult(orderId));
        dispatch(modalEffects.closeModal());
        toast.success('Изменения сохранены');
      } catch (error) {
        dispatch(duckActions.updateOrderItemsDataFailure(error));
      }
    },
    updateOrderItemsTemp: ({ id, data }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.updateOrderItemsTempRequest());
      try {
        await updateOrderItemsTemp(id, data);
        dispatch(duckActions.updateOrderItemsTempSuccess());
        // if (orderId) await dispatch(ordersEffects.fetchTotalOrderResult(orderId));
        toast.success('Изменения сохранены');
      } catch (error) {
        dispatch(duckActions.updateOrderItemsTempFailure(error));
      }
    }
  },
  selectors: {
    getError: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
