import { getPositions, createPosition, deletePosition } from 'api/positions.api';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';
import { effects as modalEffects } from './modal.duck';

import { actions as handbookActions } from './handbook.duck';

export const options = {
  name: 'positions',
  initialState: {
    errorMessage: '',
    isLoading: false,
    positions: [],
    id: '',
  },
  actions: {
    getPositionsRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getPositionsSuccess: positions => state => ({
      ...state,
      positions,
      isLoading: false,
    }),
    getPositionsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    createPositionRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    createPositionSuccess: data => state => ({
      ...state,
      isLoading: false,
      id: data.data.id,
    }),
    createPositionFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    deletePositionRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    deletePositionSuccess: id => state => ({
      ...state,
      isLoading: false,
      positions: state.positions.filter(item => item.id !== id)
    }),
    deletePositionFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
  },
  effects: {
    getPositions: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getPositionsRequest());
      try {
        const { items } = await getPositions();
        dispatch(duckActions.getPositionsSuccess(items));
      } catch (error) {
        dispatch(duckActions.getPositionsFailure(error));
      }
    },
    createPosition: data => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.createPositionRequest());
      try {
        const response = await createPosition(data);
        dispatch(duckActions.createPositionSuccess(response));

        dispatch(handbookActions.setNewPosition(response.data));
        return response.data;
      } catch (error) {
        dispatch(duckActions.createPositionFailure(error));
        return error;
      }
    },
    deletePosition: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deletePositionRequest());
      try {
        const response = await deletePosition(id);

        if (response.success) {
          toast.success('Должность удалена')
          dispatch(handbookActions.deletePosition(id));
          dispatch(modalEffects.closeModal());
        }
      } catch (error) {
        dispatch(duckActions.deletePositionFailure(error));
        return error;
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    getIsLoading: (getState, createSelector) => createSelector([getState], s => s.isLoading),
    getPositions: (getState, createSelector) => createSelector([getState], s => s.positions),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
