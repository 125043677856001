import { createSelector } from 'reselect';
import { NODE_ENV } from 'constants';

const invariant = (condition, message) => {
  if (NODE_ENV === 'development') {
    if (!condition) {
      throw new Error(message);
    }
  }
};

export const createDuck = ({
  initialState,
  rootSelector = null,
  name,
  actions,
  effects,
  selectors,
  externalActionHandlers = null,
}) => {
  // Build the action creators using a namespaced
  // action type
  const duckActions = {};
  Object.keys(actions).forEach(type => {
    duckActions[type] = payload => {
      return {
        payload,
        type: actionType,
      }
    };
    const actionType = `${name}/${type}`;
    duckActions[type].toString = () => actionType;
    duckActions[type].actionType = actionType;
  });

  // Build selectors giving them access to the state and each other
  const duckSelectors = {};
  const selector = rootSelector || (s => s?.[name]);
  const getDuckState = createSelector([selector], state => {
    invariant(
      state,
      `You have a missing reducer for duck: ${name}.${'\n'}Register it in the page's <Provider> component.`
    );
    return state;
  });
  Object.keys(selectors).forEach(type => {
    duckSelectors[type] = selectors[type](getDuckState, createSelector, duckSelectors);
  });

  // Build the effects by passing them the internal
  // actions and effects
  const duckEffects = {};
  Object.keys(effects).forEach(type => {
    duckEffects[type] = payload => (dispatch, getState, extraArgs) => {
      const effect = effects[type](payload);
      return effect(dispatch, getState, duckActions, duckEffects, duckSelectors, extraArgs);
    };
  });

  // Create the reducer
  const reducer = (state = initialState, action) => {
    if (externalActionHandlers && externalActionHandlers[action.type]) {
      return externalActionHandlers[action.type](action)(state);
    }
    if (action.type.includes(name)) {
      const actionType = action.type.replace(`${name}/`, '');
      const reduce = actions[actionType];
      if (reduce) {
        return reduce(action.payload)(state);
      }
    }
    return state;
  };

  return {
    actions: duckActions,
    effects: duckEffects,
    selectors: duckSelectors,
    reducer,
  };
};
