import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';

import { primaryTheme, GlobalStyles } from 'styles';
import { configureStore } from 'redux/configureStore';
import history from 'utils/history';
import Modal from 'components/modals/Modal';
import { ToastContainer } from 'components/toasts';
import Routes from './routes/index';

const { store } = configureStore();

const App = () => {
  return (
    <Provider store={store}>
      <GlobalStyles/>
      <ThemeProvider theme={primaryTheme}>
        <Router history={history}>
          <Routes />
        </Router>
        <Modal />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  );
};

export default hot(App);
