import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { NODE_ENV } from 'constants';

import rootReducer from './rootReducer';

const IS_DEV = NODE_ENV === 'development';

const middlewareDev = composeWithDevTools(applyMiddleware(thunk));

const middleware = compose(applyMiddleware(thunk));

export const configureStore = initialState => {
  // eslint-disable-next-line no-undef
  const composedEnchancers = IS_DEV ? middlewareDev : middleware;

  const store = createStore(rootReducer, initialState, composedEnchancers);

  return { store };
};
