import {
  getNotifications,
  editNotifications,
} from 'api/notificationsSettings.api';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';

const parseUnproccesableEntityError = (notificationId, userId, notifications = [], users = [],) => {
  const errorNotification = notifications.find(item => item.id === notificationId);
  const errorUser = users.find(user => user.id === userId);

  if (errorNotification && errorNotification.title) {
    if (errorUser && errorUser.name) toast.error(`Для уведомления ${errorNotification.title} нельзя назначить сотрудника ${errorUser.name}`);
    else toast.error(`Для уведомления ${errorNotification.title} нельзя назначить удалённого сотрудника`);
  }
}

export const options = {
  name: 'notificationTemplates',
  initialState: {
    notifications: {},
    errorMessage: '',
    isLoading: false,
    overtime: {
      from: '20:00',
      to: '8:00',
    },
    inspectionNotificationHour: 1,
    orderNotificationHour: 1,
  },
  actions: {
    getNotificationsRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getNotificationsSuccess: ({ data }) => state => ({
      ...state,
      notifications: data,
      inspectionNotificationHour: data.templatesForClient[0]?.settings[0]?.value || '',
      orderNotificationHour: data.templatesForClient[1]?.settings[0]?.value || '',
      isLoading: false,
    }),
    getNotificationsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    editNotificationsRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    editNotificationsSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    editNotificationsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
  },
  effects: {
    getNotifications: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getNotificationsRequest());
      try {
        const response = await getNotifications();
        dispatch(duckActions.getNotificationsSuccess(response));
      } catch (error) {
        dispatch(duckActions.getNotificationsFailure(error));
      }
    },
    editNotifications: ({ submitAction, isRegister, ...data }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.editNotificationsRequest());
      const state = getState();
      const notifications = state.notificationTemplates.notifications;
      const users = state.employees.employees;

      try {
        const { success } = await editNotifications(data);
        dispatch(duckActions.editNotificationsSuccess());

        if (success) {
          toast.success('Изменения сохранены');
          if (!isRegister) await dispatch(duckEffects.getNotifications());
          if (submitAction) submitAction();
        }
      } catch (error) {
        if (error.status === 422) parseUnproccesableEntityError(error.notificationId, error.userId, notifications.templatesForEmployee, users,);
        dispatch(duckActions.editNotificationsFailure(error));
      }
    },
  },
  selectors: {
    getNotifications: (getState, createSelector) => createSelector([getState], s => s.notifications),
    getIsLoading: (getState, createSelector) => createSelector([getState], s => s.isLoading),
    getError: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    getOvertime: (getState, createSelector) => createSelector([getState], s => s.overtime),
    getInspectionNotificationHour: (getState, createSelector) => createSelector([getState], s => s.inspectionNotificationHour),
    getOrderNotificationHour: (getState, createSelector) => createSelector([getState], s => s.orderNotificationHour),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
