const getCookieByName = (name: string, cookieString: string): ?string => {
  // https://stackoverflow.com/a/15724300/796347
  const value = `; ${cookieString}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length >= 2)
    return parts
      .pop()
      .split(';')
      .shift();
  return undefined;
};

export default getCookieByName;
