export const primaryTheme = {
  color: {
    // BLACK
    pureBlack: '#000000',
    black: '#333333',
    lightBlack: '#444444',
    // GRAY
    pureDarkGray: '#939393',
    darkGray: '#939393',
    mountainMist: '#919398',
    gray: '#bfbfbf',
    lightGray: '#e8e8e8',
    slateGray: '#d5d5d5',
    dim: '#c1c1c1',
    silver: '#c4c4c4',
    sashaGray: '#f5f5f5',
    // WHITE
    white: '#ffffff',
    whiteSmoke: '#f6f6f6',
    whiteGhost: '#f2f2f2',
    // BLUE
    navi: '#587DFF', // '#003ecb',
    lightBlue: '#0487FF',
    blue: '#6C8BF9', // '#0487FF',
    azure: '#eef7ff',
    skyBlue: '#ecf2ff',
    paleBlue: '#5572ce',
    richBlue: '#354ea3',
    iceBlue: '#d3e3ff',
    steelBlue: '#bbd4ff',
    // RED
    red: '#ff5b5b',
    darkRed: '#e13b3b',
    // VIOLET
    violet: '#8a80ff',
    violetHover: '#956ee7',
    violetBlue: '#354da3',
    littleBoyBlue: '#75a4eb',
    // BROWN
    beige: '#fff4cc',
    wheat: '#faf1d1',
    // PINK
    dirtyPink: '#ebdef5',
    // GREEN
    modernGreen: '#cae8d8',
    silverTree: '#65b088',
    tinyGreen: '#fafdfb'
  },
  fontSize: {
    XS: '10px',
    S: '12px',
    SM: '13px',
    M: '14px',
    XM: '17px',
    L: '19px',
    XL: '24px',
    XXL: '36px',
    XXXL: '48px',
    MAX: '64px',
    mobile: {
      M: '16px',
      L: '17px',
      XL: '30px',
    },
  },
  fontWeight: {
    LIGHTEST: '200',
    LIGHT: '300',
    NORMAL: 'normal',
    HEAVY: '500',
    SEMI_BOLD: '600',
    BOLD: '700',
    BOLDEST: '900',
  },
  shadow: {
    main: '0px 4px 26px 0px rgba(0,0,0,0.25)',
  },
};
