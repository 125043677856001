import { account } from 'utils/axiosInstances';
import { camelize } from 'utils/objectUtilities';

const URL = '/orders/schedule';

export const getOrdersSchedule = async ({ date, brigade }) => {
  try {
    const response = await account.get(
      `${URL}?date=${date}${brigade ? `&brigade=${brigade}` : ''}`
    );
    return camelize(response, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const getOrderSchedule = async (id) => {
  try {
    const response = await account.get(`/orders/${id}/schedule`);
    return camelize(response, true);
  } catch (error) {
    const { response } = error
    throw new Error(response.message, response.status)
  }
}
