import { account } from 'utils/axiosInstances';
import { snakeify, camelize } from 'utils/objectUtilities';

const purchaseURL = '/purchase-order';
const ordersURL = '/orders';

export const getPurchaseOrder = async orderId => {
  try {
    const response = await account.get(`orders/${orderId}${purchaseURL}`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};
export const createSupply = async data => {
  try {
    const response = await account.post(`${purchaseURL}/item`, snakeify(data, false));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const editSupply = async (id, data) => {
  try {
    const response = await account.put(`${purchaseURL}/item/${id}`, snakeify(data, false));
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const deleteSupply = async id => {
  try {
    const response = await account.delete(`${purchaseURL}/item/${id}`);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const updateReceivers = async (id, data) => {
  try {
    const response = await account.put(`${ordersURL}/${id}${purchaseURL}`, snakeify(data, false));
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const deletePurchaseOrder = async id => {
  try {
    const response = await account.delete(`${ordersURL}/${id}${purchaseURL}`);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const editTechnologySupplyCost = async (id, data) => {
  try {
    const response = await account.patch(`/order-items/${id}/update-supplies-cost`, snakeify(data, false))
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const recalcPurchaseOrder = async orderId => {
  try {
    const response = await account.get(`${ordersURL}/${orderId}${purchaseURL}/recalc`)
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}