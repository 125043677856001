import loadable from '@loadable/component';

const EntitiesPage = loadable(() =>
    import('pages/EntitiesPage'));
const EntityPage = loadable(() =>
    import('pages/EntityPage'));
const UserSettings = loadable(() =>
    import('pages/UserSettingsPage'));
const EmployeesPage = loadable(() =>
    import('pages/EmployeesPage'));
const EmployeePage = loadable(() =>
    import('pages/EmployeePage'));
const WeekendSchedule = loadable(() =>
    import('pages/WeekendSchedulePage'));
const RecycleBin = loadable(() =>
    import('pages/RecycleBinPage'));
const Orders = loadable(() =>
    import('pages/OrdersPage'));
const Order = loadable(() =>
    import('pages/OrderPage'));
const OrderSchedule = loadable(() =>
    import('pages/OrderSchedulePage'));
const OrderCard = loadable(() =>
    import('pages/OrderCardPage'));
const Clients = loadable(() =>
    import('pages/ClientsPage'));
const Client = loadable(() =>
    import('pages/ClientPage'));
const ClientCard = loadable(() =>
    import('pages/ClientCardPage'));
const OrdersSchedule = loadable(() =>
    import('pages/OrdersSchedulePage'));
const Knowledge = loadable(() =>
    import('pages/KnowledgePage'));
const EditBranch = loadable(() =>
    import('pages/EditBranchPage'));
const ManagerSchedule = loadable(() =>
    import('pages/ManagerSchedulePage'));
const Notifications = loadable(() =>
    import('pages/NotificationsPage'));
const Application = loadable(() =>
    import('pages/ApplicationPage'));
const PaymentSuccess = loadable(() =>
    import('pages/PaymentSuccessPage'))
const PaymentFailure = loadable(() =>
    import('pages/PaymentFailurePage'))
const Role = loadable(() =>
    import('pages/RolePage'))
const CompanySettings = loadable(() =>
    import('pages/CompanySettingsPage'));
const SystemSettings = loadable(() =>
    import('pages/SystemSettingsPage'));
const TMCSettings = loadable(() =>
    import('pages/TMCSettingsPage'));
const MarginIncomeReport = loadable(() =>
    import('pages/MarginIncomeReportPage'));
const News = loadable(() =>
    import('pages/NewsPage'));
const NewsCard = loadable(() =>
    import('pages/NewsCardPage'));
const Tariff = loadable(() =>
    import('pages/TariffPage'));

export default {
    EntitiesPage,
    EntityPage,
    UserSettings,
    EmployeesPage,
    EmployeePage,
    WeekendSchedule,
    RecycleBin,
    Orders,
    Order,
    OrderSchedule,
    OrderCard,
    Clients,
    Client,
    ClientCard,
    OrdersSchedule,
    Knowledge,
    EditBranch,
    ManagerSchedule,
    Notifications,
    Application,
    PaymentSuccess,
    PaymentFailure,
    Role,
    CompanySettings,
    SystemSettings,
    TMCSettings,
    MarginIncomeReport,
    News,
    NewsCard,
    Tariff
};
