import React from 'react';
import { Route } from 'react-router-dom';

// import { getCookie } from 'utils/cookie';
import PublicTemplate from 'templates/PublicTemplate';

export default function PublicRoute({ component: Component, smallMargin = false, ...rest }) {
  // const accessToken = getCookie('token');
  // if (accessToken) {
  //   return <Redirect to="/orders" />;
  // }
  return (
    <Route
      {...rest}
      component={() => (
        <PublicTemplate smallMargin={smallMargin}>
          <Component />
        </PublicTemplate>
      )}
    />
  );
}
