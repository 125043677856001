import { account } from 'utils/axiosInstances';
import { snakeify, camelize } from 'utils/objectUtilities';
import { getPaginationParams } from './utils/paginationUtils';

const URL = '/freelance-staffes';

export const getFreelancers = async (params = { limit: 0, offset: 1 }) => {
  try {
    const response = await account.get(`${URL}?${getPaginationParams(params.offset, params.limit)}`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error
    throw new Error(response.data.message)
  }
};

export const getFreelancer = async id => {
  try {
    const response = await account.get(`${URL}/${id}`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error
    throw new Error(response.data.message)
  }
};

export const createFreelancer = async newFreelancerData => {
  try {
    const response = await account.post(URL, snakeify(newFreelancerData, true));
    return camelize(response.data, true);
  } catch ({ response }) {
    throw response.data
  }
};

export const updateFreelancer = async (id, payload) => {
  try {
    const response = await account.put(`${URL}/${id}`, snakeify(payload), true);
    return camelize(response.data, true);
  } catch ({ response }) {
    throw response.data
  }
};

export const deleteFreelancer = async id => {
  try {
    const response = await account.delete(`${URL}/${id}`);

    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
