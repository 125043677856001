import moment from 'moment';
import {
  getSchedule,
  getFreelancersSchedule,
  setOrderSchedule,
  getOrderSchedule,
  getOrderWorkingHours,
  setOrderWorkingHours,
  editOrderWorkingHours,
} from 'api/schedule.api';
import {
  setOrderStatus
} from 'api/orders.api';

import { toast } from 'react-toastify';

import history from 'utils/history';
import { effects as modalEffects } from './modal.duck';
import { effects as ordersScheduleEffects } from './ordersSchedule.duck';
import { selectors as handbookSelectors } from 'redux/ducks/handbook.duck';
import { selectors as userSelectors } from 'redux/ducks/user.duck'
import { createDuck } from './utils/createDuck';

export const options = {
  name: 'orderSchedule',
  initialState: {
    schedule: [],
    freelancersSchedule: [],
    workSchedule: [],
    workingHours: [],
    errorMessage: '',
    loading: false,
    freelancersScheduleLoading: false,
    freelancersScheduleErrorMessage: '',
    orderScheduleErrorMessage: '',
    orderScheduleLoading: false,
    orderScheduleLoaded: false,
    workScheduleLoading: false,
    workScheduleErrorMessage: '',
    workingHoursLoading: false,
    workingHoursErrorMessage: '',
  },
  actions: {
    getScheduleRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getScheduleSuccess: data => state => ({
      ...state,
      loading: false,
      schedule: data,
    }),
    getScheduleFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    getFreelancersScheduleRequest: () => state => ({
      ...state,
      freelancersScheduleLoading: true,
    }),
    getFreelancersScheduleSuccess: data => state => ({
      ...state,
      freelancersScheduleLoading: false,
      freelancersSchedule: data,
    }),
    getFreelancersScheduleFailure: ({ message }) => state => ({
      ...state,
      freelancersScheduleErrorMessage: message,
      freelancersScheduleLoading: false,
    }),
    getWorkScheduleRequest: () => state => ({
      ...state,
      workScheduleLoading: true,
    }),
    getWorkScheduleSuccess: data => state => ({
      ...state,
      workScheduleLoading: false,
      workSchedule: data,
    }),
    getWorkScheduleFailure: ({ message }) => state => ({
      ...state,
      workScheduleErrorMessage: message,
      workScheduleLoading: false,
    }),
    getWorkingHoursRequest: () => state => ({
      ...state,
      workingHoursLoading: true,
    }),
    getWorkingHoursSuccess: data => state => ({
      ...state,
      workingHoursLoading: false,
      workingHours: data,
    }),
    getWorkingHoursFailure: ({ message }) => state => ({
      ...state,
      workingHoursErrorMessage: message,
      workingHoursLoading: false,
    }),
    setWorkingHoursRequest: () => state => ({
      ...state,
      workingHoursLoading: true,
    }),
    setWorkingHoursSuccess: data => state => ({
      ...state,
      workingHours: [
        ...state.workingHours,
        data
      ],
      workingHoursLoading: false,
    }),
    setWorkingHoursFailure: ({ message }) => state => ({
      ...state,
      workingHoursErrorMessage: message,
      workingHoursLoading: false,
    }),
    editWorkingHoursRequest: () => state => ({
      ...state,
      workingHoursLoading: true,
    }),
    editWorkingHoursSuccess: data => state => ({
      ...state,
      workingHours: [
        ...state.workingHours.filter(item => item.id !== data.id),
        data
      ],
      workingHoursLoading: false,
    }),
    editWorkingHoursFailure: ({ message }) => state => ({
      ...state,
      workingHoursErrorMessage: message,
      workingHoursLoading: false,
    }),
    setOrderScheduleRequest: () => state => ({
      ...state,
      orderScheduleLoaded: false,
      orderScheduleLoading: true,
    }),
    setOrderScheduleSuccess: () => state => ({
      ...state,
      orderScheduleLoaded: true,
      orderScheduleLoading: false,
    }),
    setOrderScheduleFailure: ({ message }) => state => ({
      ...state,
      orderScheduleLoaded: false,
      orderScheduleLoading: false,
      orderScheduleErrorMessage: message,
    }),
    setOrderScheduleErrorMessage: message => state => ({
      ...state,
      orderScheduleErrorMessage: message
    }),
    reset: () => state => ({ ...state, orderScheduleLoaded: false }),
  },
  effects: {
    getSchedule: payload => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getScheduleRequest());
      try {
        const { data } = await getSchedule(payload);
        dispatch(duckActions.getScheduleSuccess(data));
      } catch (error) {
        dispatch(duckActions.getScheduleFailure(error));
      }
    },
    getFreelancersSchedule: payload => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getFreelancersScheduleRequest());
      try {
        const { data } = await getFreelancersSchedule(payload);
        dispatch(duckActions.getFreelancersScheduleSuccess(data));
      } catch (error) {
        dispatch(duckActions.getFreelancersScheduleFailure(error));
      }
    },
    setOrderSchedule: ({ shouldRedirect, shouldStay, ...payload }) => async (
      dispatch,
      getState,
      duckActions,
    ) => {
      const { ordersSchedule, } = getState();
      const state = getState()
      const documentsTariffPerm = handbookSelectors.getDocumentsTariffPerm(state)
      const applicationFormUpdatePerm = userSelectors.getApplicationFormUpdateFullPerm(state) || userSelectors.getApplicationFormUpdatePartPerm(state)
      dispatch(duckActions.setOrderScheduleRequest());

      try {
        await setOrderSchedule(payload);
        await setOrderStatus(payload.orderId, { statusId: payload.data.length ? 2 : 11 })
        dispatch(duckActions.setOrderScheduleSuccess());

        if (payload.data.length) {
          toast.success('Заказ поставлен в график');
        }
        else {
          toast.success('Заказ удален из графика')
          dispatch(duckActions.getWorkScheduleSuccess([]))
          dispatch(modalEffects.closeModal())

          if (shouldStay) {
            dispatch(ordersScheduleEffects.getSchedule(ordersSchedule.scheduleDate))
          } else {
            history.push('/orders')
          }
        }

        dispatch(duckActions.reset());
        if (shouldRedirect && documentsTariffPerm && applicationFormUpdatePerm) history.push(`/orders/${payload.orderUuid}/application`);
      } catch (error) {
        dispatch(duckActions.setOrderScheduleFailure(error));
      }
    },
    getWorkSchedule: orderId => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getWorkScheduleRequest());
      try {
        const { data } = await getOrderSchedule(orderId);
        dispatch(duckActions.getWorkScheduleSuccess(data));
      } catch (error) {
        dispatch(duckActions.getWorkScheduleFailure(error));
      }
    },
    getWorkingHours: orderId => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getWorkingHoursRequest());
      try {
        const { data } = await getOrderWorkingHours(orderId);
        dispatch(duckActions.getWorkingHoursSuccess(data));
      } catch (error) {
        dispatch(duckActions.getWorkingHoursFailure(error));
      }
    },
    setWorkingHours: ({ orderId, payload }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.setWorkingHoursRequest());
      try {
        const { data } = await setOrderWorkingHours({ orderId, data: payload });
        dispatch(duckActions.setWorkingHoursSuccess(data));
        toast.success('Время установлено');
      } catch (error) {
        dispatch(duckActions.setWorkingHoursFailure(error));
      }
    },
    editWorkingHours: ({ orderId, hoursId, payload }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.editWorkingHoursRequest());
      try {
        const { data } = await editOrderWorkingHours({ orderId, hoursId, data: payload });
        dispatch(duckActions.editWorkingHoursSuccess(data));
        toast.success('Время изменено');
      } catch (error) {
        dispatch(duckActions.editWorkingHoursFailure(error));
      }
    },
  },
  selectors: {
    getOrderScheduleErrorMessage: (getState, createSelector) =>
      createSelector([getState], s => s.orderScheduleErrorMessage),
    getOrderScheduleLoading: (getState, createSelector) =>
      createSelector([getState], s => s.orderScheduleLoading),
    getOrderScheduleLoaded: (getState, createSelector) =>
      createSelector([getState], s => s.orderScheduleLoaded),
    getWorkScheduleErrorMessage: (getState, createSelector) =>
      createSelector([getState], s => s.workScheduleErrorMessage),
    getWorkScheduleLoading: (getState, createSelector) =>
      createSelector([getState], s => s.workScheduleLoading),
    getWorkSchedule: (getState, createSelector) => createSelector([getState], s => s.workSchedule),
    getWorkingHoursErrorMessage: (getState, createSelector) =>
      createSelector([getState], s => s.workingHoursErrorMessage),
    getWorkingHoursLoading: (getState, createSelector) =>
      createSelector([getState], s => s.workingHoursLoading),
    getWorkingHours: (getState, createSelector) => createSelector([getState], s => s.workingHours),
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
    getFreelancersScheduleErrorMessage: (getState, createSelector) =>
      createSelector([getState], s => s.freelancersScheduleErrorMessage),
    getFreelancersScheduleLoading: (getState, createSelector) =>
      createSelector([getState], s => s.freelancersScheduleLoading),
    getSchedule: (getState, createSelector) =>
      createSelector([getState], s => {
        const formattedSchedule = [];
        s.schedule.forEach(
          ({
            user,
            userId,
            date,
            hoursCount,
            hoursCountWithOrders,
            activityId,
            activityName,
            orderSchedule,
          }) => {
            const userCell = formattedSchedule.find(el => el.userId === userId);
            const newDay = {
              date,
              hoursCount,
              hoursCountWithOrders,
              activityId,
              activityName,
              orderSchedule,
              occupiedHoursFrom: orderSchedule.map(el =>
                moment(el.workFrom, 'HH:mm:ss').format('H:mm')
              ),
              occupiedHoursTo: orderSchedule.map(el =>
                moment(el.workTo, 'HH:mm:ss').format('H:mm')
              )
            };
            if (userCell) {
              userCell.days.push(newDay);
            } else {
              formattedSchedule.push({
                userId,
                user,
                days: [newDay],
              });
            }
          }
        );
        return formattedSchedule;
      }),
    getFreelancersSchedule: (getState, createSelector) =>
      createSelector([getState], s => {
        const formattedFreelanceSchedule = [];
        s.freelancersSchedule.forEach(
          ({
            user,
            userId,
            date,
            hoursCount,
            hoursCountWithOrders,
            activityId,
            activityName,
            orderSchedule,
          }) => {
            const userCell = formattedFreelanceSchedule.find(el => el.userId === userId);
            const newDay = {
              date,
              hoursCount,
              hoursCountWithOrders,
              activityId,
              activityName,
              occupiedHoursFrom: orderSchedule.map(el =>
                moment(el.workFrom, 'HH:mm:ss').format('H:mm')
              ),
              occupiedHoursTo: orderSchedule.map(el =>
                moment(el.workTo, 'HH:mm:ss').format('H:mm')
              )
            };
            if (userCell) {
              userCell.days.push(newDay);
            } else {
              formattedFreelanceSchedule.push({
                userId,
                user,
                days: [newDay],
              });
            }
          }
        );
        return formattedFreelanceSchedule;
      }),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
