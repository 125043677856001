import { getUser, editUser } from 'api/user.api';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';

const getUserPermission = (s, perm) => s.companyHasRoles ?
    (s.userPermission.find(({ permission }) => permission.slug === perm) || {}).existsForRole || false
    : true;

export const options = {
    name: 'user',
    initialState: {
        user: {},
        isLoading: false,
        userPermission: [],
        companyHasRoles: true,
        tariffType: 0,
        rolesTariffPerms: [0, 0, 0, 1, 1, 1],
        errorMessage: '',
    },
    actions: {
        fetchUserRequest: () => state => ({
            ...state,
            isLoading: true,
        }),
        fetchUserSuccess: user => state => ({
            ...state,
            user,
            isLoading: false,
        }),
        fetchUserFailure: ({ message }) => state => ({
            ...state,
            isLoading: false,
            message,
        }),
        editUserRequest: () => state => ({
            ...state,
            isLoading: true,
        }),
        editUserSuccess: () => state => ({
            ...state,
            isLoading: false,
        }),
        editUserFailure: ({ message }) => state => ({
            ...state,
            isLoading: false,
            message,
        }),
        setUserPermission: userPermission => state => ({
            ...state,
            userPermission,
        }),
        setUserNews: (newsCount) => state => ({
            ...state,
            user: {
                ...state.user,
                news: newsCount,
            }
        }),
        setTariffType: tariffType => state => ({
            ...state,
            tariffType,
            companyHasRoles: state.rolesTariffPerms[tariffType],
        }),
        setRolesTariffPerms: rolesTariffPerms => state => ({
            ...state,
            rolesTariffPerms,
            tariffgType: 22,
            companyHasRoles: rolesTariffPerms[state.tariffType],
        }),
    },
    effects: {
        fetchUser: () => async (dispatch, getState, duckActions) => {
            dispatch(duckActions.fetchUserRequest());
            try {
                const response = await getUser();

                dispatch(duckActions.fetchUserSuccess(response.data));
            } catch (error) {
                dispatch(duckActions.fetchUserFailure(error));
            }
        },
        editUser: payload => async (dispatch, getState, duckActions, duckEffects) => {
            dispatch(duckActions.editUserRequest());
            try {
                const response = await editUser(payload);

                if (response.success) {
                    dispatch(duckActions.editUserSuccess());
                    toast.success('Изменения сохранены');
                    await dispatch(duckEffects.fetchUser());
                } else {
                    throw new Error('Something went wrong');
                }
            } catch (error) {
                dispatch(duckActions.editUserFailure(error));
            }
        },
    },
    selectors: {
        getUser: (getState, createSelector) => createSelector([getState], s => s.user),
        getUserPermission: (getState, createSelector) => createSelector([getState], s => s.userPermission),
        getCompanyDataViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'company_data_view_full')),
        getLegalEntityViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'legal_entity_view_full')),
        getBranchesViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'company_branches_view_full')),
        getDocumentsViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'documents_view_full')),
        getDocumentsUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'settings_documents_update_full')),
        getSettingsCompanyUpdatePerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'settings_company_update_full')),
        getLegalEntityUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'settings_legal_entity_update_full')),
        getBranchesUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'company_branches_update_full')),
        getRolesUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'settings_users_roles_full')),
        getApplicationFormViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'application_form_view_full')),
        getApplicationFormViewPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'application_form_view_participant')),
        getApplicationFormUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'application_form_view_and_update_full')),
        getApplicationFormUpdatePartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'application_form_view_and_update_participant')),
        getOrderViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_view_full')),
        getOrderViewPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_view_participant')),
        getClientsListViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'client_list_view_full')),
        getClientsListViewPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'client_list_view_participant')),
        getClientsListCreateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'client_create_full')),
        getClientUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'client_view_and_update_full')),
        getClientUpdatePartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'client_view_and_update_participant')),
        getClientViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'client_view_full')),
        getClientViewPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'client_view_participant')),
        getSettingsBrigadesFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'settings_brigades_full')),
        getRecycleBinEditClientsPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'recycle_bin_deleting_and_restore_clients_participant')),
        getRecycleBinEditOrdersPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'recycle_bin_deleting_and_restore_orders_participant')),
        getOrderListViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_list_view_full')),
        getOrderListViewPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_list_view_participant')),
        getOrderListCreateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_list_create_full')),
        getOrderListCreatePartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_list_create_participant')),
        getOrderListDeleteFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_list_view_and_delete_full')),
        getOrderListDeletePartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_list_view_and_delete_participant')),
        getOrderDownloadPrintFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_download_print_full')),
        getOrderDownloadPrintPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_download_print_participant')),
        getOrderSendFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_send_full')),
        getOrderSendPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_send_participant')),
        getOrderUpdateAttendanceFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_create_and_update_attendance_full')),
        getOrderUpdateAttendancePartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_create_and_update_attendance_participant')),
        getKnowledgeBaseUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'knowledge_base_update_full')),
        getPurchaseOrderUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'purchase_order_create_and_update_full')),
        getPurchaseOrderUpdatePartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'purchase_order_create_and_update_participant')),
        getOrderInfoUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_info_create_and_update_full')),
        getOrderInfoUpdatePartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_info_create_and_update_participant')),
        getOrderScheduleViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_schedule_view_full')),
        getOrderScheduleViewPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_schedule_view_participant')),
        getOrderScheduleUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_schedule_view_and_update_full')),
        getOrderScheduleUpdatePartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_schedule_view_and_update_participant')),
        getTaskScheduleUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'task_schedule_create_update_and_view_full')),
        getTaskScheduleUpdatePartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'task_schedule_create_update_and_view_participant')),
        getTechStaffViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'staff_view_technical_full')),
        getAdminStaffViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'staff_view_employee_full')),
        getFreeStaffViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'staff_view_freelance_full')),
        getTechStaffUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'staff_create_update_and_view_technical_full')),
        getTechStaffUpdatePartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'staff_create_update_and_view_technical_participant')),
        getAdminStaffUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'staff_create_update_and_view_employee_full')),
        getFreeStaffUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'staff_create_update_and_view_freelance_full')),
        getTechStaffScheduleViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'technical_staff_schedule_view_full')),
        getTechStaffScheduleViewPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'technical_staff_schedule_view_participant')),
        getFreeStaffScheduleViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'freelance_staff_schedule_view_full')),
        getFreeStaffScheduleViewPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'freelance_staff_schedule_view_participant')),
        getTechStaffScheduleUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'technical_staff_schedule_view_and_update_full')),
        getTechStaffScheduleUpdatePartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'technical_staff_schedule_view_and_update_participant')),
        getFreeStaffScheduleUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'freelance_staff_schedule_update_full')),
        getFreeStaffScheduleUpdatePartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'freelance_staff_schedule_update_participant')),
        getRecycleBinEditOrdersFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'recycle_bin_deleting_and_restore_orders_full')),
        getRecycleBinEditClientsFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'recycle_bin_deleting_and_restore_clients_full')),
        getRecycleBinEditBranchesFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'recycle_bin_deleting_and_restore_branches_full')),
        getRecycleBinEditStaffFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'recycle_bin_deleting_and_restore_staff_full')),
        getRecycleBinEditFreeStaffFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'recycle_bin_deleting_and_restore_freelance_full')),
        getRecycleBinEditKnowledgeFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'recycle_bin_deleting_and_restore_knowledge_base_full')),
        getRecycleBinEditEntityFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'recycle_bin_deleting_and_restore_legal_entity_full')),
        getRecycleBinEditEquipmentFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'recycle_bin_deleting_and_restore_equipment_in_storage_full')),
        getTaskScheduleViewYourFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'task_schedule_view_your_full')),
        getTaskScheduleViewYourPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'task_schedule_view_your_participant')),
        getOrderCreateWorkScheduleFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_create_work_schedule_full')),
        getOrderCreateWorkSchedulePartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_create_work_schedule_participant')),
        getOrderPaymentUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_payment_create_and_update_full')),
        getOrderPaymentUpdatePartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_payment_create_and_update_participant')),
        getOrderUpdateBeforeSignedFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_update_application_form_before_signed_full')),
        getOrderUpdateBeforeSignedPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_update_application_form_before_signed_participant')),
        getOrderUpdateAfterSignedFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_update_application_form_after_signed_full')),
        getOrderUpdateAfterSignedPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_update_application_form_after_signed_participant')),
        getOrderMarginUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'marginal_income_update_full')),
        getOrderMarginUpdatePartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'marginal_income_update_participant')),
        getMarginIncViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'marginal_income_view_full')),
        getMarginIncViewPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'marginal_income_view_participant')),
        getMarginIncDownloadPrintFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'marginal_income_download_print_full')),
        getMarginIncDownloadPrintPartPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'marginal_income_download_print_participant')),
        getStatusesViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_statuses_view_full')),
        getStatusesUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'order_statuses_update_full')),
        getNotificationsViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'notifications_view_full')),
        getNotificationsUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'settings_notifications_update_full')),
        getRolesViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'users_roles_view_full')),
        getPaymentViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'payments_view_full')),
        getPaymentUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'settings_payments_full')),
        getKnowledgeBaseViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'knowledge_base_view_full')),
        getTMCSettingsViewFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'tmc_settings_view_full')),
        getTMCSettingsUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'tmc_settings_update_full')),
        getTMCNoQuantityUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'tmc_settings_without_quantity_view_and_update_full')),
        getTMCWithQuantityUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'tmc_settings_with_quantity_view_and_update_full')),
        getTMCAmortizationUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'tmc_settings_amortization_view_and_update_full')),
        getTMCNomenclatureUpdateFullPerm: (getState, createSelector) => createSelector([getState], s => getUserPermission(s, 'tmc_settings_nomenclature_view_and_update_full')),
        getIsLoading: (getState, createSelector) => createSelector([getState], s => s.isLoading),
        getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
