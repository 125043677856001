import { getInvoiceData, editInvoiceData, createInvoiceData } from 'api/invoiceData.api';
import { createDuck } from './utils/createDuck';

import type {
  ActionCreator,
  EffectCreator,
  SelectorCreator,
  DuckOptions,
} from './utils/createDuck';

export type State = {
  invoiceData: Object,
  errorMessage: string,
  loading: boolean,
};

type Actions = {
  fetchInvoiceRequest: ActionCreator<void>,
  fetchInvoiceSuccess: ActionCreator<Object>,
  fetchInvoiceFailure: ActionCreator<{ message: string }>,
  createInvoiceRequest: ActionCreator<void>,
  createInvoiceSuccess: ActionCreator<Object>,
  createInvoiceFailure: ActionCreator<{ message: string }>,
  editInvoiceRequest: ActionCreator<void>,
  editInvoiceSuccess: ActionCreator<Object>,
  editInvoiceFailure: ActionCreator<{ message: string }>,
};

type Effects = {
  getInvoiceData: EffectCreator<void>,
  editInvoiceData: EffectCreator<void>,
  createInvoiceData: EffectCreator<void>,
};

type Selectors = {
  getInvoiceData: SelectorCreator<Object>,
  isLoading: SelectorCreator<boolean>,
  getErrorMessage: SelectorCreator<string>,
};

type Options = DuckOptions<State, Actions, Effects, Selectors>;

export const options: Options = {
  name: 'invoiceData',
  initialState: {
    invoiceData: null,
    loading: false,
    errorMessage: '',
  },
  actions: {
    fetchInvoiceRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchInvoiceSuccess: invoiceData => state => ({
      ...state,
      invoiceData,
      loading: false,
    }),
    fetchInvoiceFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      message,
    }),
    editInvoiceRequest: () => state => ({
      ...state,
      loading: true,
    }),
    editInvoiceSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    editInvoiceFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      message,
    }),
    createInvoiceRequest: () => state => ({
      ...state,
      loading: true,
    }),
    createInvoiceSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    createInvoiceFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      message,
    }),
  },
  effects: {
    getInvoiceData: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchInvoiceRequest());
      try {
        const response = await getInvoiceData();
        dispatch(duckActions.fetchInvoiceSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchInvoiceFailure(error));
      }
    },
    createInvoiceData: payload => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.createInvoiceRequest());
      try {
        const response = await createInvoiceData(payload);
        if (response.success) {
          dispatch(duckActions.createInvoiceSuccess());
          await dispatch(duckEffects.getInvoiceData());
        } else {
          throw new Error('Something went wrong');
        }
      } catch (error) {
        dispatch(duckActions.createInvoiceFailure(error));
      }
    },
    editInvoiceData: payload => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.editInvoiceRequest());
      try {
        const response = await editInvoiceData(payload);
        if (response.success) {
          dispatch(duckActions.editInvoiceSuccess());
          await dispatch(duckEffects.getInvoiceData());
        } else {
          throw new Error('Something went wrong');
        }
      } catch (error) {
        dispatch(duckActions.editInvoiceFailure(error));
      }
    },
  },
  selectors: {
    getInvoiceData: (getState, createSelector) => createSelector([getState], s => s.invoiceData),
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
