import { getSchedule, createTask, editTask, deleteTask } from 'api/managerSchedule.api';
import { toast } from 'react-toastify';
import history from 'utils/history';
import { createDuck } from './utils/createDuck';
import { effects as modalEffects } from './modal.duck';

export const options = {
  name: 'managerSchedule',
  initialState: {
    schedule: [],
    errorMessage: '',
    errorMessages: {},
    loading: false,
  },
  actions: {
    getManagerScheduleRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getManagerScheduleSuccess: schedule => state => ({
      ...state,
      loading: false,
      schedule,
      errorMessage: '',
    }),
    getManagerScheduleFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    createManagerScheduleTaskRequest: () => state => ({
      ...state,
      loading: true,
    }),
    createManagerScheduleTaskSuccess: task => state => ({
      ...state,
      loading: false,
      schedule: [...state.schedule, task],
      errorMessage: '',
    }),
    createManagerScheduleTaskFailure: ({ errors }) => state => ({
      ...state,
      errorMessages: errors,
      loading: false,
    }),
    editManagerScheduleTaskRequest: () => state => ({
      ...state,
      loading: true,
    }),
    editManagerScheduleTaskSuccess: task => state => {
      const newSchedule = [...state.schedule];
      newSchedule.splice(
        newSchedule.findIndex(el => el.id === task.id),
        1,
        task
      );
      return {
        ...state,
        loading: false,
        schedule: newSchedule,
        errorMessage: '',
      };
    },
    editManagerScheduleTaskFailure: ({ errors }) => state => ({
      ...state,
      errorMessages: errors,
      loading: false,
    }),
    deleteManagerScheduleTaskRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteManagerScheduleTaskSuccess: id => state => ({
      ...state,
      loading: false,
      schedule: state.schedule.filter(el => el.id !== id),
      errorMessage: '',
    }),
    deleteManagerScheduleTaskFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    reset: () => () => options.initialState,
    resetErrors: () => state => ({ ...state, errorMessages: {} }),
  },
  effects: {
    getSchedule: payload => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getManagerScheduleRequest());
      try {
        const { data } = await getSchedule(payload);
        dispatch(duckActions.getManagerScheduleSuccess(data));
      } catch (error) {
        dispatch(duckActions.getManagerScheduleFailure(error));
      }
    },
    createTask: ({ isOffHours, schedulePayload, notificateUser = true, returnToOrders = false, order, ...payload }) => async (
      dispatch,
      getState,
      duckActions,
    ) => {
      dispatch(duckActions.createManagerScheduleTaskRequest());
      try {
        const { data } = await createTask(payload);
        dispatch(duckActions.createManagerScheduleTaskSuccess(data));

        if (notificateUser) {
          toast.success('Задача поставлена в график');

          if (isOffHours)
            toast.warn('Задача поставлена в нерабочее время');
        }

        dispatch(modalEffects.closeModal());
        if (data && data.id) {
          const element = document.getElementById(`task-${data.id}`);
          if (element) {
            const headerOffset = 60;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition - headerOffset;
            window.scrollTo(0, offsetPosition);
          }
        }

        if (returnToOrders) history.push(`/orders/${order.uuid}`)

        return { success: true, id: data.id }
      } catch ({ data }) {
        dispatch(duckActions.createManagerScheduleTaskFailure(data));
      }
    },

    editTask: ({ id, isOffHours, notificateUser = true, ...payload }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.editManagerScheduleTaskRequest());
      try {
        const { data } = await editTask(id, payload);
        dispatch(duckActions.editManagerScheduleTaskSuccess(data));
        if (notificateUser)
          toast.success('Задача изменена');
        if (isOffHours) toast.warn('Задача поставлена в нерабочее время');
        dispatch(modalEffects.closeModal());

        return { success: true }
      } catch ({ data }) {
        dispatch(duckActions.editManagerScheduleTaskFailure(data));
      }
    },
    deleteTask: ({ id, notificateUser = true }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deleteManagerScheduleTaskRequest());
      try {
        await deleteTask(id);
        dispatch(duckActions.deleteManagerScheduleTaskSuccess(id));
        if (notificateUser)
          toast.success('Задача удалена из графика');
        dispatch(modalEffects.closeModal());

        return { success: true }
      } catch (error) {
        dispatch(duckActions.deleteManagerScheduleTaskFailure(error));
      }
    },
    resetErrors: () => (dispatch, getState, duckActions) => {
      dispatch(duckActions.resetErrors());
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    getErrorMessages: (getState, createSelector) =>
      createSelector([getState], s => s.errorMessages),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
    getSchedule: (getState, createSelector) => createSelector([getState], s => s.schedule),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
