/* eslint-disable no-nested-ternary */
import { account } from 'utils/axiosInstances';
import { snakeify, camelize } from 'utils/objectUtilities';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { getPaginationParams } from './utils/paginationUtils';

const URL = '/orders';

class PaymentForbiddenError extends Error {
  constructor(message) {
    super(message);
    this.name = 'PaymentForbiddenError';
    this.statusCode = 403;
    this.type = 'warn';
  }
}
class PaymentServerError extends Error {
  constructor(message) {
    super(message);
    this.name = 'PaymentServerError';
    this.statusCode = 500;
    this.type = 'error';
  }
}
class UnprocessableEntityError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UnprocessableEntityError';
    this.statusCode = 422;
    this.type = 'error';
  }
}

const parsePaymentErrors = error => {
  const { response } = error
  const { status } = response;
  const message = response.data?.message || response.message;

  if (status === 403)
    throw new PaymentForbiddenError(message);
  else if (status === 500)
    throw new PaymentServerError(message);
  else if (status === 422)
    throw new UnprocessableEntityError(message);
  else
    throw new Error(response.message, response.status);
}

const parseStatusesErrors = error => {
  const { response } = error
  const { status } = response;
  let message = response.data?.message || response.message;

  if (response.data?.errors?.order) {
    message = response.data.errors.order[0] || '';
  }

  if (status === 403)
    throw new PaymentForbiddenError('Недостаточно прав для установки данного статуса');
  else if (status === 500)
    throw new PaymentServerError(message);
  else if (status === 422)
    throw new UnprocessableEntityError(message);
  else
    throw new Error(response.message, response.status);
}

export const fetchOrders = async (params = { limit: 0, offset: 1, }) => {
  try {
    const query =
      `${URL}?${getPaginationParams(params.offset, params.limit)}` +
      `${params.sortBy ? `&sort_by=${params.sortBy}` : ''}` +
      `${params.sortBy
        ? `&sort_direction=${typeof params.sortDirection === 'string'
          ? params.sortDirection
          : params.sortDirection
            ? 'desc'
            : 'asc'
        }`
        : ''
      }` +
      `${params.clientId ? `&client_id=${params.clientId}` : ''}` +
      `${params.employeeId ? `&employee_id=${params.employeeId}` : ''}` +
      `${params.companyObjectId ? `&company_object_id=${params.companyObjectId}` : ''}` +
      `${params.statusId ? `&status_id=${params.statusId}` : ''}` +
      `${params.statusesIds ? `&statuses_ids[]=${params.statusesIds.join('&statuses_ids[]=')}` : ''}`
    const response = await account.get(query);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const fetchOneOrder = async id => {
  try {
    const response = await account.get(`${URL}/${id}`);
    return camelize(get(response, 'data.data'), true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.status,);
  }
};

export const fetchOneOrderByFakeId = async fakeId => {
  try {
    const response = await account.get(`${URL}/by_fake_id/${fakeId}`);
    return camelize(get(response, 'data.data'), true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.status,);
  }
};

export const fetchOneOrderByUUID = async fakeId => {
  try {
    const response = await account.get(`${URL}/by_uuid/${fakeId}`);
    return camelize(get(response, 'data.data'), true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.status,);
  }
};

export const createOrder = async data => {
  try {
    const response = await account.post(URL, snakeify(data, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const updateOrder = async (id, data) => {
  try {
    const response = await account.put(`${URL}/${id}`, snakeify(data, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const deleteOrder = async id => {
  try {
    const response = await account.delete(`${URL}/${id}`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    if (get(response, 'data.errors.order', '')) {
      response.data.errors.order.forEach((text) => {
        toast.error(text)
      })
    }
    throw new Error(response.message, response.status);
  }
};

export const setOrderStatus = async (id, data) => {
  try {
    const response = await account.patch(`${URL}/${id}/set-status`, snakeify(data, true));
    return camelize(response.data, true);
  } catch (error) {
    parseStatusesErrors(error);
  }
};

export const fetchTotalOrderResult = async id => {
  try {
    const response = await account.get(`${URL}/${id}/total-results`);
    return camelize(get(response, 'data.data', {}), true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const fetchComposedInfo = async id => {
  try {
    const { data } = await account.get(`${URL}/${id}/composed-info`);
    return camelize(data.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const updateComposedInfo = async (id, data) => {
  try {
    const newData = {
      ...(!!data?.suppliesCost && { supplies_cost: data?.suppliesCost }),
      ...(!!data?.amortizationSum && { amortization_sum: data?.amortizationSum }),
      ...(!!data?.salarySum && { salary_sum: data?.salarySum }),
      ...(!!data?.contractorsSum && { contractors_sum: data?.contractorsSum }),
      ...(!!data?.driverSalary && { driver_salary: data?.driverSalary }),
      // ...(!!data?.driverHours && { driver_hours: data?.driverHours }),
      ...(!!data?.headSalarySum && { head_salary_sum: data?.headSalarySum }),
      ...(!!data?.fullPrice && { full_price: data?.fullPrice }),
      ...(!!data?.additionalStaffSalary && { additional_staff_salary: data?.additionalStaffSalary }),
      ...(!!data?.fuelExpenses && { fuel_expenses: data.fuelExpenses }),
      ...(!!data?.fuelExpensesChanged && { fuel_expenses_changed: data.fuelExpensesChanged }),
      ...(!!data?.taxForSalary && { tax_for_salary: data?.taxForSalary }),
      ...(!!data?.salarySumChanged && { salary_sum_changed: data?.salarySumChanged }),
      suppliesCostChanged: data.suppliesCostChanged,
      contractorsSumChanged: data.contractorsSumChanged,
      driverSalaryChanged: data.driverSalaryChanged,
      headSalarySumChanged: data.headSalarySumChanged,
      additionalStaffSalaryChanged: data.additionalStaffSalaryChanged,
      amortizationSumChanged: data.amortizationSumChanged,
      taxForSalaryChanged: data.taxForSalaryChanged
    }
    const response = await account.put(`${URL}/${id}/composed-info`, snakeify(newData, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    if (response.data?.errors?.order.length) {
      toast.error(response.data.errors.order[0])
    }
    throw new Error(response.message, response.status);
  }
};

export const repeatOrder = async id => {
  try {
    const response = await account.post(`${URL}/${id}/copy`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const dataApplied = async orderId => {
  try {
    const response = await account.patch(`${URL}/${orderId}/data-applied`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const fetchOrderPayment = async id => {
  try {
    const response = await account.get(`${URL}/${id}/order-payment`);
    return camelize(get(response, 'data.data'), true);
  } catch (error) {
    parsePaymentErrors(error)
  }
};

export const editOrderPayment = async (id, data) => {
  try {
    const response = await account.put(`${URL}/${id}/order-payment`, snakeify(data, true));
    return camelize(response.data, true);
  } catch (error) {
    parsePaymentErrors(error)
  }
};

export const sendEmailOrderPayment = async (id) => {
  try {
    const response = await account.patch(`${URL}/${id}/send-payment`);
    return camelize(response.data, true);
  } catch (error) {
    parsePaymentErrors(error)
  }
};

export const sendNotificationPaymentControl = async (id) => {
  try {
    const response = await account.post(`${URL}/${id}/order-payment/notify`);
    return camelize(response.data, true);
  } catch (error) {
    parsePaymentErrors(error)
  }
};

export const orderPaymentRefund = async (id, isRefunded) => {
  try {
    const response = await account.patch(`${URL}/${id}/order-payment/refund`, snakeify({ isRefunded }));
    return camelize(response.data, true);
  } catch (error) {
    parsePaymentErrors(error)
  }
};

export const orderPrepaymentRefund = async (id, isPrepaymentRefunded) => {
  try {
    const response = await account.patch(`${URL}/${id}/order-payment/refund-prepayment`, snakeify({ isPrepaymentRefunded }));
    return camelize(response.data, true);
  } catch (error) {
    parsePaymentErrors(error)
  }
};

export const orderPaymentCheckout = async (id) => {
  try {
    const response = await account.get(`${URL}/${id}/order-payment/checkout`);
    return camelize(response.data, true)
  } catch (error) {
    parsePaymentErrors(error)
  }
};

export const getEstimate = async (id) => {
  try {
    account.defaults.responseType = 'blob';
    const response = await account.get(`${URL}/${id}/composed-info/pdf`, {
      responseType: 'blob',
    });
    // for next application/json requests
    account.defaults.responseType = 'json';
    return response;
  } catch (error) {
    const { response } = error;
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};

export const sendEstimate = async (id, email) => {
  try {
    const response = await account.post(`${URL}/${id}/composed-info/pdf`, snakeify({ email }, true));
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const setNotify = async (orderId, data) => {
  try {
    const response = await account.patch(`${URL}/${orderId}/set-notify`, snakeify(data, true));
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const updateOrderDescription = async (orderId, data) => {
  try {
    const response = await account.patch(`${URL}/${orderId}/description`, snakeify(data, true));
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const updateOrderSetting = async (orderId) => {
  try {
    const response = await account.patch(`${URL}/${orderId}/setting`);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
