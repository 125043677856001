import styled from 'styled-components';
import { media } from 'styles';
// Components
import { NavLink } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import Icon from 'components/UI/Icon';
import { ReactComponent as NewsIcon } from 'assets/svg/news.svg';

export const SideMenu = styled.aside`
  position: fixed;
  top: ${({ logotypeHeight }) => `${logotypeHeight}px`};
  left: 0;
  z-index: 1002;
  transition: width 0.5s;
  font-weight: ${({ theme }) => theme.fontWeight.HEAVY};
  height: ${({ logotypeHeight }) => `calc(100vh - ${logotypeHeight + 80}px)`};
  width: 286px;
  background: ${({ theme }) => theme.color.violetBlue};
  color: ${({ theme }) => theme.color.white};

  ${media.MD_DOWN} {
    top: 0;
    height: 100vh;
    width: ${({ opened }) => (opened ? '320px' : '0px')};
    box-shadow: 0px 0px 16px ${({ theme }) => theme.color.darkGray};
    background: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.black};
  }

  ${media.XS_DOWN} {
    height: 100%;
    z-index: 99999;
  }
`;

export const SideMenuNav = styled.nav`
  padding: 0 0 0 0;
  overflow-y: auto;
  overflow-x: hidden;

  text-overflow: ellipsis;
`;

export const SideMenuFooter = styled.footer`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 20px;
  padding: 0 17px;

  ${media.MD_DOWN} {
    margin: 0 0 25px;
  }

  ${media.SM_DOWN} {
    padding: 20px 17px;
  }
`;

export const StyledNewsIcon = styled(NewsIcon)`
  width: 24px;
  height: 24px;
`

export const SideMenuBlock = styled.section`
  margin-top: 20px;
  margin-left: 15px;
`;

export const SideMenuTitle = styled.header`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-left: 10px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const SideMenuMainIcon = styled(Icon)`
  font-size: ${({ theme }) => theme.fontSize.XL};
`;

export const SideMenuItemIcon = styled(Icon)`
  margin-right: 15px;
  font-size: ${({ theme }) => theme.fontSize.XL};
`;

export const SideMenuList = styled.ul`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 0px;
`;

export const DisabledButton = styled.a`
  cursor: default;
`

export const SideMenuItem = styled.li`
  padding: 6px 25px 6px 10px;
  line-height: 160%;
  background-color: ${({ active, theme }) => (active ? theme.color.white : 'transparent')};
  border-radius: 4px 0 0 4px;
`;

export const StyledLink = styled(NavLink)`
  transition: 0.2s;
  padding: 5px 0;
  white-space: nowrap;
  color: ${({ theme, disabled }) => disabled ? theme.color.darkGray : 'inherit'};
  text-overflow: ellipsis;

  &:hover {
    color: ${({ theme }) => theme.color.darkGray};
  }

  &.selected {
    color: ${({ theme }) => theme.color.richBlue};
  }

  ${media.MD_DOWN} {
    &:hover {
      color: ${({ theme, disabled }) => disabled ? theme.color.darkGray : theme.color.blue};
    }
    &.selected {
      color: ${({ theme }) => theme.color.blue};
    }
  }
`;

export const SideMenuFooterButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  transition: 0.2s;
  background-color: transparent;
  color: ${({ theme }) => theme.color.white};
  border-radius: 4px;
  gap: 0 15px;
  padding: 6px 15px 6px 8px;

  &:hover {
    color: ${({ theme }) => theme.color.darkGray};
  }

  &.selected {
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.richBlue};
    &:hover {
      color: ${({ theme }) => theme.color.richBlue};
    }
  }

  ${media.MD_DOWN} {
    color: ${({ theme }) => theme.color.black};

    &.selected {
      color: ${({ theme }) => theme.color.blue};
      &:hover {
        color: ${({ theme }) => theme.color.blue};
      }
    }

    &:hover {
      color: ${({ theme }) => theme.color.blue};
    }
  }
`;

export const NewsText = styled.p`
  position: relative;

  &:after {
    content: '';
    display: ${({ hasNews }) => hasNews ? 'block' : 'none'};
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.color.red};
    position: absolute;
    top: -6px;
    right: -9px;
  }
`;

export const HeadSideMenuButton = styled(SideMenuFooterButton)`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 15px;
  margin-top: 10px;
  padding: 15px 0 15px 15px;
  width: 100%;
  color: ${({ theme, disabled }) => disabled ? theme.color.darkGray : 'inherit'};

  &.selected {
    color: ${({ theme }) => theme.color.richBlue};
    background-color: ${({ theme }) => theme.color.white};
  }

  ${SideMenuMainIcon} {
    margin-left: 10px;
  }

  ${media.MD_DOWN} {
    &:hover {
      color: ${({ theme, disabled }) => disabled ? theme.color.darkGray : theme.color.blue};
    }
    &.selected {
      color: ${({ theme }) => theme.color.blue};
    }
  }
`

export const StyledSimpleBar = styled(SimpleBar)`
  height: 100%;

  .simplebar-content {
    display: grid;
    height: 100%;
    grid-template-rows: 1fr min-content;

    ${media.MD_DOWN} {
      grid-template-rows: 120px 1fr min-content;
    }
  }

  .simplebar-content:before, .simplebar-content:after {
    display:none;
  }
`;
