import {
  fetchAllObjects,
  fetchObjects,
  createObject,
  updateObject,
  deleteObject,
  objectTransfer,
} from 'api/clientObjects.api';

import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';

import { effects as modalEffects } from './modal.duck';

export const options = {
  name: 'clientObjects',
  initialState: {
    clientObjects: [],
    allObjects: [],
    object: {},
    errorMessage: '',
    loading: false,
  },
  actions: {
    fetchAllObjectsRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchAllObjectsSuccess: ({ items }) => state => ({
      ...state,
      loading: false,
      allObjects: items,
    }),
    fetchAllObjectsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    fetchObjectsRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchObjectsSuccess: ({ items }) => state => ({
      ...state,
      loading: false,
      clientObjects: items,
    }),
    fetchObjectsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    createObjectRequest: () => state => ({
      ...state,
      loading: true,
    }),
    createObjectSuccess: object => state => ({
      ...state,
      object: object || state.object,
      loading: false,
    }),
    createObjectFailure: errors => state => ({
      ...state,
      errorMessage: errors,
      loading: false,
    }),
    updateObjectRequest: () => state => ({
      ...state,
      loading: true,
    }),
    updateObjectSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    updateObjectFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    deleteObjectRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteObjectSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    deleteObjectFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    objectTransferRequest: () => state => ({
      ...state,
      loading: true,
    }),
    objectTransferSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    objectTransferFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
  },
  effects: {
    fetchAllObjects: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchAllObjectsRequest());
      try {
        const response = await fetchAllObjects();
        dispatch(duckActions.fetchAllObjectsSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchAllObjectsFailure(error));
      }
    },
    fetchObjects: ({ id, params, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchObjectsRequest());
      try {
        const response = await fetchObjects(id, params);
        dispatch(duckActions.fetchObjectsSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchObjectsFailure(error));
      }
    },
    createObject: ({ clientId, closeForm, ...data }) => async (
      dispatch,
      getState,
      duckActions,
      duckEffects
    ) => {
      dispatch(duckActions.createObjectRequest());
      try {
        const response = await createObject(clientId, data);

        if (window.location.href.includes('order')) {
          dispatch(duckActions.createObjectSuccess(response.data));
        } else {
          dispatch(duckActions.createObjectSuccess(null));
        }

        toast.success('Объект создан');
        if (closeForm) closeForm();

        await dispatch(duckEffects.fetchObjects({ id: clientId }));
        dispatch(modalEffects.closeModal());
        return response.data;
      } catch (errors) {
        const { message } = errors;

        if (message)
          toast.error(message)

        dispatch(duckActions.createObjectFailure(errors));
        return false;
      }
    },
    updateObject: ({ id, clientId, closeForm, ...data }) => async (
      dispatch,
      getState,
      duckActions,
      duckEffects
    ) => {
      dispatch(duckActions.updateObjectRequest());
      try {
        if (data.employee_id) await dispatch(duckEffects.objectTransfer({ id, employee_id: data.employee_id }))
        const response = await updateObject(id, data);
        dispatch(duckActions.updateObjectSuccess(response));
        toast.success('Изменения сохранены');
        closeForm();
        await dispatch(duckEffects.fetchObjects({ id: clientId }));
      } catch (error) {
        const { message } = error;
        if (message) toast.error(message)
        dispatch(duckActions.updateObjectFailure(error));
      }
    },
    objectTransfer: id => async (
      dispatch,
      getState,
      duckActions,
    ) => {
      dispatch(duckActions.objectTransferRequest());
      try {
        await objectTransfer(id);
        dispatch(duckActions.objectTransferSuccess());
        toast.success('Ответственный изменен');
      } catch (error) {
        dispatch(duckActions.objectTransferFailure(error));
      }
    },
    deleteObject: ({ id, clientId, ...data }) => async (
      dispatch,
      getState,
      duckActions,
      duckEffects
    ) => {
      dispatch(duckActions.deleteObjectRequest());
      try {
        const response = await deleteObject(id, data);
        dispatch(duckActions.deleteObjectSuccess(response));
        toast.success('Объект удален');
        await dispatch(duckEffects.fetchObjects({ id: clientId }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteObjectFailure(error));
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
    getObjects: (getState, createSelector) => createSelector([getState], s => s.clientObjects),
    getAllObjects: (getState, createSelector) => createSelector([getState], s => s.allObjects),
    getOneObject: (getState, createSelector) => createSelector([getState], s => s.object),
    getError: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
