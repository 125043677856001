import { NavLink } from 'react-router-dom';
import { SideMenuFooterButton, SideMenuFooter, NewsText, StyledNewsIcon, } from '../styles';

const SideMenuFooterComponent = ({ toggleOpened, user, }) => {

  return (
    <SideMenuFooter>
      <SideMenuFooterButton
        as={NavLink}
        to="/news"
        onClick={toggleOpened}
        activeClassName="selected"
      >
        <StyledNewsIcon />
        <NewsText hasNews={Boolean(user.news)}>Новости</NewsText>
      </SideMenuFooterButton>
    </SideMenuFooter>
  )
}

export default SideMenuFooterComponent;
