import { account } from 'utils/axiosInstances';
import { camelize, snakeify } from 'utils/objectUtilities';

export const fetchApplication = async id => {
  try {
    const response = await account.get(`/orders/${id}/application-form`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const updateApplication = async (id, data) => {
  try {
    const response = await account.put(`/application-forms/${id}`, snakeify(data, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const deleteApplication = async id => {
  try {
    const response = await account.delete(`/application-forms/${id}`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const fetchApplicationPdf = async id => {
  try {
    const response = await account.get(`/application-forms/${id}/pdf`, {
      responseType: 'blob',
    });
    return response;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};
