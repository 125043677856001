import { account } from 'utils/axiosInstances';
import { get } from 'lodash';

export const fetchCalculator = async () => {
  try {
    const response = await account.get('/calculator');
    return get(response, 'data', {});
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const updateTechnologies = async data => {
  try {
    const response = await account.put('/calculator/technologies', data);
    return get(response, 'data', {});
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};
