import React, { useState } from 'react';
// Components
import Modal from './ModalAdapter';
import {
  EmployeeModal,
  DeleteBrigadeModal,
  ConfirmModal,
  CustomModal,
  DeleteModal,
  FreelancerModal,
  CreateObjectModal,
  CreateByNameModal,
  CreateFolderModal,
  CreateFileModal,
  MoveFileModal,
  OrderScheduleModal,
  WorkTimeModal,
  SortModal,
  FilterModal,
  SuppliesModal,
  ManagerTaskModal,
  ScheduleWeekendModal,
  CreateContractorsModal,
  OrderPaymentModal,
  ImageModal,
  PDFModal,
  SendEstimateModal,
  NomenclatureModal,
  TMCUniqModal,
  CreateDocumentModal,
  StorageEquipmentModal,
  EditDocumentModal,
  DeleteBranchModal,
  DepreciationModal,
  TagModal,
} from './Modal.constants';

export default function ExportedModal(props) {
  const { type, data, content, actions, closeModal } = props;
  const isOpen = Boolean(type);

  const [isClosing, setIsClosing] = useState(false);
  function closeModalTimeout(isCurrent = false) {
    setIsClosing(true);
    setTimeout(() => {
      if (isCurrent && actions.onCustom) {
        actions.onCustom()
      }
      closeModal();
      setIsClosing(false);
    }, 200);
  }

  const modalProps = {
    data,
    content,
    actions,
    closeModal: closeModalTimeout,
    isClosing,
  };


  function renderModal(modalType) {

    switch (modalType) {
      case 'deleteModal':
        return <DeleteModal {...modalProps} />;
      case 'confirmModal':
        return <ConfirmModal {...modalProps} />;
      case 'deleteBrigade':
        return <DeleteBrigadeModal {...modalProps} />;
      case 'employee':
        return <EmployeeModal {...modalProps} />;
      case 'freelancer':
        return <FreelancerModal {...modalProps} />;
      case 'createObject':
        return <CreateObjectModal {...modalProps} />;
      case 'createNyName':
        return <CreateByNameModal {...modalProps} />;
      case 'createContractors':
        return <CreateContractorsModal {...modalProps} />;
      case 'createFolder':
        return <CreateFolderModal {...modalProps} />;
      case 'createFile':
        return <CreateFileModal {...modalProps} />;
      case 'moveFile':
        return <MoveFileModal {...modalProps} />;
      case 'orderSchedule':
        return <OrderScheduleModal {...modalProps} />;
      case 'workTimeForm':
        return <WorkTimeModal {...modalProps} />;
      case 'sortModal':
        return <SortModal {...modalProps} />;
      case 'filterModal':
        return <FilterModal {...modalProps} />;
      case 'suppliesModal':
        return <SuppliesModal {...modalProps} />;
      case 'managerTaskModal':
        return <ManagerTaskModal {...modalProps} />;
      case 'scheduleWeekendModal':
        return <ScheduleWeekendModal {...modalProps} />;
      case 'orderPaymentModal':
        return <OrderPaymentModal {...modalProps} />;
      case 'imageModal':
        return <ImageModal {...modalProps} />;
      case 'PDFModal':
        return <PDFModal {...modalProps} />;
      case 'sendEstimateModal':
        return <SendEstimateModal {...modalProps} />;
      case 'nomenclatureModal':
        return <NomenclatureModal {...modalProps} />;
      case 'TMCUniqModal':
        return <TMCUniqModal {...modalProps} />
      case 'createDocumentModal':
        return <CreateDocumentModal {...modalProps} />
      case 'storageEquipmentModal':
        return <StorageEquipmentModal {...modalProps} />
      case 'editDocumentModal':
        return <EditDocumentModal {...modalProps} />
      case 'deleteBranchModal':
        return <DeleteBranchModal {...modalProps} />
      case 'depreciationModal':
        return <DepreciationModal {...modalProps} />
      case 'tagModal':
        return <TagModal {...modalProps} />
      case 'customModal':
        return <CustomModal {...modalProps} />
      default:
        return <div>No such modal!</div>;
    }
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={() => actions.onCustom ? closeModalTimeout(true) : closeModalTimeout()} isClosing={isClosing}>
      {renderModal(type)}
    </Modal>
  );
}
