import { account } from 'utils/axiosInstances';

const URL = '/tags';

export const fetchTags = async () => {
  try {
    const { data } = await account.get(URL);
    return data;
  } catch (error) {
    const { response } = error;
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};

export const createTag = async payload => {
  try {
    const { data } = await account.post(URL, payload);
    return data;
  } catch (error) {
    const { response } = error;
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};

export const editTag = async (id, payload) => {
  try {
    const { data } = await account.put(`${URL}/${id}`, payload);
    return data;
  } catch (error) {
    const { response } = error;
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};

export const deleteTag = async id => {
  try {
    const { data } = await account.delete(`${URL}/${id}`);
    return data;
  } catch (error) {
    const { response } = error;
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};
