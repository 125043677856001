import getCookieDomain from './getCookieDomain';
import getCookieByName from './getCookieByName';
import getExpires from './getExpires';
import CookiesRequest from './CookiesRequest'

const PATH = '/';

const getDomain = () => getCookieDomain(window.location.hostname) || '';

export const getCookie = name => {
  const cookie = getCookieByName(name, document.cookie);
  if (cookie) {
    const parsedCookie = JSON.parse(cookie);
    return parsedCookie.value;
  }
  return undefined;
};

export const getCookieExpTime = name => {
  const cookie = getCookieByName(name, document.cookie);
  if (cookie) {
    const parsedCookie = JSON.parse(cookie);
    return parsedCookie.expires.toUTCString();
  }
  return undefined;
};

export const createCookie = (name, value, days) => {
  const expires = getExpires(days);
  const data = {
    value,
    expires,
  };
  document.cookie = `${name}=${JSON.stringify(
    data
  )};expires=${expires};domain=${getDomain()};path=${PATH};`;
};

export const removeCookie = name => {
  if (!getCookie(name)) return;
  document.cookie = `${name}=;path=${PATH};domain=${getDomain()};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};

export const hasCookie = name => !!getCookie(name);

const cookie = {
  getCookie,
  createCookie,
  removeCookie,
  hasCookie,
  CookiesRequest
};

export default cookie;
