import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const List = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 331px;
  max-height: 400px;
  background: #ffffff;
  box-shadow: 0px 3px 20px rgba(108, 139, 249, 0.6);
  border-radius: 6px;
`;

export const NewHeader = styled.h4`
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #6c8bf9;
  padding: 11px 20px;
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  height: 53px;

  span {
    padding-left: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #333333;
  }
`;
export const BackButton = styled.button`
  padding: 0;
`

export const ItemsContainer = styled.ul`
  flex: 1;
  max-height: 400px;
  overflow: auto;
`;

export const Items = styled.div``;

export const NewItems = styled.div`
  background: #d3e3ff;
`;

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  padding: 12px;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #6c8bf9;
`;
