import { toast } from 'react-toastify';
// utils
import { account, guest } from 'utils/axiosInstances';
import { camelize } from 'utils/objectUtilities';

const URL = '/company/payment-method';

export const fetchPaymentMethod = async () => {
  try {
    const response = await account.get(`${URL}`);
    return response.data;
  } catch ({ response }) {
    const { data } = response

    if (response.status === 403) {
      toast.warning('Нет разрешения у вашей роли')
    }
    throw new Error(data?.message, response.status);
  }
};

export const editPaymentMethod = async (payload) => {
  try {
    const response = await account.post(`${URL}`, { type: payload });
    return response;
  } catch ({ error }) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const editPaymentInfo = async ({ type, data }) => {
  try {
    const response = await account.post(`${URL}/settings/${type}`, data)
    return response.data
  } catch ({ error }) {
    const { response } = error
    throw new Error(response.message, response.status)
  }
}

export const guestOrder = async payload => {
  try {
    const response = await guest.get(`/order?token=${payload}`);
    return camelize(response.data, true);
  } catch ({ error }) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const guestOrderInfo = async (payload) => {
  try {
    const response = await guest.get(`/order/info?token=${payload}`, {
      responseType: 'blob',
    });
    return response;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const guestOrderOffer = async (payload) => {
  try {
    const response = await guest.get(`/order/offer?token=${payload}`, {
      responseType: 'blob',
    });
    return response;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};
