import S from 'string';

const camelize = (snakeData = {}, recursive = false) => {
  const camelData = Array.isArray(snakeData) ? [] : {};

  Object.keys(snakeData).forEach(key => {
    const camelKey = S(key).camelize().s;
    if (recursive && snakeData[key] && typeof snakeData[key] === 'object') {
      camelData[camelKey] = camelize(snakeData[key], true);
    } else {
      camelData[camelKey] = snakeData[key];
    }
  });

  return camelData;
};

const snakeify = (camelData = {}, recursive = false) => {
  const snakeData = Array.isArray(camelData) ? [] : {};

  Object.keys(camelData).forEach(key => {
    const snakeKey = S(key).underscore().s;
    if (recursive && camelData[key] && typeof camelData[key] === 'object') {
      snakeData[snakeKey] = snakeify(camelData[key], true);
    } else {
      snakeData[snakeKey] = camelData[key];
    }
  });

  return snakeData;
};

export { camelize, snakeify };
