import { base, account } from 'utils/axiosInstances';
import { camelize, snakeify } from 'utils/objectUtilities';
import { toast } from 'react-toastify';
import { get } from 'lodash'

const URL = '/company';

export const getCompany = async () => {
  try {
    const response = await account.get(URL);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const getHeadCompany = async () => {
  try {
    const response = await account.get('/companies/head');
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const editCompany = async payload => {
  try {
    const response = await account.put(URL, snakeify(payload, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    if (get(response, 'data.errors.child_companies_enabled', '')) {
      response.data.errors.child_companies_enabled.forEach((text) => {
        toast.error(text)
      })
    }
    throw new Error(response.message, response.status);

  }
};

export const logCompanyLastSeen = async () => {
  try {
    const response = await account.patch(`${URL}/last-seen`);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const getTariffInfo = async () => {
  try {
    const response = await base.get('current-tariff');
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
