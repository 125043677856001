import { account } from 'utils/axiosInstances';
import { camelize } from 'utils/objectUtilities';

const URL = '/employees';

export const getSchedule = async ({ dateFrom, dateTo, brigadeId, withOrders = false, exceptOrderId = '' }) => {
  try {
    const response = await account.get(
      `/employees-schedule?date_from=${dateFrom}&date_to=${dateTo}${brigadeId ? `&brigade_id=${brigadeId}` : ''
      }${withOrders ? `&with_orders=${withOrders}` : ''
      }${exceptOrderId ? `&except_order_id=${exceptOrderId}` : ''}`
    );
    return camelize(response, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const editScheduleWeekend = async (id, data) => {
  try {
    const response = await account.post(`/employees/${id}/schedule`, data)
    return response
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const editFreelancersWeekend = async (id, data) => {
  try {
    const response = await account.post(`/freelance-staffes/${id}/schedule`, data)
    return response
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const getFreelancersSchedule = async ({ dateFrom, dateTo, created_date_from, created_date_to, search, withOrders = false }) => {
  try {
    const response = await account.get(
      `/freelancers-schedule?date_from=${dateFrom}&date_to=${dateTo}${created_date_from ? `&created_date_from=${created_date_from}` : ''}${created_date_to ? `&created_date_to=${created_date_to}` : ''}${search ? `&search=${search}` : ''}&with_orders=${withOrders}`
    );
    return camelize(response, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const setDayoff = async ({ id, ...data }) => {
  try {
    const response = await account.put(`${URL}/${id}/schedule/set-not-work`, data);
    return response;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const resetDayoff = async ({ id, ...data }) => {
  try {
    const response = await account.delete(`${URL}/${id}/schedule/reset-not-work`, {
      data,
    });
    return response;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const setOrderSchedule = async ({ orderId, data }) => {
  try {
    const response = await account.post(`/orders/${orderId}/schedule`, { schedule: data });
    return response;
  } catch (error) {
    const { response } = error;
    if (response.status === 422) {
      const errors = response.data.errors;
      if (errors) {
        const keys = Object.keys(errors);
        throw new Error(errors[keys[0]][0] || 'Ошибка входных данных', 422)
      }
    }
    throw new Error(response.message, response.status);
  }
};

export const getOrderSchedule = async orderId => {
  try {
    const { data } = await account.get(`/orders/${orderId}/schedule`);
    return camelize(data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const getOrderWorkingHours = async orderId => {
  try {
    const { data } = await account.get(`/orders/${orderId}/working-hours`);
    return camelize(data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const setOrderWorkingHours = async ({ orderId, data }) => {
  try {
    const response = await account.post(`/orders/${orderId}/working-hours`, data);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const editOrderWorkingHours = async ({ orderId, hoursId, data }) => {
  try {
    const response = await account.put(`/orders/${orderId}/working-hours/${hoursId}`, data);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const setScheduleDefault = async (isForce = false) => {
  try {
    const response = await account.post(`${URL}-schedule/default`, { force: isForce });
    return response;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};
