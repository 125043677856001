import {
  getFreelancers,
  getFreelancer,
  createFreelancer,
  updateFreelancer,
  deleteFreelancer,
} from 'api/freelancers.api';

import { get } from 'lodash';
import history from 'utils/history';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';
import { effects as modalEffects } from './modal.duck';

export const toastifyError = errors => {
  let error;

  if (get(errors, 'email', '').includes('Такое значение поля уже существует.')) {
    error = 'Адрес электронной почты уже используется'
  }
  else if (get(errors, 'telegram_id', '').includes('Такое значение поля уже существует.')) {
    error = 'Указанный телеграм ID уже используется'
  }
  if (error) {
    toast.error(error);
  }
}

export const options = {
  name: 'freelancers',
  initialState: {
    freelancers: [],
    freelancer: {},
    errorMessage: '',
    loading: false,
  },
  actions: {
    fetchFreelancersRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchFreelancersSuccess: ({ items, count }) => state => ({
      ...state,
      loading: false,
      freelancers: items,
      count,
    }),
    fetchFreelancersFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    fetchFreelancerRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchFreelancerSuccess: freelancer => state => ({
      ...state,
      loading: false,
      freelancer,
    }),
    fetchFreelancerFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    createFreelancersRequest: () => state => ({
      ...state,
      loading: true,
    }),
    createFreelancersSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    createFreelancersFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message || '',
      loading: false,
    }),
    updateFreelancerRequest: () => state => ({
      ...state,
      loading: true,
    }),
    updateFreelancerSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    updateFreelancerFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message || '',
    }),
    deleteFreelancerRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteFreelancerSuccess: id => state => ({
      ...state,
      loading: false,
      freelancers: state.freelancers.filter(freelancer => freelancer.id !== id)
    }),
    deleteFreelancerFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
  },
  effects: {
    fetchFreelancers: params => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchFreelancersRequest());
      try {
        const response = await getFreelancers(params);
        dispatch(duckActions.fetchFreelancersSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchFreelancersFailure(error));
      }
    },
    fetchFreelancer: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchFreelancerRequest());
      try {
        const response = await getFreelancer(id);
        dispatch(duckActions.fetchFreelancerSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchFreelancerFailure(error));
      }
    },
    createFreelancer: ({ updateData = true, ...data }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.createFreelancersRequest());
      try {
        const response = await createFreelancer(data);
        dispatch(duckActions.createFreelancersSuccess(response));
        if (updateData) await dispatch(duckEffects.fetchFreelancers());
        history.push({ pathname: '/employees', search: '?tab=3' });
        toast.success('Сотрудник добавлен');
      } catch (error) {
        const { errors } = error
        if (errors) toastifyError(errors);
        dispatch(duckActions.createFreelancersFailure(error));
      }
    },
    updateFreelancer: ({ id, updateData = true, ...data }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.updateFreelancerRequest());
      try {
        const response = await updateFreelancer(id, { ...data });
        dispatch(duckActions.updateFreelancerSuccess(response));
        if (updateData)
          await dispatch(duckEffects.fetchFreelancer(id));
        history.push({ pathname: '/employees', search: '?tab=3' });
        toast.success('Изменения сохранены')
      } catch (error) {
        const { errors } = error
        if (errors) toastifyError(errors);
        dispatch(duckActions.updateFreelancerFailure(error));
      }
    },
    deleteFreelancer: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deleteFreelancerRequest());
      try {
        const response = await deleteFreelancer(id);
        if (response.success) {
          dispatch(duckActions.deleteFreelancerSuccess(id));
          toast.success('Сотрудник перемещен в корзину');
          dispatch(modalEffects.closeModal());
        }
      } catch (error) {
        dispatch(duckActions.deleteFreelancerFailure(error));
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
    getFreelancers: (getState, createSelector) => createSelector([getState], s => s.freelancers),
    getFreelancer: (getState, createSelector) => createSelector([getState], s => s.freelancer),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
