import { account } from 'utils/axiosInstances';
import { camelize, } from 'utils/objectUtilities';
import { toast } from 'react-toastify';
import { getPaginationParams } from './utils/paginationUtils';

const NOTIFICATIONS_URL = '/notifications';

export const getUserNotifications = async (params = { limit: 10, offset: 1, }) => {
  const { limit, offset, sortBy, sortDirection } = params;
  try {
    const query = `${NOTIFICATIONS_URL}?${getPaginationParams(offset, limit)}${sortBy ? `&sort_by=${sortBy}&sort_direction=${sortDirection ? 'desc' : 'asc'}` : ''
      }`;
    const { data = {} } = await account.get(query);
    return camelize(data, true);
  } catch ({ response = {} }) {
    throw new Error(response.message, response.status);
  }
};

export const setUserNotifications = async () => {
  try {
    const query = `${NOTIFICATIONS_URL}/read`;
    const response = await account.patch(query);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const setUserNotification = async notificationId => {
  try {
    const query = `${NOTIFICATIONS_URL}/${notificationId}/read`;
    const response = await account.patch(query);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const setUserNotificationChecked = async notificationId => {
  try {
    const query = `${NOTIFICATIONS_URL}/actions/${notificationId}`;
    const response = await account.patch(query);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    if (response.status === 403) {
      toast.warning('Нет разрешения у вашей роли')
    }
    throw new Error(response.message, response.status);
  }
};
