import { fetchStatuses, updateStatuses } from 'api/statuses.api';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';

export const options = {
  name: 'statuses',
  initialState: {
    statuses: [],
    loading: false,
    errorMessage: '',
  },
  actions: {
    fetchStatusesRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchStatusesSuccess: statuses => state => ({
      ...state,
      statuses,
      loading: false,
    }),
    fetchStatusesFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      message,
    }),
    updateStatusesRequest: () => state => ({
      ...state,
      loading: true,
    }),
    updateStatusesSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    updateStatusesFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      message,
    }),
  },
  effects: {
    fetchStatuses: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchStatusesRequest());
      try {
        const response = await fetchStatuses();
        dispatch(duckActions.fetchStatusesSuccess(response.data));
      } catch (error) {
        dispatch(duckActions.fetchStatusesFailure(error));
      }
    },
    updateStatuses: ({ submitAction, isRegister, ...data }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.updateStatusesRequest());
      try {
        const { success } = await updateStatuses(data);
        dispatch(duckActions.updateStatusesSuccess());
        
        if (success) {
          toast.success('Изменения сохранены');
          if (!isRegister) await dispatch(duckEffects.fetchStatuses());
          if (submitAction) submitAction();
        }
      } catch (error) {
        dispatch(duckActions.updateStatusesFailure(error));
      }
    },
  },
  selectors: {
    getStatuses: (getState, createSelector) => createSelector([getState], s => s.statuses),
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
