import {
  getBranches,
  createBranch,
  changeBranch,
  editBranch,
  getBranch,
  deleteBranch
} from 'api/branches.api';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';
import { effects as modalEffects } from './modal.duck';
import history from 'utils/history';

const initialState = {
  branches: [],
  loading: false,
  error: '',
  branch: {},
  tempBranchData: {}
};

export const options = {
  name: 'branches',
  initialState,
  actions: {
    fetchBranchesRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchBranchesSuccess: branches => state => ({
      ...state,
      branches,
      error: '',
      loading: false,
    }),
    fetchBranchesFailure: ({ message: error }) => state => ({
      ...state,
      loading: false,
      error,
    }),
    fetchBranchRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchBranchSuccess: branch => state => ({
      ...state,
      branch,
      error: '',
      loading: false,
    }),
    fetchBranchFailure: ({ message: error }) => state => ({
      ...state,
      loading: false,
      error,
    }),
    deleteBranchRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteBranchSuccess: () => state => ({
      ...state,
      error: '',
      loading: false,
    }),
    deleteBranchFailure: ({ message: error }) => state => ({
      ...state,
      loading: false,
      error,
    }),
    createBranchRequest: () => state => ({
      ...state,
      loading: true,
    }),
    createBranchSuccess: (branch) => state => ({
      ...state,
      error: '',
      loading: false,
      branch,
    }),
    createBranchFailure: ({ message: error }) => state => ({
      ...state,
      loading: false,
      error,
    }),
    saveTempBranchData: (data) => state => ({
      ...state,
      tempBranchData: data
    }),
    changeBranchRequest: () => state => ({
      ...state,
      loading: true,
    }),
    changeBranchSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    changeBranchFailure: ({ message: error }) => state => ({
      ...state,
      loading: false,
      error,
    }),
    editBranchRequest: () => state => ({
      ...state,
      loading: true,
    }),
    editBranchSuccess: () => state => ({
      ...state,
      error: '',
      loading: false,
    }),
    editBranchFailure: ({ message: error }) => state => ({
      ...state,
      loading: false,
      error,
    }),
    saveBranchlocally: (branch) => state => ({
      ...state,
      branch,
    }),
    resetBranchesState: () => () => initialState,
  },
  effects: {
    fetchBranches: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchBranchesRequest());
      try {
        const response = await getBranches();
        dispatch(duckActions.fetchBranchesSuccess(response.data));
      } catch (error) {
        dispatch(duckActions.fetchBranchesFailure(error));
      }
    },
    createBranch: ({ payload, }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.createBranchRequest());
      try {
        const { data = {} } = await createBranch(payload);
        if (data.id) {
          dispatch(duckActions.createBranchSuccess(data));
          await dispatch(duckEffects.changeBranch({ branch: data, pushToast: false, reload: false, toOrders: false, }));
          toast.success('Компания создана');
          return { success: true, data, }
        }
        else throw new Error('Something went wrong');
      } catch (error) {
        dispatch(duckActions.createBranchFailure(error));
      }
    },
    editBranch: ({ id, payload, closeForm }) => async (dispatch, getState, duckActions, duckEffects,) => {
      dispatch(duckActions.editBranchRequest());
      try {
        const { success } = await editBranch(id, payload);
        if (success) {
          dispatch(duckActions.editBranchSuccess());
          toast.success('Филиал изменен');
          if (closeForm) closeForm();

          return { success, data: { id, ...payload } };
        }
        else throw new Error('Something went wrong');
      } catch (error) {
        toast.warn('Филиал не изменен');
        dispatch(duckActions.editBranchFailure(error));
      }
    },
    changeBranch: ({ branch, pushToast = true, reload = true, toOrders = true, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.changeBranchRequest());
      try {
        const response = await changeBranch(branch.id);
        if (response) {
          dispatch(duckActions.changeBranchSuccess());
          if (pushToast) toast.info('Филиал изменен');
          if (toOrders) history.push({ pathname: '/orders' });
          if (reload) window.location.reload();
        } else throw new Error('Something went wrong');
      } catch (error) {
        toast.warn('Компания не изменена');
        dispatch(duckActions.changeBranchFailure(error));
      }
    },
    deleteBranch: ({ id, payload }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteBranchRequest());
      try {
        const { success } = await deleteBranch(id, payload);
        if (success) {
          dispatch(duckActions.deleteBranchSuccess());
          dispatch(duckEffects.fetchBranches());
          toast.success('Филиал удален');
          dispatch(modalEffects.closeModal());
        } else throw new Error('Something went wrong');
      } catch (e) {
        const { data } = await deleteBranch(id, payload);
        if (!!data?.errors?.new_company_id.length) {
          toast.warn(data?.errors?.new_company_id[0]);
        } else {
          toast.warn('Компания не изменена');
        }
        dispatch(duckActions.deleteBranchFailure(e));
      }
    },
    fetchBranch: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchBranchRequest());
      try {
        const response = await getBranch(id);
        dispatch(duckActions.fetchBranchSuccess(response.data));
      } catch (error) {
        dispatch(duckActions.fetchBranchFailure(error));
      }
    },
  },
  selectors: {
    getBranches: (getState, createSelector) => createSelector([getState], s => s.branches),
    getBranch: (getState, createSelector) => createSelector([getState], s => s.branch),
    getError: (getState, createSelector) => createSelector([getState], s => s.error),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
    getTempBranchData: (getState, createSelector) => createSelector([getState], s => s.tempBranchData),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
