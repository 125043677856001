import {
  fetchPaymentMethod,
  editPaymentMethod,
  editPaymentInfo,
  guestOrder,
  guestOrderInfo,
  guestOrderOffer
} from 'api/payment.api';
// Helpers
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';

export const options = {
  name: 'payment',
  initialState: {
    paymentMethod: [],
    guestOrder: {}
  },
  actions: {
    fetchPaymentMethodRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    fetchPaymentMethodSuccess: paymentMethod => state => ({
      ...state,
      isLoading: false,
      paymentMethod,
    }),
    fetchPaymentMethodFailure: errorMessage => state => ({
      ...state,
      isLoading: false,
      errorMessage
    }),
    editPaymentMethodRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    editPaymentMethodSuccess: data => state => ({
      ...state,
      isLoading: false,
      paymentMethod: {
        ...state.paymentMethod,
        settings: data || state.paymentMethod.settings
      }
    }),
    editPaymentMethodFailure: (response) => state => ({
      ...state,
      isLoading: false,
      error: response
    }),
    guestOrderRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    guestOrderSuccess: guestOrderData => state => ({
      ...state,
      guestOrder: guestOrderData,
      isLoading: false,
    }),
    guestOrderFailure: (response) => state => ({
      ...state,
      isLoading: false,
      error: response
    }),
    guestOrderInfoRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    guestOrderInfoSuccess: guestOrderInfoData => state => ({
      ...state,
      guestOrderInfo: guestOrderInfoData,
      isLoading: false,
    }),
    guestOrderInfoFailure: (response) => state => ({
      ...state,
      isLoading: false,
      error: response
    }),
    guestOrderOfferRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    guestOrderOfferSuccess: guestOrderOfferData => state => ({
      ...state,
      guestOrderOffer: guestOrderOfferData,
      isLoading: false,
    }),
    guestOrderOfferFailure: (response) => state => ({
      ...state,
      isLoading: false,
      error: response
    })
  },
  effects: {
    fetchPaymentMethod: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchPaymentMethodRequest());
      try {
        const response = await fetchPaymentMethod();
        dispatch(duckActions.fetchPaymentMethodSuccess(response.data || {}));
      } catch (data) {
        dispatch(duckActions.fetchPaymentMethodFailure(data));
      }
    },
    editPaymentMethod: (id) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.editPaymentMethodRequest());
      try {
        await editPaymentMethod(id);
        dispatch(duckActions.editPaymentMethodSuccess())
        await dispatch(duckEffects.fetchPaymentMethod())
      } catch ({ data }) {
        dispatch(duckActions.editPaymentMethodFailure(data));
        toast.error('Ошибка!');
      }
    },
    editPaymentInfo: ({ type, data }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.editPaymentMethodRequest());
      try {
        const { success } = await editPaymentInfo({ type, data });
        await dispatch(duckEffects.fetchPaymentMethod())
        dispatch(duckActions.editPaymentMethodSuccess(success ? data : null));
        toast.success('Настройки платежа успешно изменены')
      } catch ({ data: errorData }) {
        dispatch(duckActions.editPaymentMethodFailure(errorData));
        toast.error('Ошибка!');
      }
    },
    fetchGuestOrder: (token) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.guestOrderRequest());
      try {
        const response = await guestOrder(token);
        dispatch(duckActions.guestOrderSuccess(response.data))
      } catch ({ data }) {
        dispatch(duckActions.guestOrderFailure(data));
        toast.error('Ошибка!');
      }
    },
    fetchGuestOrderInfo: (token) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.guestOrderInfoRequest());
      try {
        const response = await guestOrderInfo(token);

        dispatch(duckActions.guestOrderInfoSuccess(response.data))

      } catch ({ data }) {
        dispatch(duckActions.guestOrderInfoFailure(data));
        toast.error('Ошибка!');
      }
    },
    fetchGuestOrderOffer: (token) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.guestOrderOfferRequest());
      try {
        const response = await guestOrderOffer(token);
        dispatch(duckActions.guestOrderOfferSuccess(response.data))
      } catch ({ data }) {
        dispatch(duckActions.guestOrderOfferFailure(data));
        toast.error('Ошибка!');
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.isLoading),
    getCurrentRole: (getState, createSelector) => createSelector([getState], s => s.currentRole),
    getPaymentMethod: (getState, createSelector) => createSelector([getState], s => s.paymentMethod),
    getGuestOrder: (getState, createSelector) => createSelector([getState], s => s.guestOrder)
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
