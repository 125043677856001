import {
  fetchHandbook,
  fetchProtectedHandbook,
  fetchNews,
  maskNewsAsRead,
} from 'api/handbook.api';
import { createDuck } from './utils/createDuck';
import { actions as userActions } from './user.duck';
import { effects as companyEffects } from './company.duck';
import moment from 'moment/moment';

export const options = {
  name: 'handbook',
  initialState: {
    errorMessage: '',
    isLoading: 0,
    tariffType: 0,
    isTariffActive: true,
    publicHandbook: {
      regions: [],
      taxTypes: [],
      legalEntityOptions: [],
      cityLevels: [],
      orderStatuses: [],
      electricityTypes: [],
      serviceQuantityType: [],
      paymentMethods: [],
      infoClientTypes: [],
      infoRoles: [],
      infoServices: [],
      currencies: [],
      tariffPermissions: {
        serviceOrderEstimateOne: [0, 1, 0, 0, 0], // DONE
        companyBranches: [0, 0, 0, 0, 1], // Филиалы // DONE, заблокировал выбор филиала в боковом меню и вкладку в настройках компании
        roleEditing: [0, 0, 0, 1, 1], // Роли и доступы // DONE, убрал даже выбор роли у сотрудников, возможно выбор оставить, а закрыть только настройки?
        knowledgeBase: [0, 0, 1, 1, 1], // Редактирование и загрузка документов в Базу знаний // DONE: заблокировал редактирование в базе знаний
        orderCardWorkHours: [0, 0, 0, 1, 1], // Табель учета рабочего времени // DONE
        purchase: [0, 0, 0, 1, 1], // Заказ-наряд // DONE, закрыл настройки С учетом количества в настройках ТМЦ, добавил уведомление на чекбокс
        clientsSeveralObjects: [0, 0, 1, 1, 1], // Возможность учета несколько объектов клиента // DONE, убрал кнопку добавить объект в карточке клиента и задизаблил кнопку Добавить объект при создании заказа.
        staffSchedule: [0, 0, 0, 1, 1], // Рабочее расписание сотрудников // DONE, заблочил кнопку Рабочее расписании в боковом меню, убрал кнопку Поставить в осмотр при создании заказа
        applicationForm: [0, 0, 1, 1, 1], // Печать документа // DONE, добавил уведомления кнопкам печать и скачать в бланк-заявке
        notificationsTelegram: [0, 0, 0, 1, 1], // Телеграм оповещение сотрудников // DONE, добавил уведомление при нажатии на ползунок в настройках уведомлений, если разрешение отсутствует
        library: [0, 0, 1, 1, 1], // Библиотека // DONE, закрыл вкладку Библиотека в боковом меню
        marginalIncome: [0, 0, 0, 1, 1], // Маржинальный доход в разрезе заказа и в целом // DONE, заблочил кнопку в боковом меню и в карточке заказа
        orderInfo: [0, 0, 0, 1, 1], // Возможность прикрепления к заказу фото, видео и текстовой информации
        objectInfo: [0, 0, 0, 1, 1], // Возможность хранения фото, видео и текстовой информации по объектам
        documents: [0, 0, 0, 1, 1], // Функционал договоров
      },
      tariffs: [],
      basisTypes: [],
      measureTypes: [],
      equipmentTypes: [],
      companySettingsParams: [],
      marginalIncomeFieldsName: [],
      defaultDocumentsTypes: [],
      periodicity: [],
    },
    protectedHandbook: {
      companies: [],
      positions: [],
      roles: [],
      skills: [],
      terms: [],
      orderScheduleTaskTypes: [],
    },
    news: []
  },
  actions: {
    fetchHandbookRequest: () => state => ({
      ...state,
      isLoading: state.isLoading + 1,
    }),
    fetchHandbookSuccess: data => state => ({
      ...state,
      isLoading: state.isLoading - 1,
      publicHandbook: {
        ...data,
        currencies: data.currencies?.map(({ name, slug, ...rest }, i) => (
          { id: i + 1, name, value: slug, ...rest }
        )) || [],
      },
    }),
    fetchHandbookFailure: ({ message }) => state => ({
      ...state,
      isLoading: state.isLoading - 1,
      errorMessage: message,
    }),
    fetchProtectedHandbookRequest: () => state => ({
      ...state,
      isLoading: state.isLoading + 1,
    }),
    fetchProtectedHandbookSuccess: data => state => ({
      ...state,
      isLoading: state.isLoading - 1,
      protectedHandbook: {
        ...data,
      },
    }),
    fetchProtectedHandbookFailure: ({ message }) => state => ({
      ...state,
      isLoading: state.isLoading - 1,
      errorMessage: message,
    }),
    fetchNewsRequest: () => state => ({
      ...state,
      isLoading: state.isLoading + 1,
    }),
    fetchNewsSuccess: ({ items }) => state => ({
      ...state,
      isLoading: state.isLoading - 1,
      news: items
    }),
    fetchNewsFailure: ({ message }) => state => ({
      ...state,
      isLoading: state.isLoading - 1,
      errorMessage: message,
    }),
    maskNewsAsReadRequest: () => state => ({
      ...state,
      isLoading: state.isLoading + 1,
    }),
    maskNewsAsReadSuccess: () => state => ({
      ...state,
      isLoading: state.isLoading - 1,
    }),
    maskNewsAsReadFailure: ({ message }) => state => ({
      ...state,
      isLoading: state.isLoading - 1,
      errorMessage: message,
    }),
    setNewPosition: position => state => ({
      ...state,
      protectedHandbook: {
        ...state.protectedHandbook,
        positions: [...state.protectedHandbook.positions, position]
      }
    }),
    deletePosition: id => state => ({
      ...state,
      protectedHandbook: {
        ...state.protectedHandbook,
        positions: state.protectedHandbook.positions.filter(item => item.id !== id)
      }
    }),
    setTariffData: ({ tariffType, tariffUntil }) => state => ({
      ...state,
      tariffType,
      isTariffActive: moment().isSameOrBefore(tariffUntil),
    }),
  },
  effects: {
    fetchHandbook: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchHandbookRequest());
      try {
        const response = await fetchHandbook();
        const currencies = response.currencies?.map(({ name, slug, ...rest }, i) => (
          { id: i + 1, name, value: slug, ...rest }
        )) || [];

        const { currency } = getState().company;
        if (!currency) {
          const { company } = getState().company;
          dispatch(companyEffects.setCompanyCurrency({ company, currencies, }));
        }

        dispatch(duckActions.fetchHandbookSuccess({ ...response, currencies, }));
        const { tariffPermissions } = response;
        dispatch(userActions.setRolesTariffPerms(tariffPermissions?.roleEditing || []));
      } catch (error) {
        dispatch(duckActions.fetchHandbookFailure(error));
      }
    },
    fetchProtectedHandbook: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchProtectedHandbookRequest());
      try {
        const response = await fetchProtectedHandbook();
        dispatch(duckActions.fetchProtectedHandbookSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchProtectedHandbookFailure(error));
      }
    },
    fetchNews: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchNewsRequest());
      try {
        const response = await fetchNews();
        dispatch(duckActions.fetchNewsSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchNewsFailure(error));
      }
    },
    maskNewsAsRead: ({ id }) => async (dispatch, getState, duckActions) => {
      const { news } = getState().handbook;
      dispatch(duckActions.maskNewsAsReadRequest());
      try {
        const { success } = await maskNewsAsRead(id);
        dispatch(duckActions.maskNewsAsReadSuccess());

        if (success && !news.some(item => item.new && item.id !== id)) {
          dispatch(userActions.setUserNews(0));
        }
      } catch (error) {
        dispatch(duckActions.maskNewsAsReadFailure(error));
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    getIsLoading: (getState, createSelector) => createSelector([getState], s => s.isLoading !== 0),
    getPublicHandbook: (getState, createSelector) => createSelector([getState], s => s.publicHandbook),
    getProtectedHandbook: (getState, createSelector) => createSelector([getState], s => s.protectedHandbook),
    getNews: (getState, createSelector) => createSelector([getState], s => s.news),
    // Public
    getRegions: (getState, createSelector) =>
      createSelector([getState], s => s.publicHandbook.regions),
    getTaxTypes: (getState, createSelector) =>
      createSelector([getState], s => s.publicHandbook.taxTypes),
    getLegalEntityOptions: (getState, createSelector) =>
      createSelector([getState], s => s.publicHandbook.legalEntityOptions),
    getManagerTaskTypes: (getState, createSelector) =>
      createSelector([getState], s => s.publicHandbook.orderScheduleTaskTypes),
    getInfoClientTypes: (getState, createSelector) =>
      createSelector([getState], s => s.publicHandbook.infoClientTypes),
    getInfoRoles: (getState, createSelector) =>
      createSelector([getState], s => s.publicHandbook.infoRoles),
    getInfoServices: (getState, createSelector) =>
      createSelector([getState], s => s.publicHandbook.infoServices),
    getBasisTypes: (getState, createSelector) =>
      createSelector([getState], s => s.publicHandbook.basisTypes),
    getMeasureTypes: (getState, createSelector) =>
      createSelector([getState], s => s.publicHandbook.measureTypes),
    getEquipmentTypes: (getState, createSelector) =>
      createSelector([getState], s => s.publicHandbook.equipmentTypes),
    getMarginalIncomeFieldsName: (getState, createSelector) =>
      createSelector([getState], s => s.publicHandbook.marginalIncomeFieldsName),
    getDefaultDocumentsTypes: (getState, createSelector) =>
      createSelector([getState], s => s.publicHandbook.defaultDocumentsTypes),
    getOrderStatuses: (getState, createSelector) =>
      createSelector([getState], s => s.publicHandbook.orderStatuses),
    getCurrencies: (getState, createSelector) =>
      createSelector([getState], s => s.publicHandbook.currencies),
    // tariff permission
    getOnlyOneServiceTariffPerm: (getState, createSelector) =>
      createSelector([getState], s => s.isTariffActive && (s.publicHandbook.tariffPermissions.serviceOrderEstimateOne || [])[s.tariffType]),
    getBranchesTariffPerm: (getState, createSelector) =>
      createSelector([getState], s => s.isTariffActive && (s.publicHandbook.tariffPermissions.companyBranches || [])[s.tariffType]),
    getRolesTariffPerm: (getState, createSelector) =>
      createSelector([getState], s => s.isTariffActive && (s.publicHandbook.tariffPermissions.roleEditing || [])[s.tariffType]),
    getOrderWorkHoursTariffPerm: (getState, createSelector) =>
      createSelector([getState], s => s.isTariffActive && (s.publicHandbook.tariffPermissions.orderCardWorkHours || [])[s.tariffType]),
    getPurchaseOrderTariffPerm: (getState, createSelector) =>
      createSelector([getState], s => s.isTariffActive && (s.publicHandbook.tariffPermissions.purchase || [])[s.tariffType]),
    getClientSeveralObjectsTariffPerm: (getState, createSelector) =>
      createSelector([getState], s => s.isTariffActive && (s.publicHandbook.tariffPermissions.clientsSeveralObjects || [])[s.tariffType]),
    getWorkScheduleTariffPerm: (getState, createSelector) =>
      createSelector([getState], s => s.isTariffActive && (s.publicHandbook.tariffPermissions.staffSchedule || [])[s.tariffType]),
    getApplicationPrintTariffPerm: (getState, createSelector) =>
      createSelector([getState], s => s.isTariffActive && (s.publicHandbook.tariffPermissions.applicationForm || [])[s.tariffType]),
    getStaffTelegramNotificationsTariffPerm: (getState, createSelector) =>
      createSelector([getState], s => s.isTariffActive && (s.publicHandbook.tariffPermissions.notificationsTelegram || [])[s.tariffType]),
    getKnowledgeBaseViewTariffPerm: (getState, createSelector) =>
      createSelector([getState], s => s.isTariffActive && (s.publicHandbook.tariffPermissions.library || [])[s.tariffType]),
    getKnowledgeBaseEditTariffPerm: (getState, createSelector) =>
      createSelector([getState], s => s.isTariffActive && (s.publicHandbook.tariffPermissions.knowledgeBase || [])[s.tariffType]),
    getMarginalIncomeTariffPerm: (getState, createSelector) =>
      createSelector([getState], s => s.isTariffActive && (s.publicHandbook.tariffPermissions.marginalIncome || [])[s.tariffType]),
    getOrderInfoTariffPerm: (getState, createSelector) =>
      createSelector([getState], s => s.isTariffActive && (s.publicHandbook.tariffPermissions.orderInfo || [])[s.tariffType]),
    getObjectInfoTariffPerm: (getState, createSelector) =>
      createSelector([getState], s => s.isTariffActive && (s.publicHandbook.tariffPermissions.objectInfo || [])[s.tariffType]),
    getDocumentsTariffPerm: (getState, createSelector) =>
      createSelector([getState], s => s.isTariffActive && (s.publicHandbook.tariffPermissions.documents || [])[s.tariffType]),
      getPeriodicity: (getState, createSelector) =>
        createSelector([getState], s => s.publicHandbook.periodicity),
    // Protected
    getPositions: (getState, createSelector) =>
      createSelector([getState], s => s.protectedHandbook.positions),
    getRoles: (getState, createSelector) =>
      createSelector([getState], s => s.protectedHandbook.roles),
    getSkills: (getState, createSelector) =>
      createSelector([getState], s => s.protectedHandbook.skills),
    getPaymentMethods: (getState, createSelector) =>
      createSelector([getState], s => s.publicHandbook.paymentMethods),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
