import React from 'react';
import styled from 'styled-components';
import Icon from 'components/UI/Icon';
import { primaryTheme } from 'styles';

const Toast = ({ text = 'Тест', icon = 'mi-notifications_none', action, actionText = '' }) => {
  return (
    <Container hasIcon={icon}>
      {Boolean(icon) && <IconWrapper>
        <Icon icon={icon} size="24" color={primaryTheme.color.blue} />
      </IconWrapper>}
      <Text>{text}</Text>
      {Boolean(action) && Boolean(actionText) &&
        <Action onClick={action} type="button">
          {actionText}
        </Action>
      }
    </Container>
  );
};

export default Toast;

const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ hasIcon }) => hasIcon ? '24px 1fr auto' : '1fr auto'};
  grid-column-gap: 10px;
  height: 100%;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const Text = styled.span`
  font-family: sans-serif;
  font-weight: ${({ theme }) => theme.fontWeight.HEAVY};
  font-size: ${({ theme }) => theme.fontSize.M};
  line-height: 155%;
  letter-spacing: 0.01em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Action = styled.button`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.S};
  line-height: 150%;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.color.blue};
  height: 100%;
  padding: 0 5px;
`;
