import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// Styles
import { modalOverlayMount, modalOverlayUnmount } from 'styles/keyframes';
import { media } from 'styles';
// Components
import Modal from 'react-modal';

Modal.setAppElement('#app');

function ReactModalAdapter({ className, isOpen, children, ...props }) {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  const modalRef = useRef(null);

  useEffect(() => {
    if (isOpen) disableBodyScroll(modalRef.current);
    else enableBodyScroll(modalRef.current);
    return () => clearAllBodyScrollLocks();
  }, [isOpen]);

  return (
    <Modal
      className={contentClassName}
      portalClassName={className}
      overlayClassName={overlayClassName}
      foc
      ref={modalRef}
      isOpen={isOpen}
      {...props}
    >
      {children}
    </Modal>
  );
}

const StyledModal = styled(ReactModalAdapter)`
  &__overlay {
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.25);
    animation: ${({ isClosing }) =>
      isClosing ? modalOverlayUnmount : modalOverlayMount} 0.3s ease;
    overflow: inherit;
  }

  &__content {
    overflow-y: auto;
    display: contents;
    
    display: flex;
    justify-content: center;
    width: 100%;
    pointer-events: none;

    &:focus {
      outline: none;
    }

    /* ${media.SM_DOWN} {
      height: 100%;
    } */
  }
`;

export default StyledModal;
