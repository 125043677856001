import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PrivateTemplate from 'templates/PrivateTemplate';
import { getCookie } from 'utils/cookie';
import PrivateRoutes from '../privateRoutes';
import CommonRoutes from '../commonRoutes';

export default function Cabinet() {
  const accessToken = getCookie(`token`);
  if (!accessToken) {
    return <Redirect to="/login" />;
  }
  return (
    <PrivateTemplate>
      <Switch>
        <Route path="/user" exact>
          <PrivateRoutes.UserSettings />
        </Route>
        <Route path="/company/legal-entities/:id" exact>
          <PrivateRoutes.EntityPage />
        </Route>
        <Route path="/companies/:id" exact>
          <PrivateRoutes.EditBranch />
        </Route>
        <Route path="/employees/:freelancers?/:id">
          <PrivateRoutes.EmployeePage />
        </Route>
        <Route path="/employees" exact>
          <PrivateRoutes.EmployeesPage />
        </Route>
        <Route path="/schedule-weekend" exact>
          <PrivateRoutes.WeekendSchedule />
        </Route>
        <Route path="/bin" exact>
          <PrivateRoutes.RecycleBin />
        </Route>
        <Route path="/orders" exact>
          <PrivateRoutes.Orders />
        </Route>
        <Route path="/orders/:id" exact>
          <PrivateRoutes.Order />
        </Route>
        <Route path="/orders/:id/schedule" exact>
          <PrivateRoutes.OrderSchedule />
        </Route>
        <Route path="/orders/:id/card" exact>
          <PrivateRoutes.OrderCard />
        </Route>
        <Route path="/orders/:id/application" exact>
          <PrivateRoutes.Application />
        </Route>
        <Route path="/clients" exact>
          <PrivateRoutes.Clients />
        </Route>
        <Route path="/clients/:id" exact>
          <PrivateRoutes.Client />
        </Route>
        <Route path="/clients/client-info/:id" exact>
          <PrivateRoutes.ClientCard />
        </Route>
        <Route path="/schedule-orders" exact>
          <PrivateRoutes.OrdersSchedule />
        </Route>
        <Route path="/knowledge" exact>
          <PrivateRoutes.Knowledge />
        </Route>
        <Route path="/manager-schedule" exact>
          <PrivateRoutes.ManagerSchedule />
        </Route>
        <Route path="/notifications" exact>
          <PrivateRoutes.Notifications />
        </Route>
        <Route path="/system-settings/role/:id">
          <PrivateRoutes.Role />
        </Route>
        <Route path="/payment-success" exact>
          <PrivateRoutes.PaymentSuccess />
        </Route>
        <Route path="/payment-failure">
          <PrivateRoutes.PaymentFailure />
        </Route>
        <Route path="/company" exact>
          <PrivateRoutes.CompanySettings />
        </Route>
        <Route path="/system-settings" exact>
          <PrivateRoutes.SystemSettings />
        </Route>
        <Route path="/TMC-settings" exact>
          <PrivateRoutes.TMCSettings />
        </Route>
        <Route path="/margin-report" exact>
          <PrivateRoutes.MarginIncomeReport />
        </Route>
        <Route path="/news" exact>
          <PrivateRoutes.News />
        </Route>
        <Route path="/news/:id" exact>
          <PrivateRoutes.NewsCard />
        </Route>
        <Route path="/tariff" exact>
          <PrivateRoutes.Tariff />
        </Route>
        <Route path="/tariffs" component={() => {
          window.location.href = 'https://digital-cleaning.ru/tarify'
        }} />

        <Route path="/404">
          <CommonRoutes.NotFound />
        </Route>
      </Switch>
    </PrivateTemplate>
  )
}
