import React from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { media } from 'styles';

export const WrappedToastContainer = ({ className, ...rest }) => (
  <div className={className}>
    <ToastContainer {...rest} />
  </div>
);

export default styled(WrappedToastContainer)`
  .Toastify__toast-container {
    min-width: 280px;
    width: auto;
    top: 4em;
    z-index: 99999;

    ${media.MD_DOWN} {
      top: 7em;
    }
  }
  .Toastify__toast {
    padding: 10px;
    min-height: 44px;
    box-shadow: 0px 0px 10px #5a7fcc;
    border-radius: 6px;
  }
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.color.black};
  }
  .Toastify__toast--error {
    background: ${({ theme }) => theme.color.red};
  }
  .Toastify__toast--warning {
    background: ${({ theme }) => theme.color.beige};
  }
  .Toastify__toast--success {
    background: ${({ theme }) => theme.color.modernGreen};
  }
  .Toastify__progress-bar {
    background: ${({ theme }) => theme.color.blue};
  }
  .Toastify__close-button {
    display: none;
  }
`;
