import { account } from 'utils/axiosInstances';
import { camelize, snakeify } from 'utils/objectUtilities';

const URL = '/company/order-statuses';

export const fetchStatuses = async () => {
  try {
    const response = await account.get(URL);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const updateStatuses = async payload => {
  try {
    const response = await account.put(URL, snakeify(payload));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};
