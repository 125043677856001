import { createGlobalStyle } from 'styled-components';
// Load material icons font
import 'assets/icons.css';
import 'assets/font.css';
// Reset custom browser styles
import './normalize.css';
// Fix iPhone input zoom
import './iPhoneFix.css';
// React Toastify
import 'react-toastify/dist/ReactToastify.min.css';

import { primaryTheme } from './themes';

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    height: 100%; 
  }

  #app {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .overflow-auto {
    overflow-y: auto;
  }

  .overflow-hidden {
    overflow-y: hidden;
  }
  
  .Range {
    & > .rc-slider-track {
      background-color: #6C8BF9;
    }
    
    & > .rc-slider-handle {
      border-color: #6C8BF9;
    }
  }

  *, *:before, *:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
		font: inherit;
		color: inherit;
  }

  #Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
  }
  
  & > .open {
    height: 100%;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
		line-height: 17px;
    color: ${primaryTheme.color.black};
    background: linear-gradient(90deg, ${primaryTheme.color.violetBlue} 18%, ${primaryTheme.color.littleBoyBlue} 100%);
    -webkit-overflow-scrolling: touch;
  }

  ol, ul {
    list-style: none;
  }

  p, ul, li {
    margin: 0;
    padding: 0;
  }

  input,
  button,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border: 0;
    background-color: transparent;
    &:focus {
      outline: 0;
      box-shadow: 0;
    }
  }  


  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    padding: 0;
    &:disabled {
      cursor: initial;
    }
  }

	a {
		text-decoration: none;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
      -moz-appearance: textfield; /* Firefox */
  }

`;

export default GlobalStyle;
