
import React, { useState, useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// Hooks
import useWindowWidth from 'utils/hooks/useWindowWidth';
// Styles
import 'simplebar/dist/simplebar.min.css';
// Components
import SideMenuBlock from './components/SideMenuBlock';
import SideMenuLogotype from './components/SideMenuLogotype';
import SideMenuBranches from './components/SideMenuBranches';
import SideMenuFooter from './components/SideMenuFooter';
import {
  SideMenu,
  SideMenuNav,
  SideMenuMainIcon,
  StyledSimpleBar,
  HeadSideMenuButton,
  DisabledButton,
} from './styles';

export default function SideMenuComponent({
  opened,
  toggleOpened,
  company,
  changeBranch,
  branchesOpened,
  setBranchesOpened,
  openBranchesHandler,
  branches,
  logotypeHeight,
  user,
  orderListViewPerm,
  orderScheduleViewPerm,
  techStaffScheduleViewPerm,
  freeStaffScheduleViewPerm,
  taskScheduleViewYourPerm,
  techStaffViewPerm,
  adminStaffViewPerm,
  freeStaffViewPerm,
  marginIncViewPerm,
  companyDataViewPerm,
  statusesViewPerm,
  notificationsViewPerm,
  paymentViewPerm,
  clientsListViewPerm,
  knowledgeBaseViewPerm,
  branchesTariffPerm,
  workScheduleTariffPerm,
  marginalIncomeTariffPerm,
  knowledgeBaseViewTariffPerm
}) {
  const sideMenuRef = useRef(null);
  const width = useWindowWidth();
  const [opennedTab, setOpennedTab] = useState(null);

  const dealingsMenu = {
    title: 'Сделки',
    icon: 'mi-assignment',
    items: [
      { name: 'Заказы', disabled: !orderListViewPerm, link: '/orders' },
      { name: 'Клиенты', disabled: !clientsListViewPerm, link: '/clients' },
    ],
  };
  const planningMenu = {
    title: 'Планирование',
    icon: 'mi-schedule',
    items: [
      { name: 'График заказов', disabled: !orderScheduleViewPerm, link: '/schedule-orders' },
      {
        name: 'График персонала',
        disabled: !techStaffScheduleViewPerm && !freeStaffScheduleViewPerm,
        link: '/schedule-weekend',
        state: { brigade: !techStaffScheduleViewPerm ? 'freelancers' : null }
      },
      { name: 'Рабочее расписание', disabled: !taskScheduleViewYourPerm || !workScheduleTariffPerm, link: '/manager-schedule' },
    ],
  }
  const qualityСontrolMenu = {
    title: 'Контроль качества',
    icon: 'mi-check',
    items: [
      { name: 'Проверка качества', disabled: true, link: '/quality-control' },
      { name: 'Программы коррекции', disabled: true, link: '/correction-programs' },
    ],
  }
  const knowledgeMenu = {
    title: 'База знаний',
    icon: 'mi-account_balance',
    items: [
      { name: 'Библиотека', disabled: !knowledgeBaseViewTariffPerm || !knowledgeBaseViewPerm, link: '/knowledge' },
      { name: 'Академия', disabled: true, link: '/academy' },
    ],
  }
  const reportsMenu = {
    title: 'Отчеты',
    icon: 'mi-monetization_on',
    items: [
      { name: 'Маржинальный доход', disabled: !marginIncViewPerm || !marginalIncomeTariffPerm, link: '/margin-report' },
    ],
  };
  const tariffMenu = {
    title: 'Тариф',
    icon: 'mi-payment',
    items: [
      { name: 'Мой тариф', disabled: false, link: '/tariff' },
      { name: 'Все тарифы', disabled: false, link: "/tariffs", target: '_blank', },
    ],
  };
  const settingsMenu = {
    title: 'Настройки',
    icon: 'mi-settings',
    items: [
      { name: 'Компания', disabled: !companyDataViewPerm, link: '/company' },
      {
        name: 'Настройки системы',
        disabled: !statusesViewPerm && !notificationsViewPerm && !paymentViewPerm,
        link: '/system-settings',
        search: `tab=${statusesViewPerm ? 1 : notificationsViewPerm ? 2 : 4}`
      },
      {
        name: 'Персонал',
        disabled: !techStaffViewPerm && !adminStaffViewPerm && !freeStaffViewPerm,
        link: '/employees',
        search: `tab=${adminStaffViewPerm ? 1 : techStaffViewPerm ? 2 : freeStaffViewPerm ? 3 : ''}`
      },
      { name: 'Настройки ТМЦ', disabled: false, link: '/TMC-settings' },
      { name: 'Корзина', disabled: false, link: '/bin' },
    ],
  }
  useEffect(() => {
    if (sideMenuRef.current) {
      if (opened && width < 767)
        disableBodyScroll(sideMenuRef.current, {
          allowTouchMove: el => {
            while (el && el !== document.body) {
              if (sideMenuRef.current.contains(el)) {
                return true;
              }
              // eslint-disable-next-line no-param-reassign
              el = el.parentNode;
            }
            return false;
          },
        });
      else enableBodyScroll(sideMenuRef.current);
    }
    return clearAllBodyScrollLocks;
  }, [opened, width]);

  return (
    <SideMenu opened={opened} ref={sideMenuRef} id="sideMenu" logotypeHeight={logotypeHeight}>
      <StyledSimpleBar>
        <SideMenuLogotype
          toggleOpened={toggleOpened}
          company={company}
          toggleOpenedBranches={openBranchesHandler}
          branchesTariffPerm={branchesTariffPerm}
        />
        <SideMenuNav className="scrollbar-hide">
          <HeadSideMenuButton as={true && DisabledButton} to="/dushboard" disabled activeClassName="selected">
            <SideMenuMainIcon icon="mi-desktop_mac" />
            Рабочий стол
          </HeadSideMenuButton>
          <SideMenuBlock {...dealingsMenu} toggleOpened={toggleOpened} opennedTab={opennedTab} setOpennedTab={setOpennedTab} />
          <SideMenuBlock {...planningMenu} toggleOpened={toggleOpened} opennedTab={opennedTab} setOpennedTab={setOpennedTab} />
          <SideMenuBlock {...qualityСontrolMenu} toggleOpened={toggleOpened} opennedTab={opennedTab} setOpennedTab={setOpennedTab} />
          <SideMenuBlock {...knowledgeMenu} toggleOpened={toggleOpened} opennedTab={opennedTab} setOpennedTab={setOpennedTab} />
          <SideMenuBlock {...reportsMenu} toggleOpened={toggleOpened} opennedTab={opennedTab} setOpennedTab={setOpennedTab} />
          <SideMenuBlock {...settingsMenu} toggleOpened={toggleOpened} opennedTab={opennedTab} setOpennedTab={setOpennedTab} />
          <SideMenuBlock {...tariffMenu} toggleOpened={toggleOpened} opennedTab={opennedTab} setOpennedTab={setOpennedTab} />
        </SideMenuNav>
        <SideMenuFooter toggleOpened={toggleOpened} user={user} />
      </StyledSimpleBar>

      {branchesOpened && (
        <SideMenuBranches
          company={company}
          branches={branches}
          changeBranch={changeBranch}
          close={() => setBranchesOpened(false)}
        />
      )}
    </SideMenu>
  );
}
