import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'
import { get } from 'lodash'

const NotificationItem = ({ date, text, handleSetNotification, id, readed, data }) => {
  const getLinkTo = () => {
    if(get(data,'actions[0].ref','') && get(data,'actions[0].obj.id','')) {
      const { ref } = data.actions[0] 
      if(ref === 'orders-card') return {pathname: `/orders/${data.actions[0].obj.id}/card`,state:{tab:1}}
      if(ref === 'orders-supplies') return {pathname: `/orders/${data.actions[0].obj.id}/card`,state:{tab:5}}
      if(ref === 'orders-estimate') return {pathname: `/orders/${data.actions[0].obj.id}/card`,state:{tab:1}}
    }
    return ''
  }

  return (
    <Container  as={getLinkTo() && Link} to={getLinkTo()} >
      <Header>
        <Date>{date}</Date>
        {!readed && (
          <Button type="button" onClick={() => handleSetNotification(id)}>
            прочитать
          </Button>
        )}
      </Header>
      <Text>{text}</Text>
    </Container>
  );
};

export default NotificationItem;

const Container = styled.div`
  width: 100%;
  min-height: 69px;
  padding: 11px 20px;
  border-top: 1px solid ${({ theme }) => theme.color.lightGray};
  display: block;

  &:first-of-type {
    border-top: 1px solid transparent;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Date = styled.p`
  font-weight: bold;
`;

const Text = styled.p`
  padding-top: 5px;
`;

const Button = styled.button`
  font-size: ${({ theme }) => theme.fontSize.S};
  color: ${({ theme }) => theme.color.darkGray};
`;
