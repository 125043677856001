import { account } from 'utils/axiosInstances';
import { snakeify, camelize } from 'utils/objectUtilities';

const URL = '/knowledge-categories';

export const fetchCategories = async () => {
  try {
    const { data } = await account.get(URL);
    return data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.data.message);
  }
};

export const createCategory = async payload => {
  try {
    const { data } = await account.post(URL, payload);
    return data;
  } catch (error) {
    const { response } = error;
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};

export const editCategory = async (id, payload) => {
  try {
    const { data } = await account.put(`${URL}/${id}`, payload);
    return data;
  } catch (error) {
    const { response } = error;
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};

export const deleteCategory = async id => {
  try {
    const { data } = await account.delete(`${URL}/${id}`);
    return data;
  } catch (error) {
    const { response } = error;
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};

export const createFileToBase64 = async (categoryId, payload) => {
  try {
    const { data } = await account.post(`${URL}/${categoryId}/file`, payload);
    return data;
  } catch (error) {
    const { response } = error;
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};
// create file without toBase64
export const createFile = async (categoryId, payload) => {
  const formData = new FormData();
  formData.append("name", "John");
  formData.append("files[]", payload.content, `${payload.name}.${payload.extension}`);

  try {
    const response = await account.post(`${URL}/${categoryId}/upload-files`, formData);
    return camelize(response.data.data, true);
  } catch ({ response }) {
    throw new Error(response.message, response.status);
  }
}

export const editFile = async (categoryId, id, payload) => {
  try {
    const { data } = await account.put(`${URL}/${categoryId}/file/${id}`, payload);
    return data;
  } catch (error) {
    const { response } = error;
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};

export const editFileName = async (categoryId, fileId, newName) => {
  try {
    const { data } = await account.patch(`${URL}/${categoryId}/file/${fileId}/rename`, snakeify({ newName }));
    return data;
  } catch (error) {
    const { response } = error;
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};

export const deleteFile = async (categoryId, id) => {
  try {
    const { data } = await account.delete(`${URL}/${categoryId}/file/${id}`);
    return data;
  } catch (error) {
    const { response } = error;
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};
