import {
  getHabitationSettings,
  createHabitationSetting,
  editHabitationSetting,
} from 'api/entityTariffs.api';
import { createDuck } from './utils/createDuck';
// Types
import {
  RoomSettings,
  createHabitationSettingType,
  editHabitationSettingType,
  getHabitationSettingsType
} from 'core/models/entityTariffs.model';
import { actionsType } from './types/actionTypes';
import { effectBodyType } from './types/effectTypes';
import { selectorType } from './types/selectorsTypes';

interface IState {
  habitationSettings: RoomSettings[],
  isLoading: boolean,
  errorMessage: string,
}

export type thisEffectBodyType = effectBodyType<IState, thisActionsType>;

type thisEffectsType = {
  getHabitationSettings: getHabitationSettingsType,
  createHabitationSetting: createHabitationSettingType,
  editHabitationSetting: editHabitationSettingType,
};

type thisSelectorsType = {
  getErrorMessage: selectorType
  isLoading: selectorType,
  getHabitationSettings: selectorType,
}

type thisActionsType = actionsType<IState>;
interface IOptions {
  name: string,
  initialState: IState,
  actions: thisActionsType,
  effects: thisEffectsType,
  // TODO: make correct selectors typing (with strict typing)
  selectors: thisSelectorsType,
}

export const options: IOptions = {
  name: 'entityTariffs',
  initialState: {
    habitationSettings: [],
    isLoading: false,
    errorMessage: ''
  },
  actions: {
    basicRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    basicSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    basicFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    getHabitationSettingsSuccess: (data: RoomSettings[]) => state => ({
      ...state,
      isLoading: false,
      habitationSettings: data,
    }),
    createHabitationSettingSuccess: data => state => ({
      ...state,
      isLoading: false,
    }),
    editHabitationSettingSuccess: data => state => ({
      ...state,
      isLoading: false,
    }),
  },
  effects: {
    getHabitationSettings: (id) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.basicRequest());
      try {
        const response = await getHabitationSettings(id);
        dispatch(duckActions.getHabitationSettingsSuccess(response));
      } catch (error) {
        dispatch(duckActions.basicFailure(error));
      }
    },
    createHabitationSetting: (settingData) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.basicRequest());
      try {
        const response = await createHabitationSetting(settingData);
        dispatch(duckActions.createHabitationSettingSuccess(response));
      } catch (error) {
        dispatch(duckActions.basicFailure(error));
      }
    },
    editHabitationSetting: ({ settingId, settingData, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.basicRequest());
      try {
        const response = await editHabitationSetting(settingId, settingData);
        dispatch(duckActions.editHabitationSettingSuccess(response));
      } catch (error) {
        dispatch(duckActions.basicFailure(error));
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], (s: IState) => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], (s: IState) => s.isLoading),
    getHabitationSettings: (getState, createSelector) => createSelector([getState], (s: IState) => s.habitationSettings || []),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options) as {
  actions: any,
  selectors: thisSelectorsType,
  effects: thisEffectsType,
  reducer: (state: any, action: any) => any,
};
