import axios from 'axios';
import { getCookie } from 'utils/cookie';

const API_URL = process.env.REACT_APP_API_URL

export const base = axios.create({
  baseURL: API_URL,
});

export const auth = axios.create({
  baseURL: `${API_URL}/auth`,
});

export const handbook = axios.create({
  baseURL: `${API_URL}/handbook`,
});

export const protectedHandbook = axios.create({
  baseURL: `${API_URL}/handbook`,
});

export const account = axios.create({
  baseURL: `${API_URL}/account`,
});

export const guest = axios.create({
  baseURL: `${API_URL}/guest`,
});

base.interceptors.request.use(config => {
  const token = getCookie(`token`);

  if (token) {
    // eslint-disable-next-line
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

account.interceptors.request.use(config => {
  const token = getCookie(`token`);

  if (token) {
    // eslint-disable-next-line
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

account.interceptors.response.use(
  response => response,
  error => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
);

protectedHandbook.interceptors.request.use(config => {
  const token = getCookie(`token`);

  if (token) {
    // eslint-disable-next-line
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});
