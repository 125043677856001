const XXS_MAX = 413;
const XS_MIN = 414;
const XS_MID = 530;
const XS_MAX = 767;
const SM_MIN = 768;
const SM_MAX = 991;
const MD_MIN = 992;
const MD_MAX = 1199;
const LG_MIN = 1200;
const LG_MAX = 1322;

const breakPoints = {
  // Extra small
  XXS: `@media (max-width:${XXS_MAX}px)`,
  XS: `@media (min-width:${XS_MIN}px) and (max-width:${XS_MAX}px)`,
  XS_DOWN: `@media (max-width:${XS_MAX}px)`,
  XS_MID_DOWN: `@media (max-width:${XS_MID}px)`,
  NOT_XS: `@media (min-width:${SM_MIN}px)`,
  // Small
  SM: `@media (min-width:${SM_MIN}px) and (max-width:${SM_MAX}px)`,
  SM_UP: `@media (min-width:${SM_MIN}px)`,
  SM_DOWN: `@media (max-width:${SM_MAX}px)`,
  NOT_SM: `@media 
    (max-width:${XS_MAX}px),
    (min-width:${MD_MIN}px)`,
  // Medium
  MD: `@media (min-width:${MD_MIN}px) and (max-width:${MD_MAX}px)`,
  MD_UP: `@media (min-width:${MD_MIN}px)`,
  MD_DOWN: `@media (max-width:${MD_MAX}px)`,
  NOT_MD: `@media 
    (max-width:${SM_MAX}px),
    (min-width:${LG_MIN}px)`,
  // Large
  LG: `@media (min-width:${LG_MIN}px)`,
  NOT_LG: `@media (max-width:${MD_MAX}px)`,
  LG_UP: `@media (min-width: ${LG_MIN}px)`,
  LG_DOWN: `@media (max-width:${LG_MAX}px)`,
};

export default breakPoints;