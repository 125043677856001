import {
  fetchFiles,
  sendFile,
  setFileComment,
  fetchObjectComments,
  createObjectComment,
  editObjectComment,
  deleteObjectComment,
  copyFileToObject,
} from 'api/orderObjectCard.api';
import {
  deleteFile,
  editFileName,
} from 'api/knowledge.api';
// Ducks
import { toast } from 'react-toastify';
import { effects as modalEffects } from './modal.duck';
// Helpers
import { createDuck } from './utils/createDuck';

export const options = {
  name: 'orderObjectCard',
  initialState: {
    orderFiles: [],
    objectFiles: [],
    objectComments: [],
    constants: {
      orderUrl: '/orders',
      objectUrl: '/client-objects',
      before: 'before',
      after: 'after',
      shared: 'shared',
    },

    errorMessage: '',
    loading: false,
  },
  actions: {
    fetchFilesRequest: () => state => ({
      ...state,
      loading: true
    }),
    fetchFilesSuccess: ({ data, url }) => state => ({
      ...state,
      [url === state.constants.orderUrl ?
        'orderFiles' :
        'objectFiles'
      ]: data.reduce((dataAcc, item) => [
        ...dataAcc,
        {
          ...item,
          files: item.files.map(file => {
            const extensionObj = file.filename.match(/\.[0-9a-z]+$/i) || [];
            const [extension] = extensionObj;

            return { ...file, extension: extension ? extension.replace('.', '') : '' }
          })
        }
      ], []),
      loading: false
    }),
    fetchFilesFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false
    }),
    sendFilesRequest: () => state => ({
      ...state,
      loading: true
    }),
    sendFilesSuccess: () => state => ({
      ...state,
      loading: false
    }),
    sendFilesFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false
    }),
    setFileCommentRequest: () => state => ({
      ...state,
      loading: true
    }),
    setFileCommentSuccess: () => state => ({
      ...state,
      loading: false
    }),
    setFileCommentFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false
    }),
    editFileRequest: () => state => ({
      ...state,
      loading: true
    }),
    editFileSuccess: () => state => ({
      ...state,
      loading: false
    }),
    editFileFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false
    }),
    deleteFileRequest: () => state => ({
      ...state,
      loading: true
    }),
    deleteFileSuccess: () => state => ({
      ...state,
      loading: false
    }),
    deleteFileFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false
    }),
    fetchObjectCommentsRequest: () => state => ({
      ...state,
      loading: true
    }),
    fetchObjectCommentsSuccess: comments => state => ({
      ...state,
      objectComments: comments,
      loading: false
    }),
    fetchObjectCommentsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false
    }),
    objectCommentsRequest: () => state => ({
      ...state,
      loading: true
    }),
    objectCommentsSuccess: () => state => ({
      ...state,
      loading: false
    }),
    objectCommentsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false
    }),
    deleteObjectCommentsRequest: () => state => ({
      ...state,
      loading: true
    }),
    deleteObjectCommentsSuccess: id => state => ({
      ...state,
      objectComments: state.objectComments.filter(comment => comment.id !== id),
      loading: false
    }),
    deleteObjectCommentsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false
    }),
    copyFileRequest: () => state => ({
      ...state,
      loading: true
    }),
    copyFileSuccess: () => state => ({
      ...state,
      loading: false
    }),
    copyFileFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
  },
  effects: {
    fetchFiles: ({ id, url }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchFilesRequest());
      try {
        const { data } = await fetchFiles(id, url);
        dispatch(duckActions.fetchFilesSuccess({ data, url }));
      } catch (error) {
        dispatch(duckActions.fetchFilesFailure(error));
      }
    },
    sendFile: ({ id, url, urlType, payload }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.sendFilesRequest());
      try {
        const { uploaded, oversized, failed } = await sendFile(id, url, urlType, payload);
        dispatch(duckActions.sendFilesSuccess());

        if (oversized.length)
          toast.warn('Размер файла слишком велик');
        else if (failed.length)
          toast.warn('Произошла ошибка, попробуйте снова');
        else {
          toast.success('Файл добавлен');
          await dispatch(duckEffects.setFileComment({ fileId: uploaded[0].id, categoryId: id, url, comment: payload.comment }))
          await dispatch(duckEffects.fetchFiles({ id, url }))
          dispatch(modalEffects.closeModal());
        }
      } catch (error) {
        dispatch(duckActions.sendFilesFailure(error));
        toast.error('Произошла ошибка, попробуйте снова');
      }
    },
    editFile: ({ id, fileId, url, urlType, categoryId, ...payload }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.editFileRequest());
      try {
        const fileExtension = payload.name.match(/\.[0-9a-z]+$/i);
        await editFileName(categoryId, fileId, payload.name.replace(fileExtension, ''))
        dispatch(duckActions.editFileSuccess());

        await dispatch(duckEffects.setFileComment({ categoryId: id, fileId, url, comment: payload.comment }))
        toast.success('Файл обновлен');

        await dispatch(duckEffects.fetchFiles({ id, url }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.editFileFailure(error));
      }
    },
    setFileComment: ({ categoryId, fileId, url, comment }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.setFileCommentRequest());
      try {
        await setFileComment(categoryId, fileId, url, comment);
        dispatch(duckActions.setFileCommentSuccess());
      } catch (error) {
        dispatch(duckActions.setFileCommentFailure(error));
      }
    },
    deleteFile: ({ id, url, categoryId, fileId, }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteFileRequest());
      try {
        await deleteFile(categoryId, fileId);
        dispatch(duckActions.deleteFileSuccess());
        toast.success('Документ удален');
        dispatch(modalEffects.closeModal());
        await dispatch(duckEffects.fetchFiles({ id, url }));
      } catch (error) {
        dispatch(duckActions.deleteFileFailure(error));
      }
    },
    fetchObjectComments: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchObjectCommentsRequest());
      try {
        const response = await fetchObjectComments(id);
        dispatch(duckActions.fetchObjectCommentsSuccess(response.data));
      } catch (error) {
        dispatch(duckActions.fetchObjectCommentsFailure(error));
      }
    },
    createObjectComment: ({ id, comment }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.objectCommentsRequest());
      try {
        await createObjectComment(id, comment);
        dispatch(duckActions.objectCommentsSuccess());
        toast.success('Запись к объекту добавлена');

        await dispatch(duckEffects.fetchObjectComments(id));
      } catch (error) {
        dispatch(duckActions.objectCommentsFailure(error));
      }
    },
    editObjectComment: ({ id, commentId, comment }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.objectCommentsRequest());
      try {
        await editObjectComment(id, commentId, comment);
        dispatch(duckActions.objectCommentsSuccess());
        toast.success('Запись к объекту изменена');

        await dispatch(duckEffects.fetchObjectComments(id));
      } catch (error) {
        dispatch(duckActions.objectCommentsFailure(error));
      }
    },
    deleteObjectComment: ({ id, commentId }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deleteObjectCommentsRequest());
      try {
        await deleteObjectComment(id, commentId);
        dispatch(duckActions.deleteObjectCommentsSuccess(commentId));
        toast.success('Запись к объекту удалена');
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteObjectCommentsFailure(error));
      }
    },
    copyFileToObject: ({ id, fileId }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.copyFileRequest());
      try {
        await copyFileToObject(id, fileId);
        toast.success('Файл добавлен в карточку объекта');
        dispatch(duckActions.copyFileSuccess());
      } catch (error) {
        dispatch(duckActions.copyFileFailure(error));
      }
    },

  },
  selectors: {
    getOrderFiles: (getState, createSelector) => createSelector([getState], s => s.orderFiles),
    getObjectFiles: (getState, createSelector) => createSelector([getState], s => s.objectFiles.find(item => item.type === 'shared')),
    getObjectComments: (getState, createSelector) => createSelector([getState], s => s.objectComments),
    getLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
