import { account } from 'utils/axiosInstances';
import { snakeify, camelize } from 'utils/objectUtilities';

const URL = '/marginal-incomes';
const orderURL = '/orders';
const marginURL = 'marginal-income'

export const fetchMarginReport = async params => {
  const { date1, date2, individual, employee, object, client } = params
  try {
    const response = await account.get(
      `${URL}?${date1 ? `date1=${date1}` : ''}
       ${date2 ? `&date2=${date2}` : ''}
       ${individual ? `&individual=${individual}` : ''}
       ${employee ? `&employee=${employee}` : ''}
       ${object ? `&object=${object}` : ''}
       ${client ? `&client=${client}` : ''}`
    );

    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const fetchOrderMarginReport = async id => {
  try {
    const response = await account.get(`${orderURL}/${id}/${marginURL}`);

    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const updateOrderMarginReport = async (id, data) => {
  try {
    const response = await account.put(`${orderURL}/${id}/${marginURL}/change`, snakeify(data, true));

    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const getMarginReportPDF = async params => {
  const { date1, date2, individual, employee, object, client } = params
  try {
    const response = await account.get(
      `${orderURL}/${marginURL}/pdf?
        ${date1 ? `date1=${date1}` : ''}
        ${date2 ? `&date2=${date2}` : ''}
        ${individual ? `&individual=${individual}` : ''}
        ${employee ? `&employee=${employee}` : ''}
        ${object ? `&object=${object}` : ''}
        ${client ? `&client=${client}` : ''}
      `
    );

    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const getOrderMarginReportPDF = async id => {
  try {
    const response = await account.get(`${orderURL}/${marginURL}/${id}/pdf`, {
      responseType: 'blob',
    });

    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
