import { fetchTags, createTag, editTag, deleteTag } from 'api/tags.api';
import { toast } from 'react-toastify';
// Helpers
import { createDuck } from './utils/createDuck';
import { effects as modalEffects } from '../ducks/modal.duck';

export const options = {
  name: 'tags',
  initialState: {
    tags: [],
    errorMessage: '',
    isLoading: false,
  },
  actions: {
    // Get tags
    fetchTagsRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    fetchTagsSuccess: ({ items: tags }) => state => ({
      ...state,
      isLoading: false,
      tags,
    }),
    fetchTagsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    // Create tags
    createTagRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    createTagSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    createTagFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    // Update tags
    editTagRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    editTagSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    editTagFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    // Delete tags
    deleteTagRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    deleteTagSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    deleteTagFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
  },
  effects: {
    // Tags
    fetchTags: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchTagsRequest());
      try {
        const data = await fetchTags();
        dispatch(duckActions.fetchTagsSuccess(data));
      } catch (error) {
        dispatch(duckActions.fetchTagsFailure(error));
      }
    },
    createTag: payload => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.createTagRequest());
      try {
        const data = await createTag(payload);
        dispatch(duckActions.createTagSuccess(data));
        await dispatch(duckEffects.fetchTags());
      } catch (error) {
        dispatch(duckActions.createTagFailure(error));
      }
    },
    editTag: ({ id, ...payload }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.editTagRequest());
      try {
        const { success } = await editTag(id, payload);
        dispatch(duckActions.editTagSuccess());
        if (success) {
          toast.success('Тег изменён');
          await dispatch(duckEffects.fetchTags());
          dispatch(modalEffects.closeModal());
        }
      } catch (error) {
        dispatch(duckActions.editTagFailure(error));
      }
    },
    deleteTag: id => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteTagRequest());
      try {
        const { success } = await deleteTag(id);
        dispatch(duckActions.deleteTagSuccess());

        if (success) {
          toast.success('Тег удален');
          await dispatch(duckEffects.fetchTags());
          dispatch(modalEffects.closeModal());
        }
      } catch (error) {
        dispatch(duckActions.deleteTagFailure(error));
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isisLoading: (getState, createSelector) => createSelector([getState], s => s.isLoading),
    getTags: (getState, createSelector) => createSelector([getState], s => s.tags),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
