import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
// Styles
import generateShortName from 'utils/data/generateShortName';
// Components
import Icon from 'components/UI/Icon';
import NotificationsMenu from 'components/NotificationsMenu';
// Local Components
import {
  Container,
  UserName,
  MenuIcon,
  Menu,
  MenuHeader,
  MenuContent,
  MenuButton,
  StyledIcon,
  Indicator,
  MenuButtonText,
  ExtendedMenuIcon
} from './styles'

const Content = ({
  user,
  notifications,
  notificationsCount,
  maxToShow,
  toggleMenuOpened,
  setUserNotification,
  logout,
}) => {
  const [menuType, toggleMenuType] = useState('main');

  const helpButtonHandler = () => {
    // open usedesk widget
    const usedeskMainButton = document.querySelector('#usedesk-messenger #uw-main-button');
    if (usedeskMainButton) {
      usedeskMainButton.click();
      toggleMenuOpened(false);
    }
  }

  switch (menuType) {
    case 'main': return (
      <Menu>
        <MenuHeader>
          <UserName>{generateShortName(user)}</UserName>
        </MenuHeader>
        <MenuContent>
          <MenuButton onClick={() => toggleMenuOpened(false)} as={NavLink} to="/user">
            <StyledIcon icon='mi-settings' size='24' />
            Настройки пользователя
          </MenuButton>
          <MenuButton onClick={() => toggleMenuType('notifications')}>
            <StyledIcon icon='mi-notifications_none' size='24' />
            <MenuButtonText>
              Уведомления
              {notificationsCount > 0 &&
                <Indicator />
              }
            </MenuButtonText>
            <ExtendedMenuIcon icon='mi-keyboard_arrow_right' size='24' />
          </MenuButton>
          <MenuButton onClick={helpButtonHandler}>
            <StyledIcon icon='mi-help_outline' size='24' />
            Помощь
          </MenuButton>
          <MenuButton onClick={logout}>
            <StyledIcon icon='mi-exit_to_app' size='24' />
            Выход
          </MenuButton>
        </MenuContent>
      </Menu>
    )
    case 'notifications': return <NotificationsMenu
      user={user}
      notifications={notifications}
      maxToShow={maxToShow}
      setUserNotification={setUserNotification}
      toggleMenuOpened={toggleMenuOpened}
      toggleMenuType={toggleMenuType}
    />
    default: break;
  }
}
const ToastDropdown = ({
  className,
  notificationsCount,
  userMenuOpened,
  toggleUserMenuOpened,
  ...props
}) => {

  return (
    <Container className={className} onClick={(e) => e.stopPropagation()}> {/* for prevent closing of UserMenu */}
      <MenuIcon onClick={() => toggleUserMenuOpened(!userMenuOpened)} shadow={userMenuOpened}>
        <Icon icon="mi-person_outline" size="36" color="#5a7fcc" />
        {notificationsCount > 0 &&
          <StyledIndicator />
        }
      </MenuIcon>
      {userMenuOpened &&
        <Content toggleMenuOpened={toggleUserMenuOpened} notificationsCount={notificationsCount} {...props} />
      }
    </Container>
  );
}

export default ToastDropdown;

const StyledIndicator = styled(Indicator)`
  position: absolute;
  right: 7px;
  top: 6px;
`
