import { account } from 'utils/axiosInstances';
import { getPaginationParams } from './utils/paginationUtils';

const URL = '/clients';
const URL_OBJ = '/client-objects';

export const fetchAllObjects = async () => {
  try {
    const response = await account.get(URL_OBJ);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const fetchObjects = async (id, params = { limit: 0, offset: 1 }) => {
  try {
    const response = await account.get(
      `${URL}/${id}/objects?${getPaginationParams(params.offset, params.limit)}`
    );
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const createObject = async (id, data) => {
  try {
    const response = await account.post(`${URL}/${id}/objects`, data);
    return response.data;
  } catch (error) {
    const { response } = error;
    const { status } = response;
    let errorMessage = '';

    if (status === 422) {
      const { errors } = response.data;
      throw errors;
    }

    if (status === 500) {
      errorMessage = 'Произошла серверная ошибка, попробуйте позже'
    }

    throw new Error(errorMessage, status);
  }
};

export const updateObject = async (id, data) => {
  try {
    const response = await account.put(`${URL_OBJ}/${id}`, data);
    return response.data;
  } catch (error) {
    const { response } = error;
    const { status } = response;
    let errorMessage = '';

    if (status === 500) {
      errorMessage = 'Произошла серверная ошибка, попробуйте позже'
    }

    throw new Error(errorMessage, status);
  }
};

export const deleteObject = async (id, data) => {
  try {
    const response = await account.delete(`${URL_OBJ}/${id}`, { data });
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const objectTransfer = async ({ id, employee_id }) => {
  try {
    const response = await account.patch(`${URL_OBJ}/${id}/transfer`, { employee_id });
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};
