import {
  getLegalEntities,
  restoreLegalEntity,
  deleteLegalEntity,
  getEmployees,
  restoreEmployee,
  deleteEmployee,
  getOrders,
  restoreOrder,
  deleteOrder,
  getClients,
  restoreClient,
  deleteClient,
  getKnowledgeBaseItems,
  restoreKnowledgeBaseCategory,
  restoreKnowledgeBaseFile,
  deleteKnowledgeBaseCategoriesAndFiles,
  getStorageEquipments,
  restoreStorageEquipment,
  deleteStorageEquipment,
  getFreelancers,
  restoreFreelancer,
  deleteFreelancer,
  getBranches,
  restoreBranch,
  deleteBranch,
} from 'api/recycleBin.api';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';
import { paginationResponseParse } from './utils/paginationParse';
import { effects as modalEffects } from './modal.duck';

const parseDeleteResult = (result, recycleBinTabName) => {
  const successCount = result.successDeletedIds.length;
  const failCount = result.failDeletedIds.length;
  const permissionDeniedCount = (result.permissionDeniedIds || []).length;

  if (successCount) {
    toast.success(`${recycleBinTabName} удалено: ${successCount}`);
  }
  if (failCount) {
    toast.warn(`${recycleBinTabName} НЕ удалено: ${failCount}`);
  }
  if (permissionDeniedCount) {
    toast.warn(`Недостаточно прав: ${permissionDeniedCount}`)
  }
}

export const options = {
  name: 'recycleBin',
  initialState: {
    legalEntities: [],
    employees: [],
    freelancers: [],
    orders: [],
    clients: [],
    branches: [],
    knowledgeBaseItems: [],
    storageEquipments: [],
    errorMessage: '',
    loading: false,
    ordersCount: 1,
    clientsCount: 1,
    branchesCount: 1,
    employeesCount: 1,
    freelancersCount: 1,
    entityCount: 1,
    knowledgeBaseCount: 1,
    equipmentsCount: 1,
  },
  actions: {
    // Legal Entities
    getLegalEntitiesRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getLegalEntitiesSuccess: data => state => ({
      ...state,
      loading: false,
      legalEntities: data.items,
      entityCount: data.count
    }),
    getLegalEntitiesFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    restoreLegalEntityRequest: () => state => ({
      ...state,
      loading: true,
    }),
    restoreLegalEntitySuccess: () => state => ({
      ...state,
      loading: false,
    }),
    restoreLegalEntityFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    deleteLegalEntityRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteLegalEntitySuccess: () => state => ({
      ...state,
      loading: false,
    }),
    deleteLegalEntityFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    // Employees
    getEmployeesRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getEmployeesSuccess: data => state => ({
      ...state,
      loading: false,
      employees: data.items,
      employeesCount: data.count
    }),
    getEmployeesFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    restoreEmployeeRequest: () => state => ({
      ...state,
      loading: true,
    }),
    restoreEmployeeSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    restoreEmployeeFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    deleteEmployeeRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteEmployeeSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    deleteEmployeeFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    // Freelancers
    getFreelancersRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getFreelancersSuccess: data => state => ({
      ...state,
      loading: false,
      freelancers: data.items,
      freelancersCount: data.count
    }),
    getFreelancersFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    restoreFreelancerRequest: () => state => ({
      ...state,
      loading: true,
    }),
    restoreFreelancerSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    restoreFreelancerFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    deleteFreelancerRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteFreelancerSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    deleteFreelancerFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    // Orders
    getOrdersRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getOrdersSuccess: data => state => ({
      ...state,
      loading: false,
      orders: data.items,
      ordersCount: data.count
    }),
    getOrdersFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    restoreOrderRequest: () => state => ({
      ...state,
      loading: true,
    }),
    restoreOrderSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    restoreOrderFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    deleteOrderRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteOrderSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    deleteOrderFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    // Clients
    getClientsRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getClientsSuccess: data => state => ({
      ...state,
      loading: false,
      clients: data.items,
      clientsCount: data.count,
    }),
    getClientsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    restoreClientRequest: () => state => ({
      ...state,
      loading: true,
    }),
    restoreClientSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    restoreClientFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    deleteClientRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteClientSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    deleteClientFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    // branches
    getBranchesRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getBranchesSuccess: data => state => ({
      ...state,
      loading: false,
      branches: data.items,
      branchesCount: data.count,
    }),
    getBranchesFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    restoreBranchRequest: () => state => ({
      ...state,
      loading: true,
    }),
    restoreBranchSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    restoreBranchFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    deleteBranchRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteBranchSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    deleteBranchFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    // knowledgeBase
    getKnowledgeBaseItemsRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getKnowledgeBaseItemsSuccess: data => state => ({
      ...state,
      loading: false,
      knowledgeBaseItems: data.items.reduce((prValue, item) => {
        let extension = '';

        if (item.type === 'dir') extension = 'папка'
        else {
          const extensionObj = item.name.match(/\.[0-9a-z]+$/i);
          if (extensionObj) [extension] = extensionObj;
        }

        return [
          ...prValue,
          {
            ...item,
            name: extension ? item.name.replace(extension, '') : item.name,
            extension: extension ? extension.replace('.', '') : ''
          }
        ]
      }, []),
      knowledgeBaseCount: data.count
    }),
    getKnowledgeBaseItemsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    restoreKnowledgeBaseCategoryRequest: () => state => ({
      ...state,
      loading: true,
    }),
    restoreKnowledgeBaseCategorySuccess: () => state => ({
      ...state,
      loading: false,
    }),
    restoreKnowledgeBaseCategoryFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    restoreKnowledgeBaseFileRequest: () => state => ({
      ...state,
      loading: true,
    }),
    restoreKnowledgeBaseFileSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    restoreKnowledgeBaseFileFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    deleteKnowledgeBaseCategoryAndFileRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteKnowledgeBaseCategoryAndFileSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    deleteKnowledgeBaseCategoryAndFileFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),

    // Storage Equipments
    getEquipmentsRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getEquipmentsSuccess: data => state => ({
      ...state,
      loading: false,
      storageEquipments: data.items,
      equipmentsCount: data.count
    }),
    getEquipmentsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    restoreEquipmentRequest: () => state => ({
      ...state,
      loading: true,
    }),
    restoreEquipmentSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    restoreEquipmentFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    deleteEquipmentRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteEquipmentSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    deleteEquipmentFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
  },
  effects: {
    // Legal Entities
    getLegalEntities: ({ sorting, pagination, setPagination, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getLegalEntitiesRequest());
      try {
        const response = await getLegalEntities({ ...sorting, ...pagination });
        dispatch(duckActions.getLegalEntitiesSuccess(response));

        paginationResponseParse({ response, pagination, setPagination, });
      } catch (error) {
        dispatch(duckActions.getLegalEntitiesFailure(error));
      }
    },
    restoreLegalEntity: ({ id, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.restoreLegalEntityRequest());
      try {
        const response = await restoreLegalEntity(id);
        dispatch(duckActions.restoreLegalEntitySuccess(response));
        toast.success('Юр. лицо восстановлено');
        await dispatch(duckEffects.getLegalEntities({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.restoreLegalEntityFailure(error));
      }
    },
    deleteLegalEntity: ({ ids, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteLegalEntityRequest());
      try {
        const { result } = await deleteLegalEntity(ids);

        parseDeleteResult(result, 'Юр. лиц');
        dispatch(duckActions.deleteLegalEntitySuccess());

        await dispatch(duckEffects.getLegalEntities({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteLegalEntityFailure(error));
      }
    },
    // Employees
    getEmployees: ({ sorting, pagination, setPagination, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getEmployeesRequest());
      try {
        const response = await getEmployees({ ...sorting, ...pagination, });
        dispatch(duckActions.getEmployeesSuccess(response));

        paginationResponseParse({ response, pagination, setPagination, });
      } catch (error) {
        dispatch(duckActions.getEmployeesFailure(error));
      }
    },
    restoreEmployee: ({ id, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.restoreEmployeeRequest());
      try {
        const response = await restoreEmployee(id);
        dispatch(duckActions.restoreEmployeeSuccess(response));
        toast.success('Сотрудник восстановлен');
        await dispatch(duckEffects.getEmployees({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.restoreEmployeeFailure(error));
      }
    },
    deleteEmployee: ({ ids, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteEmployeeRequest());
      try {
        const { result } = await deleteEmployee(ids);

        parseDeleteResult(result, 'Сотрудников');
        dispatch(duckActions.deleteEmployeeSuccess());

        await dispatch(duckEffects.getEmployees({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteEmployeeFailure(error));
      }
    },
    // Freelancers
    getFreelancers: ({ sorting, pagination, setPagination, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getFreelancersRequest());
      try {
        const response = await getFreelancers({ ...sorting, ...pagination, });
        dispatch(duckActions.getFreelancersSuccess(response));

        paginationResponseParse({ response, pagination, setPagination, });
      } catch (error) {
        dispatch(duckActions.getFreelancersFailure(error));
      }
    },
    restoreFreelancer: ({ id, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.restoreFreelancerRequest());
      try {
        const response = await restoreFreelancer(id);
        dispatch(duckActions.restoreFreelancerSuccess(response));
        toast.success('Сотрудник восстановлен');
        await dispatch(duckEffects.getFreelancers({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.restoreFreelancerFailure(error));
      }
    },
    deleteFreelancer: ({ ids, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteFreelancerRequest());
      try {
        const { result } = await deleteFreelancer(ids);

        parseDeleteResult(result, 'Внештатных сотрудников');
        dispatch(duckActions.deleteFreelancerSuccess());

        await dispatch(duckEffects.getFreelancers({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteFreelancerFailure(error));
      }
    },
    // Orders
    getOrders: ({ sorting, pagination, setPagination, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getOrdersRequest());
      try {
        const response = await getOrders({ ...sorting, ...pagination, });
        dispatch(duckActions.getOrdersSuccess(response));

        paginationResponseParse({ response, pagination, setPagination, });
      } catch (error) {
        dispatch(duckActions.getOrdersFailure(error));
      }
    },
    restoreOrder: ({ id, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.restoreOrderRequest());
      try {
        await restoreOrder(id);
        dispatch(duckActions.restoreOrderSuccess());
        toast.success('Заказ восстановлен');
        await dispatch(duckEffects.getOrders({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.restoreOrderFailure(error));
      }
    },
    restoreOrderAndClient: ({ clientId, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.restoreOrderRequest());
      try {
        await restoreClient(clientId);
        dispatch(duckActions.restoreClientSuccess());
        toast.success('Клиент и заказы восстановлены');

        await dispatch(duckEffects.getOrders({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.restoreOrderFailure(error));
      }
    },
    deleteOrder: ({ ids, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteOrderRequest());
      try {
        const { result } = await deleteOrder(ids);

        dispatch(duckActions.deleteOrderSuccess());
        parseDeleteResult(result, 'Заказов');

        await dispatch(duckEffects.getOrders({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteOrderFailure(error));
      }
    },
    // Clients
    getClients: ({ sorting, pagination, setPagination, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getClientsRequest());
      try {
        const response = await getClients({ ...sorting, ...pagination, });
        dispatch(duckActions.getClientsSuccess(response));

        paginationResponseParse({ response, pagination, setPagination, });
      } catch (error) {
        dispatch(duckActions.getClientsFailure(error));
      }
    },
    restoreClient: ({ id, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.restoreClientRequest());
      try {
        await restoreClient(id);
        dispatch(duckActions.restoreClientSuccess());
        toast.success('Клиент восстановлен');
        await dispatch(duckEffects.getClients({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.restoreClientFailure(error));
      }
    },
    deleteClient: ({ ids, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteClientRequest());
      try {
        const { result } = await deleteClient(ids);

        dispatch(duckActions.deleteClientSuccess());
        parseDeleteResult(result, 'Клиентов');

        await dispatch(duckEffects.getClients({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteClientFailure(error));
      }
    },

    // branches
    getBranches: ({ sorting, pagination, setPagination, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getBranchesRequest());
      try {
        const response = await getBranches({ ...sorting, ...pagination, });
        dispatch(duckActions.getBranchesSuccess(response));

        paginationResponseParse({ response, pagination, setPagination, });
      } catch (error) {
        dispatch(duckActions.getBranchesFailure(error));
      }
    },
    restoreBranch: ({ id, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.restoreBranchRequest());
      try {
        await restoreBranch(id);
        dispatch(duckActions.restoreBranchSuccess());
        toast.success('Филиал восстановлен');
        await dispatch(duckEffects.getBranches({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.restoreBranchFailure(error));
      }
    },
    deleteBranch: ({ ids, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteBranchRequest());
      try {
        const { result } = await deleteBranch(ids);

        dispatch(duckActions.deleteBranchSuccess());
        parseDeleteResult(result, 'Филиалов');

        await dispatch(duckEffects.getBranches({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteBranchFailure(error));
      }
    },
    // Knowledge base items
    getKnowledgeBaseItems: ({ sorting, pagination, setPagination, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getKnowledgeBaseItemsRequest());
      try {
        const response = await getKnowledgeBaseItems({ ...sorting, ...pagination, });
        dispatch(duckActions.getKnowledgeBaseItemsSuccess(response));

        paginationResponseParse({ response, pagination, setPagination, });
      } catch (error) {
        dispatch(duckActions.getKnowledgeBaseItemsFailure(error));
      }
    },
    restoreKnowledgeBaseCategory: ({ id, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.restoreKnowledgeBaseCategoryRequest());
      try {
        const response = await restoreKnowledgeBaseCategory(id);
        dispatch(duckActions.restoreKnowledgeBaseCategorySuccess(response));
        toast.success('Папка восстановлена');
        await dispatch(duckEffects.getKnowledgeBaseItems({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.restoreKnowledgeBaseCategoryFailure(error));
      }
    },
    restoreKnowledgeBaseFile: ({ id, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.restoreKnowledgeBaseFileRequest());
      try {
        const response = await restoreKnowledgeBaseFile(id);
        dispatch(duckActions.restoreKnowledgeBaseFileSuccess(response));
        toast.success('Файл восстановлен');

        await dispatch(duckEffects.getKnowledgeBaseItems({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.restoreKnowledgeBaseFileFailure(error));
      }
    },
    deleteKnowledgeBaseCategoriesAndFiles: ({ categoriesIds, filesIds, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteKnowledgeBaseCategoryAndFileRequest());
      try {
        const { result } = await deleteKnowledgeBaseCategoriesAndFiles(categoriesIds, filesIds);

        dispatch(duckActions.deleteKnowledgeBaseCategoryAndFileSuccess());
        parseDeleteResult({
          failDeletedIds: [...result.categories.failDeletedIds, ...result.files.failDeletedIds],
          successDeletedIds: [...result.categories.successDeletedIds, ...result.files.successDeletedIds]
        }, `${filesIds.length ? `Файлов ${categoriesIds.length ? 'и папок' : ''}` : 'Папок'}`);

        await dispatch(duckEffects.getKnowledgeBaseItems({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteKnowledgeBaseCategoryAndFileFailure(error));
      }
    },

    // Storage Equipments
    getStorageEquipments: ({ sorting, pagination, setPagination, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getEquipmentsRequest());
      try {
        const response = await getStorageEquipments({ ...sorting, ...pagination, });
        dispatch(duckActions.getEquipmentsSuccess(response));

        paginationResponseParse({ response, pagination, setPagination, });
      } catch (error) {
        dispatch(duckActions.getEquipmentsFailure(error));
      }
    },
    restoreStorageEquipment: ({ id, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.restoreEquipmentRequest());
      try {
        const response = await restoreStorageEquipment(id);
        dispatch(duckActions.restoreEquipmentSuccess(response));
        toast.success('Оборудование восстановлено');
        await dispatch(duckEffects.getStorageEquipments({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.restoreEquipmentFailure(error));
      }
    },
    deleteStorageEquipment: ({ ids, pagination, sorting }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteEquipmentRequest());
      try {
        const { result } = await deleteStorageEquipment(ids);

        dispatch(duckActions.deleteEquipmentSuccess());
        parseDeleteResult(result, 'Оборудования');

        await dispatch(duckEffects.getStorageEquipments({
          ...pagination,
          ...sorting
        }));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteEquipmentFailure(error));
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
    getLegalEntities: (getState, createSelector) => createSelector([getState], s => s.legalEntities),
    getEmployees: (getState, createSelector) => createSelector([getState], s => s.employees),
    getFreelancers: (getState, createSelector) => createSelector([getState], s => s.freelancers),
    getOrders: (getState, createSelector) => createSelector([getState], s => s.orders),
    getClients: (getState, createSelector) => createSelector([getState], s => s.clients),
    getBranches: (getState, createSelector) => createSelector([getState], s => s.branches),
    getKnowledgeBaseItems: (getState, createSelector) => createSelector([getState], s => s.knowledgeBaseItems),
    getStorageEquipments: (getState, createSelector) => createSelector([getState], s => s.storageEquipments),
    getOrdersCount: (getState, createSelector) => createSelector([getState], s => s.ordersCount),
    getClientsCount: (getState, createSelector) => createSelector([getState], s => s.clientsCount),
    getBranchesCount: (getState, createSelector) => createSelector([getState], s => s.branchesCount),
    getEmployeesCount: (getState, createSelector) => createSelector([getState], s => s.employeesCount),
    getFreelancersCount: (getState, createSelector) => createSelector([getState], s => s.freelancersCount),
    getEntityCount: (getState, createSelector) => createSelector([getState], s => s.entityCount),
    getKnowledgeBaseCount: (getState, createSelector) => createSelector([getState], s => s.knowledgeBaseCount),
    getEquipmentsCount: (getState, createSelector) => createSelector([getState], s => s.equipmentsCount),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
