import { account } from 'utils/axiosInstances';
import { snakeify, camelize } from 'utils/objectUtilities';

const URL = '/orders';

export const createOrderItem = async (orderId, data) => {
  try {
    const response = await account.post(`${URL}/${orderId}/order-items`, snakeify(data, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const getTime = async (data) => {
  try {
    const response = await account.get(`/order-items/${data}/execution-time`);
    return response.data.execution_time
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const updateOrderItem = async (id, data) => {
  try {
    const response = await account.put(`/order-items/${id}`, snakeify(data, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const deleteOrderItem = async id => {
  try {
    const response = await account.delete(`/order-items/${id}`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const calculateOrderItems = async (id, data) => {
  try {
    const response = await account.put(`/order-items/${id}/result`, snakeify(data, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const updateOrderItemsGroup = async (id, data) => {
  try {
    const response = await account.put(
      `/order-items-groups${id ? `/${id}` : ''}`,
      snakeify(data, true)
    );
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const updateOrderItemsData = async (id, data) => {
  try {
    const response = await account.put(`/order-result-groups/${id}`, snakeify(data, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const updateOrderItemsTemp = async (id, data) => {
  try {
    const response = await account.put(`/order-result-groups/${id}`, snakeify(data, true));
    return camelize(response, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};
