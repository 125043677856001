import { account } from 'utils/axiosInstances';
import { camelize } from 'utils/objectUtilities';

const docURL = '/documents';

class UnprocessableEntityError extends Error {
  constructor(message, type, id,) {
    super(message);
    this.name = 'UnprocessableEntityError';
    this.status = 422;
    this.type = type || null;
    this.id = id || null;
  }
}

class ServerError extends Error {
  constructor() {
    super('Серверная ошибка, попробуйте позже либо обратитесь к своему руководителю');
    this.name = 'ServerError';
    this.status = 500;
  }
}

const errorParse = ({ response }) => {
  const { status, data: { errors, error, message, type, id }, } = response;

  if (status === 422) {
    if (error || message) {
      throw new UnprocessableEntityError(error || message, type, id,);
    }

    if (errors) {
      const errorsArr = Object.values(errors);
      if (errorsArr && errorsArr.length) {
        throw new UnprocessableEntityError(errorsArr[0]);
      }
    }

    throw new UnprocessableEntityError('Ошибка данных',);
  }

  if (status === 500) {
    throw new ServerError();
  }

  throw new Error(response.message, status);
}

export const fetchClientDocuments = async clientId => {
  try {
    const response = await account.get(`${docURL}/${clientId}`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const createClientDocument = async (legalEntityId, clientId) => {
  try {
    const response = await account.post(`${docURL}/create/${legalEntityId}/${clientId}`);
    return response.data;
  } catch (error) {
    errorParse(error);
  }
}

export const fetchClientArchiveDocuments = async clientId => {
  try {
    const response = await account.get(`${docURL}/archive/${clientId}`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const archiveClientDocument = async ids => {
  try {
    const response = await account.delete(`${docURL}/delete`, { data: { files_ids: ids } });
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const deleteClientDocument = async fileId => {
  try {
    const response = await account.delete(`${docURL}/${fileId}/delete-permanently`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const restoreClientDocument = async fileId => {
  try {
    const response = await account.patch(`${docURL}/restore/${fileId}`);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const changeClientDocument = async (fileId, data) => {
  try {
    const response = await account.patch(`${docURL}/change/${fileId}`, data);
    return response.data;
  } catch (error) {
    errorParse(error);
  }
}

export const getClientDocument = async fileId => {
  try {
    account.defaults.responseType = 'blob';
    const response = await account.get(`${docURL}/download/${fileId}`);
    account.defaults.responseType = 'json';
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const getClientLegalEntityDocument = async (legalEntityId, clientId) => {
  try {
    account.defaults.responseType = 'blob';
    const response = await account.get(`${docURL}/contract/${legalEntityId}/${clientId}`);
    account.defaults.responseType = 'json';
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const getDocumentTemplate = async type => {
  try {
    account.defaults.responseType = 'blob';
    const response = await account.get(`${docURL}/default/${type}`);
    account.defaults.responseType = 'json';
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const sendClientDocument = async (fileId, email) => {
  try {
    const response = await account.patch(`${docURL}/send/${fileId}`, { email });
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}