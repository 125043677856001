import loadable from '@loadable/component';

const Login = loadable(() => import('pages/LoginPage'));
const PasswordRecovery = loadable(() => import('pages/PasswordRecoveryPage'));
const NewPassword = loadable(() => import('pages/NewPasswordPage'));
const Registration = loadable(() => import('pages/RegisterPage'));
const CreateEmployees = loadable(() => import('pages/CreateEmployeesPage'));
const CreateCompany = loadable(() => import('pages/CreateCompanyPage'));
const PaymentCard = loadable(()=> import('pages/PaymentCard'));
const PaymentSuccess = loadable(()=> import('pages/PaymentSuccessPage'));
const PaymentFailure = loadable(()=> import('pages/PaymentFailurePage'));
const Documents = loadable(()=> import('pages/Documents'));
const CreateCompanyManually = loadable(() => import('pages/CreateCompanyManuallyPage'));
const CreateBranch = loadable(() => import('pages/CreateBranchPage'));

export default {
  Login,
  PasswordRecovery,
  NewPassword,
  Registration,
  CreateEmployees,
  CreateCompany,
  PaymentCard,
  PaymentSuccess,
  PaymentFailure,
  CreateCompanyManually,
  Documents,
  CreateBranch,
};
