import { uniqBy } from 'lodash';
import { fetchCalculator, updateTechnologies } from 'api/calc.api';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';

export const options = {
  name: 'calc',
  initialState: {
    calcList: [],
    errorMessage: '',
    loading: false,
    globalTMC: {},
  },
  actions: {
    fetchCalculatorRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchCalculatorSuccess: data => state => ({
      ...state,
      loading: false,
      calcList: data.items,
      globalTMC: data.global_supplies,
    }),
    fetchCalculatorFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    updateTechnologiesRequest: () => state => ({
      ...state,
      loading: true,
    }),
    updateTechnologiesSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    updateTechnologiesFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
  },
  effects: {
    fetchCalculator: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchCalculatorRequest());
      try {
        const response = await fetchCalculator();
        dispatch(duckActions.fetchCalculatorSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchCalculatorFailure(error));
      }
    },
    updateTechnologies: data => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.updateTechnologiesRequest());
      try {
        await updateTechnologies(data);
        dispatch(duckActions.updateTechnologiesSuccess());
        toast.success('Изменения сохранены');
        await dispatch(duckEffects.fetchCalculator());
      } catch (error) {
        dispatch(duckActions.updateTechnologiesFailure(error));
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
    getCalculator: (getState, createSelector) => createSelector([getState], s => s.calcList),
    getServices: (getState, createSelector) =>
      createSelector([getState], s =>
        s.calcList.reduce(
          (lAcc, item) => [
            ...lAcc,
            {
              id: item.id,
              name: item.name,
              technologies: uniqBy(
                item.standards.reduce(
                  (sAcc, standard) => [...sAcc, ...standard.technology_standards],
                  []
                ),
                'technology_id'
              ),
              standards: item.standards.reduce((sAcc, standart) => [...sAcc, {
                supergroup: standart.supergroup,
                name: standart.group,
                id: standart.id
              }],
                []
              ),
              factors: item.factor_groups.reduce((sAcc, factor) => [...sAcc, { ...factor }], []),
              quantityType: item.quantity_type,
            },
          ],
          []
        )
      ),
    getGlobalTMC: (getState, createSelector) => createSelector([getState], s => s.globalTMC),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
