import React from 'react';
import moment from 'moment';
// Components
import Icon from 'components/UI/Icon';
// Local Components
import NotificationItem from './NotificationItem';
import {
  List,
  ListHeader,
  ItemsContainer,
  NewItems,
  NewHeader,
  Items,
  StyledLink,
  BackButton,
} from './styles'

const NotificationsMenu = ({
  user,
  notifications,
  maxToShow,
  setUserNotification,
  toggleMenuOpened,
  toggleMenuType,
}) => {
  const { items = [], newItems = [] } = notifications?.reduce((acc, item) => {
    if (item.readAt) {
      return {
        ...acc,
        items: acc.items ? [item] : [...acc.items, item],
      };
    }
    return {
      ...acc,
      newItems: [...acc.newItems, item],
    };
  }, {
    items: [],
    newItems: []
  });

  const newItemsToShow = newItems.slice(Math.max(newItems.length - maxToShow, 1));
  const itemsToShow = (newItemsToShow.length < 5 && newItemsToShow.length > 0) ?
    items.slice(Math.max(items.length - (maxToShow - newItemsToShow.length))) : [];

  return (
    <List>
      <ListHeader>
        <BackButton onClick={() => toggleMenuType('main')}>
          <Icon icon='mi-arrow_back' size='24' />
        </BackButton>
        <span>Уведомления</span>
      </ListHeader>

      <ItemsContainer>
        {!!newItemsToShow.length && (
          <NewItems>
            <NewHeader>Новые</NewHeader>
            {newItemsToShow.map(el => (
              <NotificationItem
                key={el.id}
                date={moment.utc(el.createdAt, 'YYYY-MM-DD HH:mm:ss').utcOffset(user?.timezone || 0).format('DD.MM.YYYY HH:mm')}
                text={el.data.text}
                data={el.data}
                id={el.id}
                readed={el.readAt}
                handleSetNotification={setUserNotification}
              />
            ))}
          </NewItems>
        )}
        {!!itemsToShow.length && (
          <Items>
            {itemsToShow.map(el => (
              <NotificationItem
                key={el.id}
                date={moment(el.createdAt, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm')}
                text={el.data.text}
                data={el.data}
                id={el.id}
                readed={el.readAt}
                handleSetNotification={setUserNotification}
              />
            ))}
          </Items>
        )}
        {!newItemsToShow.length && (
          <Items>
            <NotificationItem text="Нет новых уведомлений" readed />
          </Items>
        )}
      </ItemsContainer>

      <StyledLink onClick={() => toggleMenuOpened(false)} to="/notifications">Смотреть все</StyledLink>
    </List>
  );
}

export default NotificationsMenu;
