import React from 'react';
import styled from 'styled-components';

export default function ButtonComponent({ children, className = '', buttonStyle = '', ...rest }) {
  const newClassName = `${className} ${buttonStyle}`.trim();
  return (
    <Button className={newClassName} {...rest}>
      {children}
    </Button>
  );
}

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: ${({ theme }) => theme.color.blue};
  color: ${({ theme }) => theme.color.white};
  transition: 0.2s;
  border-radius: 6px;
  &:hover {
    background-color: ${({ theme }) => theme.color.navi};
  }

  &.red {
    background: ${({ theme }) => theme.color.red};
    border-color: ${({ theme }) => theme.color.red};
    &:hover {
      background: ${({ theme }) => theme.color.red};
    }
  }

  &.dotted {
    background: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.blue};
    border: 1px dashed ${({ theme }) => theme.color.blue};
    &:hover {
      color: ${({ theme }) => theme.color.white};
      background: ${({ theme }) => theme.color.blue};
      border-style: solid;
    }

    &.red {
      color: ${({ theme }) => theme.color.red};
      border-color: ${({ theme }) => theme.color.red};
      &:hover {
        color: ${({ theme }) => theme.color.white};
        background: ${({ theme }) => theme.color.red};
      }
    }
  }

  &.inverted {
    background: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.blue};
    border: 1px solid ${({ theme }) => theme.color.blue};
    &:hover {
      color: ${({ theme }) => theme.color.white};
      background: ${({ theme }) => theme.color.blue};
    }

    &.red {
      color: ${({ theme }) => theme.color.red};
      border-color: ${({ theme }) => theme.color.red};
      &:hover {
        color: ${({ theme }) => theme.color.white};
        background: ${({ theme }) => theme.color.red};
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.color.silver};
    border-color: ${({ theme }) => theme.color.silver};
    color: ${({ theme }) => theme.color.white};
    &:hover {
      background-color: ${({ theme }) => theme.color.darkGray};
    }
  }
`;
