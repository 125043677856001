import { account, handbook, protectedHandbook } from 'utils/axiosInstances';
import { camelize } from 'utils/objectUtilities';

const PUBLIC = '/public/all';
const PROTECTED = '/protected/all';

export const fetchHandbook = async () => {
  try {
    const response = await handbook.get(PUBLIC);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const fetchProtectedHandbook = async () => {
  try {
    const response = await protectedHandbook.get(PROTECTED);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const fetchNews = async () => {
  try {
    const response = await account.get('news');
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const maskNewsAsRead = async (id) => {
  try {
    const response = await account.patch(`news/${id}/read`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};
