import { account } from 'utils/axiosInstances';
import { snakeify, camelize } from 'utils/objectUtilities';

const URL = '/positions';

export const createPosition = async newPositionData => {
  try {
    const response = await account.post(URL, snakeify(newPositionData, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};
export const getPositions = async () => {
  try {
    const response = await account.get(URL);

    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};
export const deletePosition = async id => {
  try {
    const response = await account.delete(`${URL}/${id}`);

    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
