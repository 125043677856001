import {
  getCompany,
  editCompany,
  getHeadCompany,
  logCompanyLastSeen,
  getTariffInfo,
} from 'api/company.api';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';

import { effects as modalEffects } from './modal.duck';
import { actions as handbookActions } from './handbook.duck';
import { actions as userActions } from './user.duck';
import moment from 'moment';

export const options = {
  name: 'company',
  initialState: {
    company: {},
    headCompany: {},
    tariffInfo: {},
    tariffInfoNeedToFetch: true,
    loading: false,
    errorMessage: '',
    isTariffUntilWarned: false,
    isStartInfoLoaded: false,
    currency: '₽',
  },
  actions: {
    fetchCompanyRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchCompanySuccess: company => state => ({
      ...state,
      company,
      loading: false,
    }),
    fetchCompanyFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      message,
    }),
    fetchCompanyHeadRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchCompanyHeadSuccess: headCompany => state => ({
      ...state,
      headCompany,
      loading: false,
    }),
    fetchCompanyHeadFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      message,
    }),
    editCompanyRequest: () => state => ({
      ...state,
      loading: true,
    }),
    editCompanySuccess: (data) => state => ({
      ...state,
      headCompany: {
        ...state.headCompany,
        ...data
      },
      loading: false,
    }),
    editCompanyFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      message,
    }),
    logCompanyLastSeenRequest: () => state => ({
      ...state,
      loading: true,
    }),
    logCompanyLastSeenSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    logCompanyLastSeenFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      message,
    }),
    fetchTariffInfoRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchTariffInfoSuccess: (tariffInfo) => state => ({
      ...state,
      loading: false,
      tariffInfo,
      company: {
        ...state.company,
        name: tariffInfo.company || '',
      }
    }),
    fetchTariffInfoFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      message,
    }),
    setIsTariffUntilWarnedTrue: () => state => ({
      ...state,
      loading: false,
      isTariffUntilWarned: true,
    }),
    setIsStartInfoLoaded: (value) => state => ({
      ...state,
      isStartInfoLoaded: value,
    }),
    setTariffInfoNeedToFetch: (value) => state => ({
      ...state,
      tariffInfoNeedToFetch: value,
    }),
    setCurrency: ({ company, currencies, }) => state => ({
      ...state,
      currency: (currencies.find(cur => cur.value === company.cur) || {}).intl || '',
    }),
  },
  effects: {
    fetchCompany: (params = {}) => async (dispatch, getState, duckActions, duckEffects) => {
      const { isTariffUntilWarned } = getState().company;
      const { tariffUntilWarn = false } = params;

      dispatch(duckActions.fetchCompanyRequest());
      try {
        const { data } = await getCompany();
        const { tariffType, tariffUntil } = data;
        dispatch(duckActions.fetchCompanySuccess(data));
        dispatch(handbookActions.setTariffData({ tariffType, tariffUntil }));
        dispatch(userActions.setTariffType(tariffType));
        dispatch(duckEffects.logCompanyLastSeen());

        const { currencies } = getState().handbook.publicHandbook;
        dispatch(duckEffects.setCompanyCurrency({ company: data, currencies, }));

        if (tariffUntilWarn && !isTariffUntilWarned) {
          const tariffHoursRemain = moment(tariffUntil).diff(moment(), 'hours');
          if (tariffHoursRemain <= 96 && tariffHoursRemain >= 0) {
            dispatch(duckActions.setIsTariffUntilWarnedTrue());
            toast.warn(`Внимание! Текущий тариф компании истекает ${moment(tariffUntil).format('DD.MM.YYYY')}`);
          }
        }
      } catch (error) {
        dispatch(duckActions.fetchCompanyFailure(error));
      }
    },
    fetchHeadCompany: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchCompanyHeadRequest());
      try {
        const response = await getHeadCompany();
        dispatch(duckActions.fetchCompanyHeadSuccess(response.data));
      } catch (error) {
        dispatch(duckActions.fetchCompanyHeadFailure(error));
      }
    },
    editCompany: payload => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.editCompanyRequest());
      try {
        const { success } = await editCompany(payload);
        if (success) {
          dispatch(duckActions.editCompanySuccess(payload));

          const { currencies } = getState().handbook.publicHandbook;
          dispatch(duckEffects.setCompanyCurrency({ company: payload, currencies, }));

          toast.success('Изменения сохранены');
          dispatch(modalEffects.closeModal());
        } else {
          throw new Error('Something went wrong');
        }
      } catch (error) {
        dispatch(duckActions.editCompanyFailure(error));
      }
    },
    logCompanyLastSeen: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.logCompanyLastSeenRequest());
      try {
        await logCompanyLastSeen();
        dispatch(duckActions.logCompanyLastSeenSuccess());
      } catch (error) {
        dispatch(duckActions.logCompanyLastSeenFailure(error));
      }
    },
    fetchTariffInfo: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchTariffInfoRequest());
      try {
        const { data } = await getTariffInfo();
        dispatch(duckActions.fetchTariffInfoSuccess(data));
      } catch (error) {
        dispatch(duckActions.fetchTariffInfoFailure(error));
      }
    },
    setCompanyCurrency: ({ company, currencies, }) => async (dispatch, getState, duckActions) => {
      if (company.cur && currencies.length) {
        dispatch(duckActions.setCurrency({ company, currencies }));
      }
    }
  },
  selectors: {
    getCompany: (getState, createSelector) => createSelector([getState], s => s.company),
    getHeadCompany: (getState, createSelector) => createSelector([getState], s => s.headCompany),
    getTariffInfo: (getState, createSelector) => createSelector([getState], s => s.tariffInfo),
    getOrderStatuses: (getState, createSelector) => createSelector([getState], s => (
      [...(s.company.orderStatuses || [])].sort((a, b) => {
        if (a.id === 11 && b.id !== 1) return -1; // сначала "новый заказ", потом "смета сформирована", потом все остальные по порядку
        if (b.id === 11 && a.id !== 1) return 1; // для firefox, сначала "новый заказ", потом "смета сформирована", потом все остальные по порядку
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      })
    )),
    getCompanyCurrency: (getState, createSelector) => createSelector([getState], s => s.currency),
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    getIsStartInfoLoaded: (getState, createSelector) => createSelector([getState], s => s.isStartInfoLoaded),
    getTariffInfoNeedToFetch: (getState, createSelector) => createSelector([getState], s => s.tariffInfoNeedToFetch),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
