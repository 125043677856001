import { account } from 'utils/axiosInstances';
import { snakeify, camelize } from 'utils/objectUtilities';

class UnprocessableEntityError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UnprocessableEntityError';
    this.statusCode = 422;
  }
}

const parseTMCSettingsErrors = error => {
  const { response } = error
  const { status, data = {} } = response;
  const { errors } = data;
  const message = errors ? Object.values(errors)[0][0] : (response.data.message || response.message);

  switch (status) {
    case 422: throw new UnprocessableEntityError(message);
    default: throw new Error(response.message, response.status);
  }
}

const createStandartSetQuery = (serviceId, technologyId, standardGroupId, factorId) => {
  return `?${serviceId ? `service_id=${serviceId}` : ''}` +
    (technologyId ? `&technology_id=${technologyId}` : '') +
    (standardGroupId ? `&standard_group_id=${standardGroupId}` : '') +
    (factorId ? `&factor_id=${factorId}` : '')
}

const URL = '/company';
const equipURL = 'equipments';
const equipEstURL = 'equipment-estimations';
const equipStURL = 'storage-equipments';
const consumURL = 'consumable';
const consumEstURL = 'consumable-estimation';
const detURL = 'detergent';
const detEstURL = 'detergent/estimation'

export const getSettings = async () => {
  try {
    const response = await account.get(`${URL}/settings`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const setSettings = async data => {
  try {
    const response = await account.put(`${URL}/settings`, snakeify(data, true));
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const getEquipment = async () => {
  try {
    const response = await account.get(`${URL}/${equipURL}`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const setEquipment = async data => {
  try {
    const response = await account.post(`${URL}/${equipURL}`, snakeify(data, true));
    return camelize(response.data.data, true);
  } catch (error) {
    const { response } = error;
    if (response.data.errors.name)
      throw new Error(`Имя: ${response.data.errors.name[0].toLowerCase()}`, response.status);
    if (response.data.errors.type)
      throw new Error(`Тип: ${response.data.errors.type[0].toLowerCase()}`, response.status);
  }
}
export const updateEquipment = async (id, data) => {
  try {
    const response = await account.put(`${URL}/${equipURL}/${id}`, snakeify(data, true));
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const deleteEquipment = async id => {
  try {
    const response = await account.delete(`${URL}/${equipURL}/${id}`);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const getEquipmentEstimation = async (standartSet) => {
  try {
    const response = await account.get(`${URL}/${equipEstURL}${standartSet ? '?type=standard' : ''}`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const setEquipmentEstimation = async equipmentObj => {
  try {
    const response = await account.post(`${URL}/${equipEstURL}`, snakeify(equipmentObj, true));
    return camelize(response.data, true);
  } catch (error) {
    parseTMCSettingsErrors(error);
  }
}
export const updateEquipmentEstimation = async (id, equipmentObj) => {
  try {
    const response = await account.put(`${URL}/${equipEstURL}/${id}`, snakeify(equipmentObj, true));
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const deleteEquipmentEstimation = async id => {
  try {
    const response = await account.delete(`${URL}/${equipEstURL}/${id}`);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const getStorageEquipments = async () => {
  try {
    const response = await account.get(`${URL}/${equipStURL}`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const setEquipmentDepreciation = async equipmentObj => {
  try {
    const response = await account.post(`${URL}/${equipStURL}`, snakeify(equipmentObj, true));
    return camelize(response.data.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const updateEquipmentDepreciation = async (id, equipmentObj) => {
  try {
    const response = await account.put(`${URL}/${equipStURL}/${id}`, snakeify(equipmentObj, true));
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const deleteEquipmentDepreciation = async id => {
  try {
    const response = await account.delete(`${URL}/${equipStURL}/${id}`);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const getConsumable = async () => {
  try {
    const response = await account.get(`${URL}/consumables`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const setConsumable = async data => {
  try {
    const response = await account.post(`${URL}/${consumURL}`, snakeify(data, true));
    return camelize(response.data.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const updateConsumable = async (id, data) => {
  try {
    const response = await account.put(`${URL}/${consumURL}/${id}`, snakeify(data, true));
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const deleteConsumable = async id => {
  try {
    const response = await account.delete(`${URL}/${consumURL}/${id}`);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const getConsumableEstimation = async (standartSet) => {
  try {
    const response = await account.get(`${URL}/consumable-estimations${standartSet ? '?type=standard' : ''}`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const setConsumableEstimation = async consumableObj => {
  try {
    const response = await account.post(`${URL}/${consumEstURL}`, snakeify(consumableObj, true));
    return camelize(response.data, true);
  } catch (error) {
    parseTMCSettingsErrors(error);
  }
}
export const updateConsumableEstimation = async (id, consumableObj) => {
  try {
    const response = await account.put(`${URL}/${consumEstURL}/${id}`, snakeify(consumableObj, true));
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const deleteConsumableEstimation = async id => {
  try {
    const response = await account.delete(`${URL}/${consumEstURL}/${id}`);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const getDetergent = async () => {
  try {
    const response = await account.get(`${URL}/detergents`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const setDetergent = async data => {
  try {
    const response = await account.post(`${URL}/${detURL}`, snakeify(data, true));
    return camelize(response.data.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const updateDetergent = async (id, data) => {
  try {
    const response = await account.put(`${URL}/${detURL}/${id}`, snakeify(data, true));
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const deleteDetergent = async id => {
  try {
    const response = await account.delete(`${URL}/${detURL}/${id}`);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const getDetergentEstimation = async (standartSet) => {
  try {
    const response = await account.get(`${URL}/detergent/estimations${standartSet ? '?type=standard' : ''}`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const setDetergentEstimation = async detergentObj => {
  try {
    const response = await account.post(`${URL}/${detEstURL}`, snakeify(detergentObj, true));
    return camelize(response.data, true);
  } catch (error) {
    parseTMCSettingsErrors(error);
  }
}
export const updateDetergentEstimation = async (id, detergentObj) => {
  try {
    const response = await account.put(`${URL}/${detEstURL}/${id}`, snakeify(detergentObj, true));
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
export const deleteDetergentEstimation = async id => {
  try {
    const response = await account.delete(`${URL}/${detEstURL}/${id}`);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const getEquipmentStandartSet = async (...params) => {
  try {
    const query = `${URL}/${equipEstURL}/standard-set` + createStandartSetQuery(...params);
    const response = await account.get(query);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const getConsumableStandartSet = async (...params) => {
  try {
    const query = `${URL}/consumable-estimations/standard-set` + createStandartSetQuery(...params);
    const response = await account.get(query);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}

export const getDetergentStandartSet = async (...params) => {
  try {
    const query = `${URL}/detergent/estimations/standard-set` + createStandartSetQuery(...params);
    const response = await account.get(query);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
}
