import { account } from 'utils/axiosInstances';
import { camelize, snakeify } from 'utils/objectUtilities';

const obj_URL = '/client-objects';
const order_URL = '/orders'

export const fetchFiles = async (id, URL) => {
  try {
    const response = await account.get(`${URL}/${id}/files`);
    return camelize(response.data, true);
  } catch ({ response }) {
    throw new Error(response.message, response.status);
  }
}
export const sendFile = async (id, URL, URLType, payload) => {
  const formData = new FormData();
  formData.append("name", "John");
  formData.append("files[]", payload.content, `${payload.name}.${payload.extension}`);

  try {
    const response = await account.post(`${URL}/${id}/${URLType}/files`, formData)
    return camelize(response.data.data, true);
  } catch ({ response }) {
    throw new Error(response.message, response.status);
  }
}

export const setFileComment = async (categoryId, fileId, URL, comment) => {
  try {
    const response = await account.patch(`${URL}/${categoryId}/file/${fileId}/comment`, snakeify({ comment }, true))
    return camelize(response.data, true);
  } catch ({ response }) {
    throw new Error(response.message, response.status);
  }
}

export const fetchObjectComments = async id => {
  try {
    const response = await account.get(`${obj_URL}/${id}/comments`)
    return camelize(response.data, true);
  } catch ({ response }) {
    throw new Error(response.message, response.status);
  }
}
export const createObjectComment = async (id, comment) => {
  try {
    const response = await account.post(`${obj_URL}/${id}/comment`, { comment });
    return response.data;
  } catch ({ response }) {
    throw new Error(response.message, response.status);
  }
}
export const editObjectComment = async (id, commentId, comment) => {
  try {
    const response = await account.put(`${obj_URL}/${id}/comment/${commentId}`, { comment });
    return response.data;
  } catch ({ response }) {
    throw new Error(response.message, response.status);
  }
}
export const deleteObjectComment = async (id, commentId) => {
  try {
    const response = await account.delete(`${obj_URL}/${id}/comment/${commentId}`);
    return response.data;
  } catch ({ response }) {
    throw new Error(response.message, response.status);
  }
}
export const copyFileToObject = async (id, fileId) => {
  try {
    const response = await account.patch(`${order_URL}/${id}/copy-file/${fileId}/shared`);
    return response.data;
  } catch ({ response }) {
    throw new Error(response.message, response.status);
  }
}
