import {
  editRole,
  fetchRoles,
  allowRoles
} from 'api/role.api';
// Helpers
import history from 'utils/history';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';

export const options = {
  name: 'role',
  initialState: {
    currentRole: {},
    isLoading: false,
    roles: [],
    error: ''
  },
  actions: {
    fetchRolesRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    fetchRolesSuccess: roles => state => ({
      ...state,
      isLoading: false,
      roles,
    }),
    fetchRolesFailure: errorMessage => state => ({
      ...state,
      errorMessage
    }),
    allowRolesRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    allowRolesSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    allowRolesFailure: errorMessage => state => ({
      ...state,
      errorMessage
    }),
    editRoleRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    editRoleSuccess: () => state => ({
      ...state,
      isLoading: false,
      currentRole: {}
    }),
    editRoleFailure: (response) => state => ({
      ...state,
      error: response
    }),
    setCurrentRole: currentRole => state => ({
      ...state,
      currentRole
    })
  },
  effects: {
    fetchRoles: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchRolesRequest());
      try {
        const response = await fetchRoles();
        dispatch(duckActions.fetchRolesSuccess(response));
      } catch ({ data }) {
        dispatch(duckActions.fetchRolesFailure(data));
      }
    },
    editRole: ({ roleId, payload, usingTemplate = true }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.editRoleRequest());
      try {
        await editRole(roleId, payload);
        await dispatch(duckEffects.fetchRoles());
        dispatch(duckActions.editRoleSuccess())
        toast.success('Роль успешно изменена')
        if (usingTemplate) {
          history.push('/system-settings?tab=3')
        }
      } catch ({ data }) {
        dispatch(duckActions.editRoleFailure(data));
        toast.error('Ошибка!');
      }
    },
    allowRoles: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.allowRolesRequest());
      try {
        await allowRoles();
        dispatch(duckActions.allowRolesSuccess())
      } catch ({ data }) {
        dispatch(duckActions.editRoleFailure(data));
        toast.error('Ошибка!');
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.isLoading),
    getCurrentRole: (getState, createSelector) => createSelector([getState], s => s.currentRole),
    getRoles: (getState, createSelector) => createSelector([getState], s => s.roles)
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
