import loadable from '@loadable/component';

const DeleteModal = loadable(() => import('components/modals/DeleteModal'));
const ConfirmModal = loadable(() => import('components/modals/ConfirmModal'));
const DeleteBrigadeModal = loadable(() => import('components/modals/DeleteBrigadeModal'));
const EmployeeModal = loadable(() => import('components/modals/EmployeeModal'));
const FreelancerModal = loadable(() => import('components/modals/FreelancerModal'));
const CreateObjectModal = loadable(() => import('components/modals/CreateObjectModal'));
const CreateByNameModal = loadable(() => import('components/modals/CreateByNameModal'));
const CreateFolderModal = loadable(() => import('components/modals/CreateFolderModal'));
const CreateFileModal = loadable(() => import('components/modals/CreateFileModal'));
const MoveFileModal = loadable(() => import('components/modals/MoveFileModal'));
const OrderScheduleModal = loadable(() => import('components/modals/OrderScheduleModal'));
const WorkTimeModal = loadable(() => import('components/modals/WorkTimeModal'));
const SortModal = loadable(() => import(`components/modals/SortModal`));
const FilterModal = loadable(() => import(`components/modals/FilterModal`));
const SuppliesModal = loadable(() => import(`components/modals/SuppliesModal`));
const ManagerTaskModal = loadable(() => import(`components/modals/ManagerTaskModal`));
const ScheduleWeekendModal = loadable(()=>import('components/modals/ScheduleWeekendModal'));
const CreateContractorsModal = loadable(()=>import('components/modals/CreateContractorsModal'));
const OrderPaymentModal = loadable(()=>import('components/modals/OrderPaymentModal'));
const ImageModal = loadable(() => import('components/modals/ImageModal'));
const PDFModal = loadable(() => import('components/modals/PDFModal'));
const SendEstimateModal = loadable(() => import('components/modals/SendEstimateModal'));
const NomenclatureModal = loadable(() => import('components/modals/NomenclatureModal'));
const TMCUniqModal = loadable(() => import('components/modals/TMCUniqModal'));
const CreateDocumentModal = loadable(() => import('components/modals/CreateDocumentModal'));
const StorageEquipmentModal = loadable(() => import('components/modals/StorageEquipmentModal'));
const EditDocumentModal = loadable(() => import('components/modals/EditDocumentModal'));
const DeleteBranchModal = loadable(() => import('components/modals/DeleteBranchModal'));
const DepreciationModal = loadable(() => import('components/modals/DepreciationModal'));
const TagModal = loadable(() => import('components/modals/TagModal'));

const CustomModal = loadable(() => import('components/modals/CustomModal'));

export {
  DeleteModal,
  ConfirmModal,
  DeleteBrigadeModal,
  EmployeeModal,
  FreelancerModal,
  CreateObjectModal,
  CreateByNameModal,
  CreateFolderModal,
  CreateFileModal,
  MoveFileModal,
  OrderScheduleModal,
  WorkTimeModal,
  SortModal,
  FilterModal,
  SuppliesModal,
  ManagerTaskModal,
  ScheduleWeekendModal,
  CreateContractorsModal,
  OrderPaymentModal,
  ImageModal,
  PDFModal,
  SendEstimateModal,
  NomenclatureModal,
  TMCUniqModal,
  CreateDocumentModal,
  StorageEquipmentModal,
  EditDocumentModal,
  DeleteBranchModal,
  DepreciationModal,
  TagModal,
  CustomModal
};
