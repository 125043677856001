import { account } from 'utils/axiosInstances';

const URL = '/manager-schedule';

export const getSchedule = async ({ user_id, date_from, date_to }) => {
  try {
    const queryString = `${URL}?user_id=${user_id}&date_from=${date_from}&date_to=${date_to}`;
    const response = await account.get(queryString);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.data.message, response.status);
  }
};

export const createTask = async payload => {
  try {
    const response = await account.post(URL, payload);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const editTask = async (id, payload) => {
  try {
    const response = await account.put(`${URL}/${id}`, payload);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const deleteTask = async id => {
  try {
    const response = await account.delete(`${URL}/${id}`);
    return response.data;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};
