import React from 'react';
import { useLocation } from 'react-router-dom';
// Components
import {
  SideMenuBlock,
  SideMenuTitle,
  SideMenuItemIcon,
  SideMenuList,
  SideMenuItem,
  StyledLink,
  DisabledButton
} from '../styles';

export default function SideMenuBlockComponent({
  title,
  icon,
  items,
  toggleOpened,
  opennedTab,
  setOpennedTab,
}) {
  const { pathname } = useLocation();

  const toggleTabOpenned = () => {
    if (opennedTab === title)
      setOpennedTab(null)
    else if (!items.some(el => pathname.includes(el.link)))
      setOpennedTab(title)
  }

  return (
    <SideMenuBlock>
      <SideMenuTitle onClick={() => toggleTabOpenned()}>
        <SideMenuItemIcon icon={icon} />
        {title}
      </SideMenuTitle>
      {(opennedTab === title || items.some(el => pathname.includes(el.link))) &&
        <SideMenuList>
          {items.map((el, index) => (
            <SideMenuItem disabled={el.disabled} key={`${title}${index}`} active={pathname.includes(el.link)}>
              <StyledLink disabled={el.disabled} as={el.disabled && DisabledButton} to={{ pathname: el.link, search: el.search || '', state: el.state || '' }} target={el.target || '_self'} onClick={toggleOpened} activeClassName="selected">
                {el.name}
              </StyledLink>
            </SideMenuItem>
          ))}
        </SideMenuList>
      }
    </SideMenuBlock>
  );
}