import { hot } from 'react-hot-loader/root';
import React from 'react';
import { connect } from 'react-redux';

import {
  effects as handbookEffects,
  selectors as handbookSelectors,
} from 'redux/ducks/handbook.duck';

import Template from './PublicTemplate';

const TemplateContainer = props => <Template {...props} />;

const mapStateToProps = state => ({
  errorMessage: handbookSelectors.getErrorMessage(state),
  isLoading: handbookSelectors.getIsLoading(state),
});

const mapDispatchToProps = {
  fetchHandbook: handbookEffects.fetchHandbook,
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(TemplateContainer));
