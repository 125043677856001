import { account, } from 'utils/axiosInstances';

const URL = '/knowledge-categories';

export const getFile = async (categoryId, fileId, convertToFile = false) => {
  try {
    account.defaults.responseType = 'blob';
    const { data } = await account.get(`${URL}/${categoryId}/file/${fileId}`);

    // for next application/json requests
    account.defaults.responseType = 'json';
    return convertToFile ? new File([data], "name", { type: data.type }) : data;
  } catch (error) {
    const { response } = error;

    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};
