import { account } from 'utils/axiosInstances';
import { snakeify, camelize } from 'utils/objectUtilities';
import { get } from 'lodash';
import { toast } from 'react-toastify'
import { getPaginationParams } from './utils/paginationUtils';

const URL = '/employees';

class UnprocessableEntityError extends Error {
  constructor(params) {
    super(params.message);
    this.name = params.name;
    this.status = 422;
  }
}

class ForbiddenError extends Error {
  constructor(message) {
    super(message);
    this.name = 'Forbidden';
    this.status = 403;
  }
}

const parseEmployeeError = error => {
  if (error.status === 422) {
    const { errors } = error.data;
    const errorsNames = Object.keys(errors);

    throw new UnprocessableEntityError({
      message: errors ? errors[errorsNames[0]][0] : 'Произошла ошибка, попробуйте снова',
      name: errors ? errorsNames[0] : ''
    })
  }
  else if (error.status === 403) {
    throw new ForbiddenError(error.message || 'Недостаточно прав')
  }
  else throw new Error(error.message, error.status)
}

export const createEmployee = async newEmployeeData => {
  try {
    const response = await account.post(URL, snakeify(newEmployeeData, true));
    return camelize(response.data, true);
  } catch ({ response }) {
    parseEmployeeError(response);
    throw response.data;
  }
};

export const getEmployees = async (params = { offset: 1, limit: 0, }) => {
  try {
    const response = await account.get(`${URL}?${getPaginationParams(params.offset, params.limit)}`,);

    return camelize(get(response, 'data'), true);
  } catch ({ response }) {
    throw new Error(response.message, response.status)
  }
};

export const editEmployee = async (id, companyData) => {
  try {
    const response = await account.put(`${URL}/${id}`, snakeify(companyData, true));

    return camelize(response.data, true);
  } catch ({ response }) {
    parseEmployeeError(response);
    throw response.data;
  }
};

export const getEmployeeCompanies = async () => {
  try {
    const response = await account.get(`/companies`)
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error
    throw new Error(response.message, response.status)
  }
};

export const getOneEmployee = async id => {
  try {
    const response = await account.get(`${URL}/${id}`);

    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.data.message, response.status);
  }
};

export const deleteEmployee = async id => {
  try {
    const response = await account.delete(`${URL}/${id}`);

    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    if (get(response, 'data.message', '')) {
      toast.error(response.data.message)
    }
    throw new Error(response.message, response.status);
  }
};
