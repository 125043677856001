import { auth } from 'utils/axiosInstances';
import { snakeify, camelize } from 'utils/objectUtilities';

export const login = async data => {
  try {
    const response = await auth.post('/login', snakeify(data, false));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;

    if (response.status === 401) {
      throw new Error('Введен неверный логин или пароль', response.status);
    }
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};

export const register = async data => {
  try {
    const response = await auth.post('/register', snakeify(data, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;

    if (response.status === 422) {
      const { errors } = response.data;

      throw errors;
    }
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};

export const resetPassword = async data => {
  try {
    const response = await auth.post('/password/reset', snakeify(data, false));
    return camelize(response.data, true);
  } catch ({ response }) {
    if (response.status === 422) {
      const { errors } = response.data;
      const error = { message: errors };
      throw error;
    }
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};

export const forgotPassword = async data => {
  try {
    const response = await auth.post('/password/forgot', snakeify(data, false));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    if (response.status === 401) throw new Error('Email не существует', response.status);
    throw new Error(response.data.error, response.status);
  }
};

export const registerCheck = async data => {
  try {
    const response = await auth.post('/register/check', snakeify(data, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;

    if (response.status === 422) {
      const { errors } = response.data;

      throw errors;
    }
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};
