import {
  fetchOrders,
  fetchOneOrder,
  fetchOneOrderByFakeId,
  fetchOneOrderByUUID,
  createOrder,
  updateOrder,
  deleteOrder,
  setOrderStatus,
  fetchTotalOrderResult,
  fetchComposedInfo,
  updateComposedInfo,
  repeatOrder,
  dataApplied,
  fetchOrderPayment,
  editOrderPayment,
  sendEmailOrderPayment,
  sendNotificationPaymentControl,
  orderPrepaymentRefund,
  orderPaymentRefund,
  orderPaymentCheckout,
  setNotify,
  updateOrderSetting,
  updateOrderDescription,
  getEstimate,
  sendEstimate,
} from 'api/orders.api';
import Omit from "lodash/omit"
import history from 'utils/history';
import { effects as clientsEffects } from 'redux/ducks/clients.duck';
import { toast } from 'react-toastify';
import { effects as modalEffects } from './modal.duck';
import { createDuck } from './utils/createDuck';
import { paginationResponseParse } from './utils/paginationParse';
import dcToast from 'utils/toast';
import { selectors as userSelectors } from 'redux/ducks/user.duck';

const errorProcessing = (error, message = '', history, order = {},) => {
  if (error.name === 'TypeError') {
    toast.warn('Техническая ошибка, попробуйте позже');
  } else {
    let errorMessage = message || error.message;

    if (errorMessage === 'У клиента не заполнены банковские реквизиты') {
      dcToast({
        text: errorMessage,
        icon: '',
        action: () => order?.clientId && history.push(`/clients/${order.clientId}`),
        actionText: order?.clientId && 'К клиенту',
        options: { type: 'warning' }
      });
    } else {
      if (!errorMessage) {
        switch (error.name) {
          case 'PaymentForbiddenError': errorMessage = 'Недостаточно прав для данного действия'; break;
          case 'PaymentServerError': errorMessage = 'Произошла серверная ошибка, попробуйте позже'; break;
          case 'UnprocessableEntityError': errorMessage = 'Произошла ошибка из-за входных данных'; break;
          default: errorMessage = 'Произошла какая-то ошибка, попробуйте позже'
        }
      }

      if (error.type === 'warn') {
        toast.warn(errorMessage);
      } else toast.error(errorMessage);
    }
  }
};

export const options = {
  name: 'orders',
  initialState: {
    orders: [],
    ordersCount: 0,
    order: {},
    totalOrderResult: {},
    orderPayment: {},
    composedInfo: {},
    errorMessage: '',
    orderPaymentCheckout: {},
    loading: false,
    statusCanBeChanged: false,
    filter: {
      client: null,
      object: null,
      employee: null,
      status: null,
    },
    sorting: {
      sortBy: '',
      sortDirection: false,
    },
    pagination: {
      offset: 1,
      limit: 10,
    },
  },
  actions: {
    fetchOrdersRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchOrdersSuccess: data => state => ({
      ...state,
      loading: false,
      orders: data,
    }),
    fetchOrdersFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    fetchOneOrderRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchOneOrderSuccess: data => state => ({
      ...state,
      loading: false,
      order: data,
    }),
    fetchOneOrderFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    createOrdersRequest: () => state => ({
      ...state,
      loading: true,
    }),
    createOrdersSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    createOrdersFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    updateOrdersRequest: () => state => ({
      ...state,
      loading: true,
    }),
    updateOrdersSuccess: () => state => ({
      ...state,
      loading: false,
      statusCanBeChanged: true,
    }),
    updateOrdersFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    deleteOrderRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteOrderSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    deleteOrderFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    deleteManyOrdersRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteManyOrdersSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    deleteManyOrdersFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    setOrderStatusRequest: () => state => ({
      ...state,
      loading: true,
    }),
    setOrderStatusSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    setOrderStatusFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    fetchComposedInfoRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchComposedInfoSuccess: composedInfo => state => ({
      ...state,
      loading: false,
      statusCanBeChanged: false,
      composedInfo,
    }),
    fetchComposedInfoFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    updateComposedInfoRequest: () => state => ({
      ...state,
      loading: true,
    }),
    updateComposedInfoSuccess: (data) => state => ({
      ...state,
      loading: false,
      order: {
        ...state.order,
        estimate: {
          ...state.order?.estimate,
          ...data,
        }
      },
      composedInfo: {
        ...state.composedInfo,
        ...data,
      }
    }),
    orderPaymentRequest: () => state => ({
      ...state,
      loading: true,
    }),
    orderPaymentFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    orderPaymentSuccess: orderPayment => state => ({
      ...state,
      orderPayment,
      loading: false,
    }),
    updateComposedInfoFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    fetchTotalOrderResultRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchTotalOrderResultSuccess: data => state => ({
      ...state,
      loading: false,
      totalOrderResult: data,
    }),
    fetchTotalOrderResultFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    repeatOrderRequest: () => state => ({
      ...state,
      loading: true,
    }),
    repeatOrderSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    repeatOrderFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    dataAppliedRequest: () => state => ({
      ...state,
      loading: true,
    }),
    dataAppliedSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    dataAppliedFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    sendEmailOrderPaymentRequest: () => state => ({
      ...state,
      loading: true,
    }),
    sendEmailOrderPaymentSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    sendEmailOrderPaymentFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    orderRefundPaymentRequest: () => state => ({
      ...state,
      loading: true,
    }),
    orderRefundPaymentSuccess: () => state => ({
      ...state,
      loading: false,
      // orderPayment: {
      //   ...state.orderPayment,
      //   isRefunded
      // }
    }),
    orderRefundPaymentFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    orderRefundPrepaymentRequest: () => state => ({
      ...state,
      loading: true,
    }),
    orderRefundPrepaymentSuccess: () => state => ({
      ...state,
      loading: false,
      // orderPayment: {
      //   ...state.orderPayment,
      //   isPrepaymentRefunded
      // }
    }),
    orderRefundPrepaymentFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    orderPaymentCheckoutRequest: () => state => ({
      ...state,
      orderPaymentCheckout: {},
      loading: true,
    }),
    orderPaymentCheckoutSuccess: orderPaymentCheckout => state => ({
      ...state,
      orderPaymentCheckout,
      loading: false,
    }),
    orderPaymentCheckoutFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    sendNotificationPaymentControlRequest: () => state => ({
      ...state,
      loading: true,
    }),
    sendNotificationPaymentControlSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    sendNotificationPaymentControlFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    setNotifyRequest: () => state => ({
      ...state,
      loading: true,
    }),
    setNotifySuccess: () => state => ({
      ...state,
      loading: false,
    }),
    setNotifyFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    updateOrderDescriptionRequest: () => state => ({
      ...state,
      loading: true,
    }),
    updateOrderDescriptionSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    updateOrderDescriptionFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    getEstimatePdfRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getEstimatePdfSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    getEstimatePdfFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    sendEstimatePdfRequest: () => state => ({
      ...state,
      loading: true,
    }),
    sendEstimatePdfSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    sendEstimatePdfFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    updateOrderSettingRequest: () => state => ({
      ...state,
      loading: true,
    }),
    updateOrderSettingSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    updateOrderSettingFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    changeOrderClientEmail: email => state => ({
      ...state,
      order: {
        ...state.order,
        client: {
          ...state.order.client,
          email
        }
      }
    }),
    updateOrderTMCSetting: () => state => ({
      ...state,
      order: {
        ...state.order,
        tmcSetting: Number(!state.order.tmcSetting)
      }
    }),

    setFilter: filter => state => ({ ...state, filter }),
    setSorting: sorting => state => ({ ...state, sorting }),
    setPagination: pagination => state => ({ ...state, pagination }),
  },
  effects: {
    fetchOrders: params => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchOrdersRequest());
      try {
        const response = await fetchOrders(params);
        dispatch(duckActions.fetchOrdersSuccess(response));

        paginationResponseParse({ response, pagination: params, dispatch, duckActions, });
      } catch (error) {
        dispatch(duckActions.fetchOrdersFailure(error));
      }
    },
    fetchOneOrder: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchOneOrderRequest());
      try {
        const response = await fetchOneOrder(id);
        dispatch(duckActions.fetchOneOrderSuccess(response));
        return response;
      } catch (error) {
        dispatch(duckActions.fetchOneOrderFailure(error));
        if (error.message === '404') history.push('/404')
        return null;
      }
    },
    fetchOneOrderByFakeId: fakeId => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchOneOrderRequest());
      try {
        const response = await fetchOneOrderByFakeId(fakeId);
        dispatch(duckActions.fetchOneOrderSuccess(response));
        return response;
      } catch (error) {
        dispatch(duckActions.fetchOneOrderFailure(error));
        if (error.message === '404') history.push('/404')
        return null;
      }
    },
    fetchOneOrderByUUID: UUID => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchOneOrderRequest());
      try {
        const response = await fetchOneOrderByUUID(UUID);
        dispatch(duckActions.fetchOneOrderSuccess(response));
        return response;
      } catch (error) {
        dispatch(duckActions.fetchOneOrderFailure(error));
        if (error.message === '404') history.push('/404')
        return null;
      }
    },
    createOrder: ({ params, ...data }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.createOrdersRequest());
      const state = getState();
      try {
        let { clientId } = data;
        if (clientId && !Number.isInteger(clientId)) {
          const clientResponse = await dispatch(clientsEffects.createClient({ name: clientId }));
          if (clientResponse) clientId = clientResponse.id;
        }
        const { data: order } = await createOrder({ ...data, clientId });
        dispatch(duckActions.createOrdersSuccess());
        dispatch(duckActions.fetchOneOrderSuccess(order));
        toast.success('Заказ создан!');
        if (params && params.state && params.pathname) {
          if (order) {
            history.push({ ...params, state: { ...params.state, order } });
          }
        } else {
          if (userSelectors.getOrderUpdateBeforeSignedFullPerm(state) || userSelectors.getOrderUpdateBeforeSignedPartPerm(state)) {
            history.push({ pathname: `/orders/${order.uuid}`, search: '?step=1' });
            history.push({ pathname: `/orders/${order.uuid}`, search: '?step=2' });
          } else {
            history.push('/orders');
            toast.warn('Для продолжения создания заказа необходимо иметь разрешение на редактирование заказа');
          }
        }
      } catch (error) {
        dispatch(duckActions.createOrdersFailure(error));
        toast.error('Ошибка!');
      }
    },

    updateOrder: ({
      shouldSelectClimbers,
      shouldSelectPlatform,
      params,
      orderResult: { id, ...data },
    }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.updateOrdersRequest());
      try {
        const { clientId, nextStep = 1 } = data;

        const handleUpdateOrder = async () => {
          let payloadClientId = clientId;

          if (clientId && !Number.isInteger(clientId)) {
            const clientResponse = await dispatch(clientsEffects.createClient({ name: clientId }));
            if (clientResponse) payloadClientId = clientResponse.id;
          }

          let orderResult = Omit(data, ["nextStep"])

          const response = await updateOrder(id, { ...orderResult, payloadClientId });
          const order = await dispatch(duckEffects.fetchOneOrder(id));

          if (params && params.state && params.pathname) {
            if (order) {
              history.push({
                ...params,
                pathname: `/orders/${order.id}`,
                search: `?step=${nextStep}`,
                state: { ...params, order }
              });
            }
          } else {
            history.push({
              pathname: `/orders/${order.uuid}`,
              search: `?step=${nextStep}`,
            });
          }

          dispatch(duckActions.updateOrdersSuccess(response));
          toast.success('Заказ обновлен!');
          dispatch(modalEffects.closeModal());
        };

        const { contractors } = data;
        if (shouldSelectClimbers && shouldSelectPlatform) {
          const hasClimbersContractors = contractors.some(
            ({ contractorTypeId }) => contractorTypeId === 1
          );
          const hasPlatformContractors = contractors.some(
            ({ contractorTypeId }) => contractorTypeId === 2
          );
          if (hasClimbersContractors && hasPlatformContractors) {
            await handleUpdateOrder();
          } else if (hasClimbersContractors && !hasPlatformContractors) {
            dispatch(duckActions.updateOrdersFailure('Добавьте автовышку и укажите сумму'));
            toast.error('Добавьте автовышку и укажите сумму!');
          } else if (!hasClimbersContractors && hasPlatformContractors) {
            dispatch(duckActions.updateOrdersFailure('Добавьте альпинистов и укажите сумму'));
            toast.error('Добавьте альпинистов и укажите сумму!');
          } else {
            dispatch(
              duckActions.updateOrdersFailure('Добавьте альпинистов, автовышку и укажите сумму')
            );
            toast.error('Добавьте автовышку и укажите сумму!');
            toast.error('Добавьте альпинистов и укажите сумму!');
          }
        } else if (shouldSelectClimbers && !shouldSelectPlatform) {
          const hasClimbersContractors = contractors.some(
            ({ contractorTypeId }) => contractorTypeId === 1
          );
          if (hasClimbersContractors) {
            await handleUpdateOrder();
          } else {
            dispatch(duckActions.updateOrdersFailure('Добавьте альпинистов и укажите сумму'));
            toast.error('Добавьте альпинистов и укажите сумму!');
          }
        } else if (!shouldSelectClimbers && shouldSelectPlatform) {
          const hasPlatformContractors = contractors.some(
            ({ contractorTypeId }) => contractorTypeId === 2
          );
          if (hasPlatformContractors) {
            await handleUpdateOrder();
          } else {
            dispatch(duckActions.updateOrdersFailure('Добавьте автовышку и укажите сумму'));
            toast.error('Добавьте автовышку и укажите сумму!');
          }
        } else {
          await handleUpdateOrder();
        }
      } catch (error) {
        dispatch(duckActions.updateOrdersFailure(error));
        toast.error('Ошибка!');
      }
    },
    deleteOrder: ({ id, fetchParams }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteOrderRequest());
      try {
        await deleteOrder(id);
        dispatch(duckActions.deleteOrderSuccess());
        await dispatch(duckEffects.fetchOrders(fetchParams));
        dispatch(modalEffects.closeModal());
        toast.success('Заказ удален!');
      } catch (error) {
        dispatch(duckActions.deleteOrderFailure(error));
      }
    },
    deleteManyOrders: ({ ids, fetchParams }) => async (
      dispatch,
      getState,
      duckActions,
      duckEffects
    ) => {
      dispatch(duckActions.deleteManyOrdersRequest());
      try {
        const promises = ids.map(id => deleteOrder(id));
        await Promise.all(promises);
        dispatch(duckActions.deleteManyOrdersSuccess());
        await dispatch(duckEffects.fetchOrders(fetchParams));
        dispatch(modalEffects.closeModal());
        toast.success('Заказы удалены!');
      } catch (error) {
        dispatch(duckActions.deleteManyOrdersFailure(error));
        toast.error('Ошибка!');
      }
    },
    setOrderStatus: ({ order, fetchParams, updateOrder = true, ...data }) => async (
      dispatch,
      getState,
      duckActions,
      duckEffects
    ) => {
      const { handbook, } = getState();
      const { id, uuid } = order;

      dispatch(duckActions.setOrderStatusRequest());
      try {
        const response = await setOrderStatus(id, data);
        dispatch(duckActions.setOrderStatusSuccess(response));
        if (fetchParams) {
          await dispatch(duckEffects.fetchOrders(fetchParams));
        }
        if (updateOrder) await dispatch(duckEffects.fetchOneOrder(id));
      } catch (error) {
        switch (error.message) {
          case 'В заказ-наряде есть невозвращённые на склад позиции.': dispatch(modalEffects.openModal({
            type: 'confirmModal',
            content: {
              title: '',
              text: "У вас в Заказ-наряде не полностью заполнены данные по возврату остатков на склад. Хотите заполнить или списать все на заказ?",
              isStatusEdit: true,
              confirmButtonText: 'Перейти в заказ-наряд',
              closeButtonText: 'Списать на заказ',
            },
            actions: {
              onSubmit: () => {
                history.push(`/orders/${uuid}/card?step=5`);
                dispatch(modalEffects.closeModal());
              },
              onCustom: () => {
                dispatch(duckEffects.setOrderStatus({ order, fetchParams, updateOrder, ...{ ...data, ignoreReturned: true, } }));
                dispatch(modalEffects.closeModal());
              }
            },
          })); break;
          case 'Заказ не оплачен.': {
            const currentStatus = handbook.publicHandbook.orderStatuses.find(status => status.id === data.statusId) || {};

            dispatch(modalEffects.openModal({
              type: 'confirmModal',
              content: {
                title: '',
                text: `Данный заказ не оплачен, всё равно желаете установить статус "${currentStatus.name || ''}"?`,
                isStatusEdit: true,
                confirmButtonText: 'Всё равно установить',
                closeButtonText: 'Перейти к оплате',
              },
              actions: {
                onSubmit: () => {
                  dispatch(duckEffects.setOrderStatus({ order, fetchParams, updateOrder, ...{ ...data, ignorePayment: true, } }))
                  dispatch(modalEffects.closeModal());
                },
                onCustom: () => {
                  history.push(`/orders/${uuid}/card?step=6`);
                  dispatch(modalEffects.closeModal());
                },
              },
            })); break;
          }
          default: errorProcessing(error, '', history, order,);
        }
        dispatch(duckActions.setOrderStatusFailure(error));
      }
    },
    fetchComposedInfo: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchComposedInfoRequest());
      try {
        const response = await fetchComposedInfo(id);
        dispatch(duckActions.fetchComposedInfoSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchComposedInfoFailure(error));
      }
    },
    updateComposedInfo: ({ id, data, shouldToast = true, }) => async (dispatch, getState, duckActions,) => {
      dispatch(duckActions.updateComposedInfoRequest());
      try {
        const { data: response } = await updateComposedInfo(id, data);
        dispatch(duckActions.updateComposedInfoSuccess(response));
        if (shouldToast) toast.success('Смета обновлена');
      } catch (error) {
        dispatch(duckActions.updateComposedInfoFailure(error));
      }
    },
    fetchTotalOrderResult: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchTotalOrderResultRequest());
      try {
        const response = await fetchTotalOrderResult(id);
        dispatch(duckActions.fetchTotalOrderResultSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchTotalOrderResultFailure(error));
      }
    },
    repeatOrder: id => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.repeatOrderRequest());
      try {
        const response = await repeatOrder(id);
        dispatch(duckActions.repeatOrderSuccess(response));
        toast.success('Заказ скопирован!');
        dispatch(duckActions.setPagination({
          offset: 1,
          limit: 10,
        }))
        await dispatch(duckEffects.fetchOrders({
          offset: 1,
          limit: 10,
        }))
        history.push({ pathname: `/orders/${response.data.uuid}`, search: '?step=1' });
        window.scrollTo(0, 0)
      } catch (error) {
        dispatch(duckActions.repeatOrderFailure(error));
      }
    },
    dataApplied: orderId => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.dataAppliedRequest());
      try {
        await dataApplied(orderId);
        dispatch(duckActions.dataAppliedSuccess());
        toast.success('Внесение данных завершено');
        await dispatch(duckEffects.fetchOneOrder(orderId));
      } catch (error) {
        dispatch(duckActions.dataAppliedFailure(error));
      }
    },
    fetchOrderPayment: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.orderPaymentRequest());
      try {
        const response = await fetchOrderPayment(id);
        dispatch(duckActions.orderPaymentSuccess(response));
        return response;
      } catch (error) {
        errorProcessing(error)
        dispatch(duckActions.orderPaymentFailure(error));
      }
    },
    editOrderPayment: ({ id, data, isIndividual, sendPayment = true }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.orderPaymentRequest());
      try {
        await editOrderPayment(id, data);
        if (isIndividual && sendPayment) await dispatch(duckEffects.sendEmailOrderPayment(id))
        toast.success('Изменения сохранены')
        dispatch(duckActions.orderPaymentSuccess(data));
      } catch (error) {
        errorProcessing(error)
        dispatch(duckActions.orderPaymentFailure(error));
      }
    },
    sendEmailOrderPayment: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.sendEmailOrderPaymentRequest());
      try {
        await sendEmailOrderPayment(id);
        dispatch(duckActions.sendEmailOrderPaymentSuccess());
        dispatch(modalEffects.closeModal());
        toast.success('Ссылка на оплату отправлена на почту клиента')
      } catch (error) {
        errorProcessing(error)
        dispatch(duckActions.sendEmailOrderPaymentFailure(error));
      }
    },
    sendNotificationPaymentControl: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.sendNotificationPaymentControlRequest());
      try {
        await sendNotificationPaymentControl(id);
        dispatch(duckActions.sendNotificationPaymentControlSuccess());
        toast.success('Уведомление ответственному отправлено')
      } catch (error) {
        errorProcessing(error)
        dispatch(duckActions.sendNotificationPaymentControlFailure(error));
      }
    },
    orderPrepaymentRefund: ({ id, isRefunded }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.orderRefundPrepaymentRequest());
      try {
        await orderPrepaymentRefund(id, isRefunded);
        dispatch(duckActions.orderRefundPrepaymentSuccess());
        // toast.success('Статус предоплаты изменен')
      } catch (error) {
        errorProcessing(error)
        dispatch(duckActions.orderRefundPrepaymentFailure(error));
      }
    },
    orderPaymentRefund: ({ id, isRefunded }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.orderRefundPaymentRequest());
      try {
        await orderPaymentRefund(id, isRefunded);
        dispatch(duckActions.orderRefundPaymentSuccess());
        // toast.success('Статус оплаты изменен')
      } catch (error) {
        errorProcessing(error)
        dispatch(duckActions.orderRefundPaymentFailure(error));
      }
    },
    fetchOrderPaymentCheckout: data => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.orderPaymentCheckoutRequest());
      try {
        const { data: responseData } = await orderPaymentCheckout(data.id);

        dispatch(duckActions.orderPaymentCheckoutSuccess(responseData));

        return responseData;
      } catch (error) {
        errorProcessing(error)
        dispatch(duckActions.orderPaymentCheckoutFailure(error));
      }
    },
    setNotify: ({ orderId, data }) => async (dispatch, getState, duckActions) => {
      const { order } = getState().orders;
      dispatch(duckActions.setNotifyRequest());
      try {
        await setNotify(orderId, data);
        dispatch(duckActions.setNotifySuccess());

        // instead of server request
        dispatch(duckActions.fetchOneOrderSuccess({
          ...order,
          remindAboutOrder: data.remindAboutOrder
        }));
      } catch (error) {
        dispatch(duckActions.setNotifyFailure(error));
      }
    },
    updateOrderDescription: ({ orderId, data }) => async (dispatch, getState, duckActions) => {
      const { order } = getState().orders;
      dispatch(duckActions.updateOrderDescriptionRequest());
      try {
        const { success } = await updateOrderDescription(orderId, data);
        dispatch(duckActions.updateOrderDescriptionSuccess());
        if (success) {
          toast.success('Информация о заказе обновлена');
          dispatch(duckActions.fetchOneOrderSuccess({
            ...order,
            description: data.description
          }));
        }
      } catch (error) {
        dispatch(duckActions.updateOrderDescriptionFailure(error));
      }
    },
    getEstimatePdf: (id) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getEstimatePdfRequest());
      try {
        const response = await getEstimate(id);
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Смета');
        document.body.appendChild(link);
        link.click();

        dispatch(duckActions.getEstimatePdfSuccess());
      } catch (error) {
        dispatch(duckActions.getEstimatePdfFailure(error));
      }
    },
    sendEstimatePdf: ({ id, email, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.sendEstimatePdfRequest());
      try {
        await sendEstimate(id, email);
        dispatch(modalEffects.closeModal());
        toast.success('Смета отправлена на вашу почту');
        dispatch(duckActions.sendEstimatePdfSuccess());
      } catch (error) {
        errorProcessing(error)
        dispatch(duckActions.sendEstimatePdfFailure(error));
      }
    },
    updateOrderSetting: (id) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.updateOrderSettingRequest());
      try {
        const response = await updateOrderSetting(id);

        if (response.success) {
          dispatch(modalEffects.closeModal());
          toast.success('Настройка ТМЦ применена к заказу');
          dispatch(duckActions.updateOrderTMCSetting());
        } else {
          toast.warn('Настройка ТМЦ не применена к заказу');
        }

        dispatch(duckActions.updateOrderSettingSuccess());
        return response
      } catch (error) {
        errorProcessing(error)
        dispatch(duckActions.updateOrderSettingFailure(error));
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
    getOrders: (getState, createSelector) => createSelector([getState], s => (s.orders && s.orders.items) || []),
    getOrdersCount: (getState, createSelector) => createSelector([getState], s => (s.orders && s.orders.count) || 0),
    getOneOrder: (getState, createSelector) => createSelector([getState], s => s.order),
    getTotalOrderResult: (getState, createSelector) => createSelector([getState], s => s.totalOrderResult),
    getComposedInfo: (getState, createSelector) => createSelector([getState], s => s.composedInfo),
    getStatusCanBeChanged: (getState, createSelector) => createSelector([getState], s => s.statusCanBeChanged),
    getFilter: (getState, createSelector) => createSelector([getState], s => s.filter),
    getSorting: (getState, createSelector) => createSelector([getState], s => s.sorting),
    getPagination: (getState, createSelector) => createSelector([getState], s => s.pagination),
    getOrderPayment: (getState, createSelector) => createSelector([getState], s => s.orderPayment),
    getOrderPaymentCheckout: (getState, createSelector) => createSelector([getState], s => s.orderPaymentCheckout),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
