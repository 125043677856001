import {
  fetchClientDocuments,
  createClientDocument,
  fetchClientArchiveDocuments,
  archiveClientDocument,
  deleteClientDocument,
  restoreClientDocument,
  sendClientDocument,
  changeClientDocument,
  getClientDocument,
  getClientLegalEntityDocument,
  getDocumentTemplate,
} from 'api/clientDocuments.api';

import { effects as modalEffects } from './modal.duck';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';
import dcToast from 'utils/toast';
import history from 'utils/history';

const errorParse = (error, history, closeModal, clientId,) => {
  const { message, status, } = error;

  if (status === 422) {
    const { type, id, } = error;

    dcToast({
      text: message,
      icon: '',
      action: () => {
        closeModal();
        const onSubmitLocation = {
          pathname: `/clients/client-info/${clientId}`,
          search: '?tab=4',
        };

        if (type === 'client') history.push({
          pathname: `/clients/${id}`,
          state: { onSubmitLocation, },
        });
        else if (type === 'legal_entity') history.push({
          pathname: `/company/legal-entities/${id}`,
          state: { onSubmitLocation, },
        });
      },
      actionText: type === 'client' ? 'К клиенту' : 'К юр. лицу',
      options: { type: 'warning', }
    });
  };
  if (status === 500) toast.error(message);
}

export const options = {
  name: 'clientDocuments',
  initialState: {
    errorMessage: '',
    loading: false,
    clientDocuments: [],
    clientArchiveDocuments: []
  },
  actions: {
    fetchClientDocumentsRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchClientDocumentsSuccess: ({ data }) => state => ({
      ...state,
      loading: false,
      clientDocuments: data
    }),
    fetchClientDocumentsFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    createClientDocumentRequest: () => state => ({
      ...state,
      loading: true
    }),
    createClientDocumentSuccess: () => state => ({
      ...state,
      loading: false
    }),
    createClientDocumentFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message
    }),
    fetchClientArchiveDocumentsRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchClientArchiveDocumentsSuccess: ({ data }) => state => ({
      ...state,
      loading: false,
      clientArchiveDocuments: data
    }),
    fetchClientArchiveDocumentsFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    archiveClientDocumentRequest: () => state => ({
      ...state,
      loading: true,
    }),
    archiveClientDocumentSuccess: () => state => ({
      ...state,
      loading: false
    }),
    archiveClientDocumentFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    handleDocumentDelete: ({ id, documentsType }) => state => ({
      ...state,
      [documentsType]: state[documentsType].reduce((prev, item) => [
        ...prev,
        {
          ...item,
          documents: item.documents.reduce((docPrev, docItem) => [
            ...docPrev,
            {
              ...docItem,
              files: docItem.files.filter(file => file.id !== id)
            }
          ], [])
        }
      ], [])
    }),
    deleteClientDocumentRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteClientDocumentSuccess: () => state => ({
      ...state,
      loading: false
    }),
    deleteClientDocumentFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    restoreClientDocumentRequest: () => state => ({
      ...state,
      loading: true,
    }),
    restoreClientDocumentSuccess: () => state => ({
      ...state,
      loading: false
    }),
    restoreClientDocumentFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    sendClientDocumentRequest: () => state => ({
      ...state,
      loading: true,
    }),
    sendClientDocumentSuccess: () => state => ({
      ...state,
      loading: false
    }),
    sendClientDocumentFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    changeClientDocumentRequest: () => state => ({
      ...state,
      loading: true,
    }),
    changeClientDocumentSuccess: () => state => ({
      ...state,
      loading: false
    }),
    changeClientDocumentFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    fetchClientDocumentPdfRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchClientDocumentPdfSuccess: () => state => ({
      ...state,
      loading: false
    }),
    fetchClientDocumentPdfFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    getClientLegalEntityDocumentRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getClientLegalEntityDocumentSuccess: () => state => ({
      ...state,
      loading: false
    }),
    getClientLegalEntityDocumentFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
    getDocumentTemplateRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getDocumentTemplateSuccess: () => state => ({
      ...state,
      loading: false
    }),
    getDocumentTemplateFailure: ({ message }) => state => ({
      ...state,
      loading: false,
      errorMessage: message,
    }),
  },
  effects: {
    fetchClientDocuments: clientId => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchClientDocumentsRequest());
      try {
        const response = await fetchClientDocuments(clientId);
        dispatch(duckActions.fetchClientDocumentsSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchClientDocumentsFailure(error));
      }
    },
    createClientDocument: ({ legalEntityId, clientId, }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.createClientDocumentRequest());
      try {
        await createClientDocument(legalEntityId, clientId);
        dispatch(duckActions.createClientDocumentSuccess());
        dispatch(duckEffects.fetchClientDocuments(clientId))
        dispatch(modalEffects.closeModal());
        toast.success('Договор создан');
      } catch (error) {
        errorParse(error, history, () => dispatch(modalEffects.closeModal()), clientId,);
        dispatch(duckActions.createClientDocumentFailure(error));
      }
    },
    fetchClientArchiveDocuments: clientId => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchClientArchiveDocumentsRequest());
      try {
        const response = await fetchClientArchiveDocuments(clientId);
        dispatch(duckActions.fetchClientArchiveDocumentsSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchClientArchiveDocumentsFailure(error));
      }
    },
    archiveClientDocument: ({ ids, clientId }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.archiveClientDocumentRequest());
      try {
        const { success } = await archiveClientDocument(ids);
        dispatch(duckActions.archiveClientDocumentSuccess());
        if (success) {
          dispatch(duckActions.handleDocumentDelete({ id: ids[0], documentsType: 'clientDocuments' }))
          dispatch(duckEffects.fetchClientArchiveDocuments(clientId));
          toast.success('Договор перенесен в архив');
        }
        else {
          toast.warn('Договор не был перенесен в архив');
        }
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.archiveClientDocumentFailure(error));
      }
    },
    deleteClientDocument: fileId => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deleteClientDocumentRequest());
      try {
        const { success } = await deleteClientDocument(fileId);
        dispatch(duckActions.deleteClientDocumentSuccess());
        if (success) {
          dispatch(duckActions.handleDocumentDelete({ id: fileId, documentsType: 'clientArchiveDocuments' }))
          dispatch(modalEffects.closeModal());
          toast.success('Договор удален');
        }
      } catch (error) {
        dispatch(duckActions.deleteClientDocumentFailure(error));
      }
    },
    restoreClientDocument: ({ clientId, fileId }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.restoreClientDocumentRequest());
      try {
        const { success } = await restoreClientDocument(fileId);
        dispatch(duckActions.restoreClientDocumentSuccess());
        if (success) {
          dispatch(duckActions.handleDocumentDelete({ id: fileId, documentsType: 'clientArchiveDocuments' }));
          dispatch(duckEffects.fetchClientDocuments(clientId));
          toast.success('Договор перенесен в действующие');
        }
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.restoreClientDocumentFailure(error));
      }
    },
    sendClientDocument: ({ fileId, email }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.sendClientDocumentRequest());
      try {
        await sendClientDocument(fileId, email);
        dispatch(duckActions.sendClientDocumentSuccess());
        dispatch(modalEffects.closeModal());
        toast.success('Договор отправлен на указанную почту');
      } catch (error) {
        dispatch(duckActions.sendClientDocumentFailure(error));
      }
    },
    changeClientDocument: ({ fileId, data, clientId }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.changeClientDocumentRequest());
      try {
        const success = await changeClientDocument(fileId, data);
        dispatch(duckActions.changeClientDocumentSuccess());
        if (success) {
          clientId && dispatch(duckEffects.fetchClientDocuments(clientId))
          dispatch(modalEffects.closeModal());
          if (data.active) toast.success('Договор активирован');
          else if (data.number) toast.success('Номер договора изменен');
        }
      } catch (error) {
        errorParse(error);
        dispatch(duckActions.changeClientDocumentFailure(error));
      }
    },
    fetchClientDocumentPdf: ({ fileId, isDownload, name = '' }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchClientDocumentPdfRequest());
      try {
        const response = await getClientDocument(fileId);
        const objUrl = window.URL.createObjectURL(
          new Blob([response], { type: 'application/pdf' })
        );

        if (isDownload) {
          const link = document.createElement('a');
          link.href = objUrl;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click()
          document.body.removeChild(link)
        } else {
          const printFrame = document.createElement('iframe');
          printFrame.src = objUrl;
          printFrame.style.display = 'none';
          printFrame.onload = () => {
            printFrame.contentWindow.focus();
            printFrame.contentWindow.print();
          };
          document.body.appendChild(printFrame);
        }

        dispatch(duckActions.fetchClientDocumentPdfSuccess());
      } catch (error) {
        dispatch(duckActions.fetchClientDocumentPdfFailure(error));
      }
    },
    getClientLegalEntityDocument: ({ legalEntityId, clientId, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getClientLegalEntityDocumentRequest());
      try {
        const response = await getClientLegalEntityDocument(legalEntityId, clientId);
        dispatch(duckActions.getClientLegalEntityDocumentSuccess(response));

        return response;
      } catch (error) {
        dispatch(duckActions.getClientLegalEntityDocumentFailure(error));
      }
    },
    getDocumentTemplate: (type) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getDocumentTemplateRequest());
      try {
        const response = await getDocumentTemplate(type);
        dispatch(duckActions.getDocumentTemplateSuccess(response));

        return response;
      } catch (error) {
        dispatch(duckActions.getDocumentTemplateFailure(error));
      }
    },
  },
  selectors: {
    getClientDocuments: (getState, createSelector) => createSelector([getState], s => s.clientDocuments),
    getClientArchiveDocuments: (getState, createSelector) => createSelector([getState], s => s.clientArchiveDocuments),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
  }
}

export const { actions, selectors, effects, reducer } = createDuck(options);