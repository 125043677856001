import {
  setSettings,
  getSettings,
  getEquipment,
  setEquipment,
  updateEquipment,
  deleteEquipment,
  getStorageEquipments,
  setEquipmentDepreciation,
  updateEquipmentDepreciation,
  deleteEquipmentDepreciation,
  getEquipmentEstimation,
  setEquipmentEstimation,
  updateEquipmentEstimation,
  deleteEquipmentEstimation,
  getConsumable,
  setConsumable,
  updateConsumable,
  deleteConsumable,
  getConsumableEstimation,
  setConsumableEstimation,
  updateConsumableEstimation,
  deleteConsumableEstimation,
  getDetergent,
  setDetergent,
  updateDetergent,
  deleteDetergent,
  getDetergentEstimation,
  setDetergentEstimation,
  updateDetergentEstimation,
  deleteDetergentEstimation,
  getEquipmentStandartSet,
  getConsumableStandartSet,
  getDetergentStandartSet
} from 'api/TMCSettings.api';

import { effects as modalEffects } from 'redux/ducks/modal.duck';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';

const TMCSettingsErrorProcessing = (error, message = '') => {
  let errorMessage = message || error.message;

  if (!errorMessage) {
    switch (error.name) {
      case 'UnprocessableEntityError': errorMessage = 'Произошла ошибка из-за входных данных'; break;
      default: errorMessage = 'Произошла какая-то ошибка, попробуйте позже'
    }
  }

  if (error.type === 'warn')
    toast.warn(errorMessage)
  else toast.error(errorMessage)
}

const initialState = {
  TMCSettings: {
    globalParam: null,
    equipmentParam: null,
    consumableParam: null,
    detergentParam: null
  },
  equipment: [],
  justCreatedEquipment: {},
  equipmentEstimation: [],
  storageEquipments: [],
  consumable: [],
  consumableEstimation: [],
  detergent: [],
  detergentEstimation: [],
  equipmentStandartSet: [],
  consumableStandartSet: [],
  detergentStandartSet: [],
  nomenclatureSwitches: {
    equipment: 1,
    consumable: 2,
    detergent: 3,
  },
  methodSettings: {
    detergent: [
      { setId: 5, title: 'Учитывать фактический расход' },
      { setId: 6, title: 'Списывать на заказ' }
    ],
    consumable: [
      { setId: 5, title: 'Учитывать износ' },
      { setId: 6, title: 'Списывать на заказ' }
    ],
    equipment: [
      { setId: 0, title: 'Не учитывать амортизацию в сметах' },
      { setId: 4, title: 'Учитывать амортизацию в сметах' }
    ]
  },
  isLoading: false,
  errorMessage: '',
};

export const options = {
  name: 'TMCSettings',
  initialState,

  actions: {
    resetTMCSettings: () => state => ({
      ...state,
      TMCSettings: {
        globalParam: null,
        equipmentParam: null,
        consumableParam: null,
        detergentParam: null
      }
    }),
    setTMCSetting: ({ paramId = null, value = null, settingName }) => state => ({
      ...state,
      TMCSettings: {
        ...state.TMCSettings,
        [settingName]: paramId ? { paramId, value } : null
      },
    }),
    setDefaultSettings: () => state => ({
      ...state,
      TMCSettings: {
        globalParam: null,
        equipmentParam: { paramId: 1, value: 4 },
        consumableParam: { paramId: 2, value: 5 },
        detergentParam: { paramId: 3, value: 5 }
      },
    }),
    setTMCSettingsRequest: () => state => ({
      ...state,
      isLoading: true
    }),
    setTMCSettingsSuccess: () => state => ({
      ...state,
      isLoading: false
    }),
    setTMCSettingsFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),
    fetchTMCSettingsRequest: () => state => ({
      ...state,
      isLoading: true
    }),
    fetchTMCSettingsSuccess: () => state => ({
      ...state,
      isLoading: false
    }),
    fetchTMCSettingsFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),

    setJustCreatedEquipment: data => state => ({
      ...state,
      justCreatedEquipment: data
    }),
    setDetergentEstimation: data => state => ({
      ...state,
      detergentEstimation: data
    }),
    getEquipmentRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getEquipmentSuccess: data => state => ({
      ...state,
      equipment: data,
      isLoading: false,
    }),
    getEquipmentFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),
    setEquipmentRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    setEquipmentSuccess: equipment => state => ({
      ...state,
      equipment: state.equipment.reduce((prValue, equipmentGroup) => [
        ...prValue,
        {
          ...equipmentGroup,
          items: equipmentGroup.typeId === equipment.type ? [...equipmentGroup.items, equipment] : equipmentGroup.items
        }
      ], []),
      isLoading: false,
    }),
    setEquipmentFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),
    updateEquipmentRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    updateEquipmentSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    updateEquipmentFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),
    getConsumableRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getConsumableSuccess: data => state => ({
      ...state,
      consumable: data,
      isLoading: false,
    }),
    getConsumableFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),
    getDetergentRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getDetergentSuccess: data => state => ({
      ...state,
      detergent: data,
      isLoading: false,
    }),
    getDetergentFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),
    setDetergentRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    setDetergentSuccess: detergent => state => ({
      ...state,
      detergent: [...state.detergent, detergent],
      isLoading: false,
    }),
    setDetergentFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),
    updateDetergentRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    updateDetergentSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    updateDetergentFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),
    deleteDetergentRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    deleteDetergentSuccess: id => state => ({
      ...state,
      detergent: state.detergent.filter(item => item.id !== id),
      isLoading: false,
    }),
    deleteDetergentFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),
    getStorageEquipmentsRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getStorageEquipmentsSuccess: data => state => ({
      ...state,
      storageEquipments: data,
      isLoading: false,
    }),
    getStorageEquipmentsFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),
    setEquipmentDepreciationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    setEquipmentDepreciationSuccess: equipment => state => ({
      ...state,
      storageEquipments: [...state.storageEquipments, equipment],
      isLoading: false,
    }),
    setEquipmentDepreciationFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),
    updateEquipmentDepreciationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    updateEquipmentDepreciationSuccess: ({ id, data }) => state => ({
      ...state,
      storageEquipments: state.storageEquipments.reduce((prev, item) => [
        ...prev,
        item.id === id ? { ...item, ...data } : item
      ], []),
      isLoading: false,
    }),
    updateEquipmentDepreciationFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),
    deleteEquipmentDepreciationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    deleteEquipmentDepreciationSuccess: id => state => ({
      ...state,
      storageEquipments: state.storageEquipments.filter(item => item.id !== id),
      isLoading: false,
    }),
    deleteEquipmentDepreciationFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),

    deleteEquipmentRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    deleteEquipmentSuccess: id => state => ({
      ...state,
      equipment: state.equipment.reduce((prValue, equipmentGroup) => [
        ...prValue,
        {
          ...equipmentGroup,
          items: equipmentGroup.items.filter(item => item.id !== id)
        }
      ], []),
      isLoading: false,
    }),
    deleteEquipmentFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),

    setConsumableRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    setConsumableSuccess: consumable => state => ({
      ...state,
      consumable: [...state.consumable, consumable],
      isLoading: false,
    }),
    setConsumableFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),
    updateConsumableRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    updateConsumableSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    updateConsumableFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),
    deleteConsumableRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    deleteConsumableSuccess: id => state => ({
      ...state,
      consumable: state.consumable.filter(item => item.id !== id),
      isLoading: false,
    }),
    deleteConsumableFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),

    getEquipmentEstimationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getEquipmentEstimationSuccess: ({ data, standartSet }) => state => ({
      ...state,
      [standartSet ? 'equipmentStandartSet' : 'equipmentEstimation']: data,
      isLoading: false,
    }),
    getEquipmentEstimationFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),
    setEquipmentEstimationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    setEquipmentEstimationSuccess: data => state => ({
      ...state,
      [data.serviceId ? 'equipmentStandartSet' : 'equipmentEstimation']: [
        ...state[data.serviceId ? 'equipmentStandartSet' : 'equipmentEstimation'],
        data
      ],
      isLoading: false,
    }),
    setEquipmentEstimationFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),
    updateEquipmentEstimationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    updateEquipmentEstimationSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    updateEquipmentEstimationFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),
    deleteEquipmentEstimationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    deleteEquipmentEstimationSuccess: ({ id, standartSet }) => state => ({
      ...state,
      [standartSet ? 'equipmentStandartSet' : 'equipmentEstimation']:
        state[standartSet ? 'equipmentStandartSet' : 'equipmentEstimation'].filter(item => item.id !== id),
      isLoading: false,
    }),
    deleteEquipmentEstimationFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),

    getConsumableEstimationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getConsumableEstimationSuccess: ({ data, standartSet, }) => state => ({
      ...state,
      [standartSet ? 'consumableStandartSet' : 'consumableEstimation']: data,
      isLoading: false,
    }),
    getConsumableEstimationFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),
    setConsumableEstimationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    setConsumableEstimationSuccess: data => state => ({
      ...state,
      [data.serviceId ? 'consumableStandartSet' : 'consumableEstimation']: [
        ...state[data.serviceId ? 'consumableStandartSet' : 'consumableEstimation'],
        data
      ],
      isLoading: false,
    }),
    setConsumableEstimationFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),
    updateConsumableEstimationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    updateConsumableEstimationSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    updateConsumableEstimationFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),
    deleteConsumableEstimationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    deleteConsumableEstimationSuccess: ({ id, standartSet }) => state => ({
      ...state,
      [standartSet ? 'consumableStandartSet' : 'consumableEstimation']:
        state[standartSet ? 'consumableStandartSet' : 'consumableEstimation'].filter(item => item.id !== id),
      isLoading: false,
    }),
    deleteConsumableEstimationFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),

    getDetergentEstimationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getDetergentEstimationSuccess: ({ data, standartSet }) => state => ({
      ...state,
      [standartSet ? 'detergentStandartSet' : 'detergentEstimation']: data,
      isLoading: false,
    }),
    getDetergentEstimationFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),
    setDetergentEstimationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    setDetergentEstimationSuccess: data => state => ({
      ...state,
      [data.serviceId ? 'detergentStandartSet' : 'detergentEstimation']: [
        ...state[data.serviceId ? 'detergentStandartSet' : 'detergentEstimation'],
        data
      ],
      isLoading: false,
    }),
    setDetergentEstimationFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),
    updateDetergentEstimationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    updateDetergentEstimationSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    updateDetergentEstimationFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),
    deleteDetergentEstimationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    deleteDetergentEstimationSuccess: ({ id, standartSet }) => state => ({
      ...state,
      [standartSet ? 'detergentStandartSet' : 'detergentEstimation']: state[standartSet ? 'detergentStandartSet' : 'detergentEstimation'].filter(item => item.id !== id),
      isLoading: false,
    }),
    deleteDetergentEstimationFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),
    getEquipmentStandartSetRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getEquipmentStandartSetSuccess: (data) => state => ({
      ...state,
      equipmentEstimation: [...state.equipmentEstimation, ...data],
      isLoading: false,
    }),
    getEquipmentStandartSetFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),
    getConsumableStandartSetRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getConsumableStandartSetSuccess: (data) => state => ({
      ...state,
      consumableEstimation: [...state.consumableEstimation, ...data],
      isLoading: false,
    }),
    getConsumableStandartSetFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),
    getDetergentStandartSetRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getDetergentStandartSetSuccess: (data) => state => ({
      ...state,
      detergentEstimation: [...state.detergentEstimation, ...data],
      isLoading: false,
    }),
    getDetergentStandartSetFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message
    }),
  },

  effects: {
    setTMCSettings: () => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.setTMCSettingsRequest())

      const { TMCSettings } = getState().TMCSettings;
      const settingsRange = Object.values(TMCSettings).filter(item => item);

      try {
        await setSettings({ data: settingsRange });
        dispatch(duckEffects.fetchTMCSettings());

        dispatch(duckActions.setTMCSettingsSuccess())
        toast.success('Настройки изменены')
      } catch (error) {
        dispatch(duckActions.setTMCSettingsFailure(error))
      }
    },

    fetchTMCSettings: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchTMCSettingsRequest());

      try {
        const { data } = await getSettings();
        dispatch(duckActions.resetTMCSettings());
        data.forEach(paramItem => {
          switch (paramItem.paramId) {
            case 4: dispatch(duckActions.setTMCSetting({ paramId: paramItem.paramId, value: paramItem.value, settingName: 'globalParam' })); break;
            case 1: dispatch(duckActions.setTMCSetting({ paramId: paramItem.paramId, value: paramItem.value, settingName: 'equipmentParam' })); break;
            case 2: dispatch(duckActions.setTMCSetting({ paramId: paramItem.paramId, value: paramItem.value, settingName: 'consumableParam' })); break;
            case 3: dispatch(duckActions.setTMCSetting({ paramId: paramItem.paramId, value: paramItem.value, settingName: 'detergentParam' })); break;

            default: break;
          }
        })
        dispatch(duckActions.fetchTMCSettingsSuccess());
      } catch (error) {
        dispatch(duckActions.fetchTMCSettingsFailure(error));
      }
    },
    setGlobalParam: paramItem => async (dispatch, getState, duckActions, duckEffects) => {
      if (paramItem) {
        dispatch(duckActions.resetTMCSettings())
        dispatch(duckActions.setTMCSetting({ paramId: paramItem.paramId, value: paramItem.value, settingName: 'globalParam' }))
      } else
        dispatch(duckActions.setTMCSetting({ settingName: 'globalParam' }));

      await dispatch(duckEffects.setTMCSettings())
    },
    setEquipmentParam: paramItem => async (dispatch, getState, duckActions, duckEffects) => {
      if (paramItem)
        dispatch(duckActions.setTMCSetting({ paramId: paramItem.paramId, value: paramItem.value, settingName: 'equipmentParam' }));
      else
        dispatch(duckActions.setTMCSetting({ settingName: 'equipmentParam' }));

      await dispatch(duckEffects.setTMCSettings())
    },
    setConsumableParam: paramItem => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.setTMCSetting({ paramId: paramItem.paramId, value: paramItem.value, settingName: 'consumableParam' }));
      await dispatch(duckEffects.setTMCSettings())
    },
    setDetergentParam: paramItem => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.setTMCSetting({ paramId: paramItem.paramId, value: paramItem.value, settingName: 'detergentParam' }));
      await dispatch(duckEffects.setTMCSettings())
    },
    setDefaultSettings: () => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.setDefaultSettings());
      await dispatch(duckEffects.setTMCSettings())
    },

    getEquipment: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getEquipmentRequest());
      try {
        const response = await getEquipment();
        dispatch(duckActions.getEquipmentSuccess(response.data));
      } catch (error) {
        dispatch(duckActions.getEquipmentFailure(error));
      }
    },
    setEquipment: data => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.setEquipmentRequest());
      try {
        const response = await setEquipment(data);
        toast.success('Оборудование добавлено в номенклатуру');
        dispatch(duckActions.setEquipmentSuccess(response));
        await dispatch(duckActions.setJustCreatedEquipment(response))

        return response;
      } catch (error) {
        toast.error(error.message);
        dispatch(duckActions.setEquipmentFailure(error))
      }
    },
    updateEquipment: ({ id, data }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.updateEquipmentRequest());
      try {
        await updateEquipment(id, data);
        toast.success('Данныe об оборудовании обновлены');
        await dispatch(duckEffects.getEquipment());
        dispatch(duckActions.updateEquipmentSuccess());
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.updateEquipmentFailure(error))
      }
    },
    deleteEquipment: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deleteEquipmentRequest())
      try {
        await deleteEquipment(id);
        toast.success('Оборудование удалено из номенклатуры');
        dispatch(duckActions.deleteEquipmentSuccess(id))
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteEquipmentFailure(error))
      }
    },

    getEquipmentEstimation: (params = {}) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getEquipmentEstimationRequest());
      try {
        const { data } = await getEquipmentEstimation(params.standartSet);
        dispatch(duckActions.getEquipmentEstimationSuccess({ data, standartSet: params.standartSet }));
      } catch (error) {
        dispatch(duckActions.getEquipmentEstimationFailure(error))
      }
    },
    setEquipmentEstimation: ({ onSuccessAction = null, data: estimationObj }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.setEquipmentEstimationRequest());
      try {
        const { name, standart, measureType, coefficient_1, coefficient_2, ...equipmantObj } = estimationObj;
        const { data } = await setEquipmentEstimation({ ...equipmantObj, equipmentTypeId: name.id || name, equipmentQuantity: standart });
        toast.success('Данные об оборудовании добавлены');

        if (onSuccessAction) onSuccessAction();
        dispatch(duckActions.setEquipmentEstimationSuccess(data));
      } catch (error) {
        TMCSettingsErrorProcessing(error);
        dispatch(duckActions.setEquipmentEstimationFailure(error));
      }
    },
    updateEquipmentEstimation: ({ id, data, onSuccessAction }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.updateEquipmentEstimationRequest());
      try {
        const { name, standart, ...equipmantObj } = data;
        await updateEquipmentEstimation(id, { ...equipmantObj, equipmentTypeId: name, equipmentQuantity: standart });
        toast.success('Данные об оборудовании изменены');
        dispatch(duckActions.updateEquipmentEstimationRequest());
        if (onSuccessAction) onSuccessAction();
        await dispatch(duckEffects.getEquipmentEstimation({ standartSet: Boolean(data.serviceId) }));
      } catch (error) {
        dispatch(duckActions.updateEquipmentEstimationRequest());
      }
    },
    deleteEquipmentEstimation: ({ id, standartSet }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deleteEquipmentEstimationRequest());
      try {
        await deleteEquipmentEstimation(id);
        dispatch(duckActions.deleteEquipmentEstimationSuccess({ id, standartSet }));
        toast.success('Данные об оборудовании удалены');
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteEquipmentEstimation(error));
      }
    },

    getStorageEquipments: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getStorageEquipmentsRequest());
      try {
        const response = await getStorageEquipments();
        dispatch(duckActions.getStorageEquipmentsSuccess(response.data));
      } catch (error) {
        dispatch(duckActions.getStorageEquipmentsFailure(error));
      }
    },
    setEquipmentDepreciation: equipmentObj => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.setEquipmentDepreciationRequest());
      try {
        const response = await setEquipmentDepreciation(equipmentObj);
        toast.success('Оборудование добавлено');
        dispatch(duckActions.setEquipmentDepreciationSuccess(response));

        return response;
      } catch (error) {
        dispatch(duckActions.setEquipmentDepreciationFailure(error));
      }
    },
    updateEquipmentDepreciation: ({ id, data, doToast = true, recalcPurchaseOrder, }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.updateEquipmentDepreciationRequest());
      try {
        await updateEquipmentDepreciation(id, data);
        dispatch(duckActions.updateEquipmentDepreciationSuccess({ id, data }));
        if (recalcPurchaseOrder) recalcPurchaseOrder();
        if (doToast) toast.success('Данные обновлены');
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.updateEquipmentDepreciationFailure(error));
      }
    },
    deleteEquipmentDepreciation: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deleteEquipmentDepreciationRequest());
      try {
        await deleteEquipmentDepreciation(id);
        toast.success('Позиция удалена в корзину');
        dispatch(duckActions.deleteEquipmentDepreciationSuccess(id));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteEquipmentDepreciationFailure(error));
      }
    },

    getConsumable: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getConsumableRequest());
      try {
        const response = await getConsumable();
        dispatch(duckActions.getConsumableSuccess(response.data));
      } catch (error) {
        dispatch(duckActions.getConsumableFailure(error));
      }
    },
    setConsumable: data => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.setConsumableRequest());
      try {
        const response = await setConsumable(data);
        toast.success('Расходные материалы добавлены в номенклатуру');
        dispatch(duckActions.setConsumableSuccess(response))

        return response;
      } catch (error) {
        dispatch(duckActions.setConsumableFailure(error))
      }
    },
    updateConsumable: ({ id, data }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.updateConsumableRequest());
      try {
        await updateConsumable(id, data);
        toast.success('Данные о расходных материалах обновлены');
        await dispatch(duckEffects.getConsumable());

        dispatch(duckActions.updateConsumableSuccess());
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.updateConsumableFailure());
      }
    },
    deleteConsumable: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deleteConsumableRequest());
      try {
        await deleteConsumable(id);
        toast.success('Расходные материалы удалены из номенклатуры');
        dispatch(duckActions.deleteConsumableSuccess(id));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteConsumableFailure());
      }
    },

    getConsumableEstimation: (params = {}) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getConsumableEstimationRequest());
      try {
        const { data } = await getConsumableEstimation(params.standartSet);
        dispatch(duckActions.getConsumableEstimationSuccess({ data, standartSet: params.standartSet }));
      } catch (error) {
        dispatch(duckActions.getConsumableEstimationFailure(error));
      }
    },
    setConsumableEstimation: ({ onSuccessAction = null, data: estimationObj }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.setConsumableEstimationRequest());
      try {
        const { name, standart, measureType, coefficient_1, coefficient_2, ...consumableObj } = estimationObj;
        const { data } = await setConsumableEstimation({ ...consumableObj, consumableTypeId: name.id || name, consumableQuantity: standart });
        toast.success('Данные о расходных материалах добавлены');
        dispatch(duckActions.setConsumableEstimationSuccess(data));
        if (onSuccessAction) onSuccessAction();
      } catch (error) {
        TMCSettingsErrorProcessing(error);
        dispatch(duckActions.setConsumableEstimationFailure(error));
      }
    },
    updateConsumableEstimation: ({ id, data, onSuccessAction }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.updateConsumableEstimationRequest());
      try {
        const { name, standart, ...consumableObj } = data;
        await updateConsumableEstimation(id, { ...consumableObj, consumableTypeId: name, consumableQuantity: standart });
        toast.success('Данные о расходных материалах изменены');
        dispatch(duckActions.updateConsumableEstimationSuccess());
        if (onSuccessAction) onSuccessAction();
        await dispatch(duckEffects.getConsumableEstimation({ standartSet: Boolean(data.serviceId) }));
      } catch (error) {
        dispatch(duckActions.updateConsumableEstimationFailure(error));
      }
    },
    deleteConsumableEstimation: ({ id, standartSet }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deleteConsumableEstimationRequest());
      try {
        await deleteConsumableEstimation(id);
        dispatch(duckActions.deleteConsumableEstimationSuccess({ id, standartSet }));
        toast.success('Данные о расходных материалах удалены');
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteConsumableEstimationFailure(error));
      }

    },

    getDetergent: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getDetergentRequest());
      try {
        const response = await getDetergent();
        dispatch(duckActions.getDetergentSuccess(response.data));
      } catch (error) {
        dispatch(duckActions.getDetergentFailure(error));
      }
    },
    setDetergent: ({ ...data }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.setDetergentRequest());
      try {
        const response = await setDetergent({
          ...data,
        });

        toast.success('Моющие средства добавлены в номенклатуру');
        dispatch(duckActions.setDetergentSuccess(response));
        return response;
      } catch (error) {
        dispatch(duckActions.setDetergentFailure(error));
      }
    },
    updateDetergent: ({ id, data }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.updateDetergentRequest());
      try {
        await updateDetergent(id, {
          ...data,
        });
        toast.success('Данные о моющем средстве обновлены');
        await dispatch(duckEffects.getDetergent());

        dispatch(duckActions.updateDetergentSuccess());
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.updateDetergentFailure());
      }
    },
    deleteDetergent: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deleteDetergentRequest());
      try {
        await deleteDetergent(id);
        toast.success('Моющие средства удалены из номенклатуры');
        dispatch(duckActions.deleteDetergentSuccess(id));
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteDetergentFailure());
      }
    },

    getDetergentEstimation: (params = {}) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getDetergentEstimationRequest());
      try {
        const { data } = await getDetergentEstimation(params.standartSet);
        dispatch(duckActions.getDetergentEstimationSuccess({ data, standartSet: params.standartSet }));
      } catch (error) {
        dispatch(duckActions.getDetergentEstimationFailure(error));
      }
    },
    setDetergentEstimation: ({ onSuccessAction = null, data: estimationObj }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.setDetergentEstimationRequest());
      try {
        const { name, standart, ...detergentObj } = estimationObj;
        const { data } = await setDetergentEstimation({ ...detergentObj, detergentTypeId: name.id || name, detergentQuantity: standart });
        toast.success('Данные о моющем средстве добавлены');
        dispatch(duckActions.setDetergentEstimationSuccess(data));
        if (onSuccessAction) onSuccessAction();
      } catch (error) {
        TMCSettingsErrorProcessing(error);
        dispatch(duckActions.setDetergentEstimationFailure(error));
      }
    },
    updateDetergentEstimation: ({ id, data, onSuccessAction = null }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.updateDetergentEstimationRequest());
      try {
        const { name, standart, ...detergentObj } = data;
        await updateDetergentEstimation(id, { ...detergentObj, detergentTypeId: name, detergentQuantity: standart });
        toast.success('Данные о моющем средстве изменены');
        dispatch(duckActions.updateDetergentEstimationSuccess());
        if (onSuccessAction) onSuccessAction();
        await dispatch(duckEffects.getDetergentEstimation({ standartSet: Boolean(data.serviceId) }));
      } catch (error) {
        dispatch(duckActions.updateDetergentEstimationFailure(error));
      }
    },
    deleteDetergentEstimation: ({ id, standartSet }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deleteDetergentEstimationRequest());
      try {
        await deleteDetergentEstimation(id);
        dispatch(duckActions.deleteDetergentEstimationSuccess({ id, standartSet }));
        toast.success('Данные о моющем средстве удалены');
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteDetergentEstimationFailure(error));
      }
    },
    getEquipmentStandartSet: ({ serviceId, technologyId, standardGroupId, factorId, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getEquipmentStandartSetRequest());
      try {
        const { data } = await getEquipmentStandartSet(serviceId, technologyId, standardGroupId, factorId);
        dispatch(duckActions.getEquipmentStandartSetSuccess(data));
        if (data.length) toast.success('Стандартный набор добавлен');
      } catch (error) {
        dispatch(duckActions.getEquipmentStandartSetFailure(error));
      }
    },
    getConsumableStandartSet: ({ serviceId, technologyId, standardGroupId, factorId, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getConsumableStandartSetRequest());
      try {
        const { data } = await getConsumableStandartSet(serviceId, technologyId, standardGroupId, factorId);
        dispatch(duckActions.getConsumableStandartSetSuccess(data));
        if (data.length) toast.success('Стандартный набор добавлен');
      } catch (error) {
        dispatch(duckActions.getConsumableStandartSetFailure(error));
      }
    },
    getDetergentStandartSet: ({ serviceId, technologyId, standardGroupId, factorId, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getDetergentStandartSetRequest());
      try {
        const { data } = await getDetergentStandartSet(serviceId, technologyId, standardGroupId, factorId);
        dispatch(duckActions.getDetergentStandartSetSuccess(data));
        if (data.length) toast.success('Стандартный набор добавлен');
      } catch (error) {
        dispatch(duckActions.getDetergentStandartSetFailure(error));
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => (s.TMCSettings.errorMessage)),
    getGlobalParam: (getState, createSelector) => createSelector([getState], s => (s.TMCSettings.globalParam)),
    getEquipmentParam: (getState, createSelector) => createSelector([getState], s => (s.TMCSettings.equipmentParam)),
    getConsumableParam: (getState, createSelector) => createSelector([getState], s => (s.TMCSettings.consumableParam)),
    getDetergentParam: (getState, createSelector) => createSelector([getState], s => (s.TMCSettings.detergentParam)),
    getEquipment: (getState, createSelector) => createSelector([getState], s => s.equipment),
    getEquipmentEstimation: (getState, createSelector) => createSelector([getState], s => s.equipmentEstimation),
    getStorageEquipments: (getState, createSelector) => createSelector([getState], s => s.storageEquipments),
    getConsumable: (getState, createSelector) => createSelector([getState], s => s.consumable),
    getConsumableEstimation: (getState, createSelector) => createSelector([getState], s => s.consumableEstimation),
    getDetergent: (getState, createSelector) => createSelector([getState], s => s.detergent),
    getDetergentEstimation: (getState, createSelector) => createSelector([getState], s => s.detergentEstimation),
    getNomenclatureSwitches: (getState, createSelector) => createSelector([getState], s => s.nomenclatureSwitches),
    getEquipmentStandartSet: (getState, createSelector) => createSelector([getState], s => s.equipmentStandartSet),
    getConsumableStandartSet: (getState, createSelector) => createSelector([getState], s => s.consumableStandartSet),
    getDetergentStandartSet: (getState, createSelector) => createSelector([getState], s => s.detergentStandartSet),
    isLoading: (getState, createSelector) => createSelector([getState], s => (s.isLoading)),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
