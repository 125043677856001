import { hot } from 'react-hot-loader/root';
import React from 'react';
import { connect } from 'react-redux';
// Ducks
import {
  effects as handbookEffects,
  selectors as handbookSelectors,
} from 'redux/ducks/handbook.duck';
import { effects as userEffects, selectors as userSelectors, actions as userActions } from 'redux/ducks/user.duck';
import { effects as authEffects } from 'redux/ducks/auth.duck';
import {
  effects as companyEffects,
  selectors as companySelectors,
  actions as companyActions,
} from 'redux/ducks/company.duck';
import {
  effects as branchesEffects,
  selectors as branchesSelectors,
} from 'redux/ducks/branches.duck';
import {
  effects as notificationsEffects,
  selectors as notificationsSelectors,
} from 'redux/ducks/userNotifications.duck';

import Template from './PrivateTemplate';

const TemplateContainer = props => <Template {...props} />;

const mapStateToProps = state => ({
  errorMessage: handbookSelectors.getErrorMessage(state),
  isLoading: handbookSelectors.getIsLoading(state),
  user: userSelectors.getUser(state),
  userPermission: userSelectors.getUserPermission(state),
  company: companySelectors.getCompany(state),
  tariffInfo: companySelectors.getTariffInfo(state),
  isStartInfoLoaded: companySelectors.getIsStartInfoLoaded(state),
  branches: branchesSelectors.getBranches(state),
  notifications: notificationsSelectors.getUserMenuNotifications(state),
  unreadNotificationsCount: notificationsSelectors.getUserUnreadCount(state),
  roles: handbookSelectors.getRoles(state),
  companyDataViewPerm: userSelectors.getCompanyDataViewFullPerm(state),
  clientsListViewPerm: userSelectors.getClientsListViewFullPerm(state) || userSelectors.getClientsListViewPartPerm(state),
  orderListViewPerm: userSelectors.getOrderListViewFullPerm(state) || userSelectors.getOrderListViewPartPerm(state),
  orderScheduleViewPerm: userSelectors.getOrderScheduleViewFullPerm(state) || userSelectors.getOrderScheduleViewPartPerm(state),
  techStaffScheduleViewPerm: userSelectors.getTechStaffScheduleViewFullPerm(state) || userSelectors.getTechStaffScheduleViewPartPerm(state),
  freeStaffScheduleViewPerm: userSelectors.getFreeStaffScheduleViewFullPerm(state) || userSelectors.getFreeStaffScheduleViewPartPerm(state),
  taskScheduleViewYourPerm: userSelectors.getTaskScheduleViewYourFullPerm(state) || userSelectors.getTaskScheduleViewYourPartPerm(state),
  techStaffViewPerm: userSelectors.getTechStaffViewFullPerm(state),
  adminStaffViewPerm: userSelectors.getAdminStaffViewFullPerm(state),
  freeStaffViewPerm: userSelectors.getFreeStaffViewFullPerm(state),
  marginIncViewPerm: userSelectors.getMarginIncViewFullPerm(state) || userSelectors.getMarginIncViewPartPerm(state),
  statusesViewPerm: userSelectors.getStatusesViewFullPerm(state),
  notificationsViewPerm: userSelectors.getNotificationsViewFullPerm(state),
  paymentViewPerm: userSelectors.getPaymentViewFullPerm(state),
  knowledgeBaseViewPerm: userSelectors.getKnowledgeBaseViewFullPerm(state),
  branchesTariffPerm: handbookSelectors.getBranchesTariffPerm(state),
  knowledgeBaseViewTariffPerm: handbookSelectors.getKnowledgeBaseViewTariffPerm(state),
  workScheduleTariffPerm: handbookSelectors.getWorkScheduleTariffPerm(state),
  marginalIncomeTariffPerm: handbookSelectors.getMarginalIncomeTariffPerm(state),
});

const mapDispatchToProps = {
  fetchUser: userEffects.fetchUser,
  fetchHandbook: handbookEffects.fetchHandbook,
  fetchProtectedHandbook: handbookEffects.fetchProtectedHandbook,
  logout: authEffects.logout,
  fetchCompany: companyEffects.fetchCompany,
  fetchTariffInfo: companyEffects.fetchTariffInfo,
  setIsStartInfoLoaded: companyActions.setIsStartInfoLoaded,
  setTariffInfoNeedToFetch: companyActions.setTariffInfoNeedToFetch,
  fetchBranches: branchesEffects.fetchBranches,
  changeBranch: branchesEffects.changeBranch,
  getUserNotifications: notificationsEffects.getUserNotifications,
  setUserNotification: notificationsEffects.setUserNotification,
  setUserPermission: userActions.setUserPermission,
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(TemplateContainer));
