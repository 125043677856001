import { keyframes } from 'styled-components';

export const mountError = keyframes`
  0% {
    transform: translateX(20px);
    height: 0px;
    opacity: 0;
  }
  30% {
    height: 17px;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
`;

export const loadingPulse = keyframes` 
  0% {
    color: white;
    background-color: rgba(0, 0, 30, 0.2);
  }
  50% {
    color: lightslategrey;
    background-color: rgba(0, 0, 40, 0.3);
  }
  100% {
    color: white;
    background-color: rgba(0, 0, 30, 0.2);
  }
`;

export const inputLoadingPulse = keyframes` 
  0% {
    color: white;
  }
  50% {
    color: lightslategrey;
  }
  100% {
    color: white;
  }
`;

export const mount = keyframes` 
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const unmount = keyframes` 
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

export const moveFromTop = keyframes`
  from {
    transform: translateY(-100vh);
  }
  to {
    transform: translateY(0);
  }
`;

export const moveOutTop = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100vh);
  }
`;

export const modalOverlayMount = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const modalOverlayUnmount = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const scaleIn = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

export const scaleOut = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;
