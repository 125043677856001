import { account } from 'utils/axiosInstances';
import { camelize } from 'utils/objectUtilities';
import { get } from 'lodash';
import { getPaginationParams, } from './utils/paginationUtils';

const URL = '/recycle-bin';

// Legal Entities
export const getLegalEntities = async (params = { limit: 0, offset: 1 }) => {
  try {
    const query =
      `${URL}/legal-entities?${getPaginationParams(params.offset, params.limit)}` +
      `${params.sortBy ? `&sort_by=${params.sortBy}` : ''}` +
      `${params.sortBy ? `&sort_direction=${params.sortDirection ? 'desc' : 'asc'}` : ''}`;
    const response = await account.get(query);
    const items = get(response, 'data', []);
    return camelize(items, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const restoreLegalEntity = async id => {
  try {
    const response = await account.patch(`${URL}/legal-entities/${id}/restore`);
    return response;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const deleteLegalEntity = async ids => {
  try {
    const response = await account.delete(`${URL}/legal-entities/delete-permanently`, { data: { legal_entities_ids: ids } });
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

// Employees
export const getEmployees = async (params = { limit: 0, offset: 1, }) => {
  try {
    const query =
      `${URL}/employees?${getPaginationParams(params.offset, params.limit)}` +
      `${params.sortBy ? `&sort_by=${params.sortBy}` : ''}` +
      `${params.sortBy ? `&sort_direction=${params.sortDirection ? 'desc' : 'asc'}` : ''}`;
    const response = await account.get(query);
    const items = get(response, 'data', []);
    return camelize(items, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const restoreEmployee = async id => {
  try {
    const response = await account.patch(`${URL}/employees/${id}/restore`);
    return response;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const deleteEmployee = async ids => {
  try {
    const response = await account.delete(`${URL}/employees/delete-permanently`, { data: { employees_ids: ids } });
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

// Orders
export const getOrders = async (params = {}) => {
  try {
    const query =
      `${URL}/orders?${getPaginationParams(params.offset, params.limit)}` +
      `${params.sortBy ? `&sort_by=${params.sortBy}` : ''}` +
      `${params.sortBy ? `&sort_direction=${params.sortDirection ? 'desc' : 'asc'}` : ''}`;
    const response = await account.get(query);
    const data = get(response, 'data', []);
    return camelize(data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const restoreOrder = async id => {
  try {
    const response = await account.patch(`${URL}/orders/${id}/restore`);
    return response;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const deleteOrder = async ids => {
  try {
    const response = await account.delete(`${URL}/orders/delete-permanently`, { data: { orders_ids: ids } });
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

// Clients
export const getClients = async (params = { limit: 0, offset: 1, }) => {
  try {
    const query =
      `${URL}/clients?${getPaginationParams(params.offset, params.limit)}` +
      `${params.sortBy ? `&sort_by=${params.sortBy}` : ''}` +
      `${params.sortBy ? `&sort_direction=${params.sortDirection ? 'desc' : 'asc'}` : ''}`;
    const response = await account.get(query);
    const items = get(response, 'data', []);
    return camelize(items, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const restoreClient = async id => {
  try {
    const response = await account.patch(`${URL}/clients/${id}/restore`);
    return response;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const deleteClient = async ids => {
  try {
    const response = await account.delete(`${URL}/clients/delete-permanently`, { data: { clients_ids: ids } });
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

// Branches
export const getBranches = async (params = { limit: 0, offset: 1, }) => {
  try {
    const query =
      `${URL}/companies?${getPaginationParams(params.offset, params.limit)}` +
      `${params.sortBy ? `&sort_by=${params.sortBy}` : ''}` +
      `${params.sortBy ? `&sort_direction=${params.sortDirection ? 'desc' : 'asc'}` : ''}`;
    const response = await account.get(query);
    const items = get(response, 'data', []);
    return camelize(items, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const restoreBranch = async id => {
  try {
    const response = await account.patch(`${URL}/companies/${id}/restore`);
    return response;
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const deleteBranch = async ids => {
  try {
    const response = await account.delete(`${URL}/companies/delete-permanently`, { data: { companies_ids: ids } });
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

// Knowledge Base
export const getKnowledgeBaseItems = async (params = { limit: 10, offset: 1 }) => {
  try {
    const query =
      `${URL}/knowledge-categories?${getPaginationParams(params.offset, params.limit)}` +
      `${params.sortBy ? `&sort_by=${params.sortBy}` : ''}` +
      `${params.sortBy ? `&sort_direction=${params.sortDirection ? 'desc' : 'asc'}` : ''}`;

    const response = await account.get(query);
    const items = get(response, 'data', []);

    return camelize(items, true);
  } catch (error) {
    const { response } = error;

    throw new Error(response.message, response.status);
  }
}

export const restoreKnowledgeBaseCategory = async id => {
  try {
    const response = await account.patch(`${URL}/knowledge-categories/${id}/restore`);

    return response;
  } catch (error) {
    const { response } = error;

    throw new Error(response.message, response.status);
  }
}

export const restoreKnowledgeBaseFile = async id => {
  try {
    const response = await account.patch(`${URL}/file/${id}/restore`);

    return response;
  } catch (error) {
    const { response } = error;

    throw new Error(response.message, response.status);
  }
}

export const deleteKnowledgeBaseCategoriesAndFiles = async (categoriesIds, filesIds) => {
  try {
    const response = await account.delete(`${URL}/knowledge-categories/delete-permanently`, {
      data: {
        categories_ids: categoriesIds,
        files_ids: filesIds
      }
    });

    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;

    throw new Error(response.message, response.status);
  }
};

export const getStorageEquipments = async (params = { limit: 0, offset: 1, }) => {
  try {
    const query =
      `${URL}/storage-equipments?${getPaginationParams(params.offset, params.limit)}` +
      `${params.sortBy ? `&sort_by=${params.sortBy}` : ''}` +
      `${params.sortBy ? `&sort_direction=${params.sortDirection ? 'desc' : 'asc'}` : ''}`;

    const response = await account.get(query);
    return camelize(response.data, true);
  } catch ({ response }) {
    throw new Error(response.message, response.status);
  }
}

export const restoreStorageEquipment = async id => {
  try {
    const response = await account.patch(`${URL}/equipments/${id}/restore`);
    return response.data;
  } catch ({ response }) {
    throw new Error(response.message, response.status);
  }
}

export const deleteStorageEquipment = async ids => {
  try {
    const response = await account.delete(`${URL}/equipments/delete-permanently`, { data: { equipments_ids: ids } });
    return camelize(response.data, true);
  } catch ({ response }) {
    throw new Error(response.message, response.status);
  }
}

export const getFreelancers = async (params = { limit: 0, offset: 1, }) => {
  try {
    const query =
      `${URL}/freelance-staffes?${getPaginationParams(params.offset, params.limit)}` +
      `${params.sortBy ? `&sort_by=${params.sortBy}` : ''}` +
      `${params.sortBy ? `&sort_direction=${params.sortDirection ? 'desc' : 'asc'}` : ''}`;

    const response = await account.get(query);
    return camelize(response.data, true);
  } catch ({ response }) {
    throw new Error(response.message, response.status);
  }
}

export const restoreFreelancer = async id => {
  try {
    const response = await account.patch(`${URL}/freelance-staffes/${id}/restore`);
    return response.data;
  } catch ({ response }) {
    throw new Error(response.message, response.status);
  }
}

export const deleteFreelancer = async ids => {
  try {
    const response = await account.delete(`${URL}/freelance-staffes/delete-permanently`, { data: { freelance_staff_ids: ids } });
    return camelize(response.data, true);
  } catch ({ response }) {
    throw new Error(response.message, response.status);
  }
}
