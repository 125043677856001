import React, { useEffect } from 'react';
import styled from 'styled-components';
import { media } from 'styles';

import Footer from './components/Footer';

export default function PublicTemplate({ fetchHandbook, children, smallMargin = false }) {
  useEffect(() => {
    fetchHandbook();
  }, []);

  return (
    <>
      <PageContent smallMargin={smallMargin}>{children}</PageContent>
      <Footer />
    </>
  );
}

const PageContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: ${({ smallMargin }) => smallMargin ? '40px' : '87px'};
  margin-bottom: ${({ smallMargin }) => smallMargin ? '10px' : '40px'};

  ${media.SM_DOWN} {
    padding: ${({ smallMargin }) => smallMargin ? '0 25px' : '40px 25px'};
  }

  ${media.XS_DOWN} {
    margin-top: ${({ smallMargin }) => smallMargin ? '20px' : '30px'};
  }

  ${media.XS_MID_DOWN} {
    margin-top: ${({ smallMargin }) => smallMargin ? '20px' : '30px'};
    padding: 10px;
  }

  ${media.XXS} {
    margin: 0;
  }
`;
