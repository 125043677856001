import { account } from 'utils/axiosInstances';
import { camelize, snakeify } from 'utils/objectUtilities';

const URL = '/brigades';

export const fetchBrigades = async () => {
  try {
    const response = await account.get(URL);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const createBrigade = async data => {
  try {
    const response = await account.post(URL, data);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const deleteBrigade = async (id, data) => {
  try {
    const response = await account.delete(`${URL}/${id}`, { data: snakeify(data, true) });
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};
