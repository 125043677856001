import React, { forwardRef } from 'react';
import styled from 'styled-components';
// Styles
import { media } from 'styles';
// Components
import { Link } from 'react-router-dom';
import Icon from 'components/UI/Icon';
// Hooks
import useScroll from 'utils/hooks/useScroll';

function LogotypeComponent({
  className,
  toggleOpened,
  company = {},
  openBranchesHandler,
  branchesTariffPerm
}, ref
) {
  const scroll = useScroll();
  return (
    <Logotype className={className} scroll={scroll} ref={ref}>
      <LogotypeLinkContainer>
        <LogotypeBurger onClick={toggleOpened}>
          <LogotypeIcon icon="mi-menu" />
        </LogotypeBurger>
        <LogotypeLink to="/orders">ВОЛСТАР</LogotypeLink>
      </LogotypeLinkContainer>
      <ToggleBranchesButton onClick={openBranchesHandler} type="button" disabled={!branchesTariffPerm}>
        <LogotypeBorder style={{ padding: '8px 0' }}>
          <p>{company.name || ''}</p>
        </LogotypeBorder>
        <LogotypeLinkContainer style={{ padding: '8px 0' }}>
          <p>{company.address?.cityName || ''}</p>
        </LogotypeLinkContainer>
      </ToggleBranchesButton>
    </Logotype>
  );
}

export default forwardRef(LogotypeComponent);

const Logotype = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1001;
  top: 0;
  left: 0;
  transition: 0.2s;
  padding: 21px 21px 0px 26px;
  width: 253px;
  color: ${({ theme }) => theme.color.white};
  white-space: nowrap;

  ${media.MD_DOWN} {
    padding: 18px 25px;
    box-shadow: 0 0 16px ${({ scroll, theme }) => (scroll ? theme.color.darkGray : 'none')};
    background: ${({ scroll = 0, theme }) => (scroll > 0 ? theme.color.white : 'transparent')};
    color: ${({ scroll = 0, theme }) => (scroll > 0 ? theme.color.black : theme.color.white)};
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;

    width: 100%;
  }
`;

const LogotypeBorder = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.darkGray};
  padding-bottom: 20px;

  & > p {
    white-space: pre-wrap;
    text-align: left;
  }

  ${media.MD_DOWN} {
    border: none;
  }
`;

const LogotypeLink = styled(Link)`
  text-transform: uppercase;
  line-height: 150%;
  font-weight: ${({ theme }) => theme.fontWeight.BOLD};
  font-size: ${({ theme }) => theme.fontSize.L};
`;

const LogotypeBurger = styled.button`
  display: none;
  justify-self: center;
  margin-right: 10px;

  ${media.MD_DOWN} {
    display: block;
  }
`;

const LogotypeIcon = styled(Icon)`
  font-size: ${({ theme }) => theme.fontSize.XL};
  padding: 5px;
`;

const LogotypeLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${media.MD_DOWN} {
    width: auto;
  }
`;

const ToggleBranchesButton = styled.button`
  width: 100%;

  &:hover {
    background-color: ${({ theme, disabled }) => disabled ? 'inherit' : theme.color.paleBlue};
  }

  ${media.MD_DOWN} {
    display: none;
  }
`;
