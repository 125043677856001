import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import Echo from 'laravel-echo';
import io from 'socket.io-client';
import { useHistory, useLocation } from 'react-router-dom';
// Cookies
import { getCookie } from 'utils/cookie';
// Styles
import { media } from 'styles';
// Helpers
import toast from 'utils/toast';
import CookiesRequest from 'utils/cookie/CookiesRequest';
// Components
import UserMenu from 'components/UserMenu';
import SideMenu from './components/SideMenu';
import Logotype from './components/Logotype';

export default function PrivateTemplateComponent({
  fetchUser,
  fetchHandbook,
  fetchProtectedHandbook,
  fetchCompany,
  children,
  user = {},
  logout,
  company = {},
  tariffInfo,
  setTariffInfoNeedToFetch,
  changeBranch,
  fetchBranches,
  fetchTariffInfo,
  isStartInfoLoaded,
  setIsStartInfoLoaded,
  branches,
  getUserNotifications,
  notifications,
  unreadNotificationsCount,
  setUserNotification,
  setUserPermission,
  roles,
  companyDataViewPerm,
  clientsListViewPerm,
  orderListViewPerm,
  orderScheduleViewPerm,
  techStaffScheduleViewPerm,
  freeStaffScheduleViewPerm,
  taskScheduleViewYourPerm,
  techStaffViewPerm,
  adminStaffViewPerm,
  freeStaffViewPerm,
  marginIncViewPerm,
  statusesViewPerm,
  notificationsViewPerm,
  paymentViewPerm,
  knowledgeBaseViewPerm,
  // tariff permissions
  branchesTariffPerm,
  knowledgeBaseViewTariffPerm,
  workScheduleTariffPerm,
  marginalIncomeTariffPerm,
}) {
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/tariff') {
      setTariffInfoNeedToFetch(false);
    }

    fetchTariffInfo();
  }, []);

  useEffect(() => {
    if (tariffInfo.name && !tariffInfo.tariffOff && !isStartInfoLoaded) {
      fetchUser();
      fetchHandbook();
      fetchProtectedHandbook();
      fetchCompany({ tariffUntilWarn: true, });
      getUserNotifications({ limit: 10, offset: 0 });
      setIsStartInfoLoaded(true);
    }
  }, [tariffInfo]);

  useEffect(() => {
    if (branchesTariffPerm) fetchBranches();
  }, [branchesTariffPerm])

  useEffect(() => {
    if (user && roles.length) {
      setUserPermission(roles.find(role => role.name === user.role?.name)?.permissionTemplates || []);
    }
  }, [roles, user])

  const userId = get(user, 'id', null);
  const logotypeRef = useRef(null);

  const getEnv = (hostname) => {
    if (hostname === 'localhost' || hostname === 'dev3.digital-cleaning.ru') {
      return 'dev3'
    }
    if (hostname === 'dev2.digital-cleaning.ru') {
      return 'dev2'
    }
    if (hostname === 'digital-cleaning.com') {
      return 'demo'
    }
  }

  // useEffect(() => {
  //   if (userId) {
  //     const options = {
  //       host: `${process.env.REACT_APP_API_URL.split('').reverse().join('').replace('ipa', '').split('').reverse().join('')}`,
  //       auth: { headers: { Authorization: `Bearer ${getCookie(`token`)}` } },
  //       broadcaster: 'socket.io',
  //       client: io,
  //       reconnectionAttempts: 4,
  //       reconnectionDelay: 1000,
  //       reconnectionDelayMax: 1000,
  //       randomizationFactor: 0,
  //     };
  //     const echoInstance = new Echo(options);
  //     const { socket } = echoInstance.connector;
  //     // DO NOT DELETE THIS COMMENTED CODE, IT WILL BE RELEIVED
  //     // socket.on('reconnect_failed', () => {
  //     //   const timeout = setTimeout(() => {
  //     //     socket.connect();
  //     //     clearTimeout(timeout);
  //     //   }, 60000);
  //     // });
  //     socket.once('connect', () => {
  //       const notificationsChannel = echoInstance.channel(
  //         `dc_redis_private-${getEnv(window.location.hostname)}-users.${userId}.notifications`
  //       );
  //       notificationsChannel.notification((props) => {
  //         const { notification } = props
  //         const { data } = notification;
  //         toast({
  //           text: data.text,
  //           actionText: 'Подробнее',
  //           action: () => !pathname.includes('notifications') && history.push('notifications'),
  //           icon: 'mi-notifications',
  //           options: { autoClose: true, type: 'default' },
  //         });
  //         getUserNotifications();
  //       });
  //     });
  //   }
  //   return () => { };
  // }, [userId]);

  const [opened, setOpened] = useState(false);
  const [branchesOpened, setBranchesOpened] = useState(false);
  const [userMenuOpened, toggleUserMenuOpened] = useState(false);

  function toggleOpened() {
    setOpened(!opened);
  }

  let logotypeHeight = 0;
  if (logotypeRef.current) {
    logotypeHeight = logotypeRef.current.offsetHeight;
  }

  const openBranchesHandler = () => {
    if (branchesTariffPerm) setBranchesOpened(true)
  }

  const closeUserMenuHandler = () => {
    if (userMenuOpened) toggleUserMenuOpened(false);
  }

  return (
    <PrivateTemplate onClick={closeUserMenuHandler}>
      <CookiesRequest />
      <Logotype
        toggleOpened={toggleOpened}
        openBranchesHandler={openBranchesHandler}
        company={company}
        ref={logotypeRef}
        branchesTariffPerm={branchesTariffPerm}
      />
      <SideMenu
        companyDataViewPerm={companyDataViewPerm}
        techStaffScheduleViewPerm={techStaffScheduleViewPerm}
        freeStaffScheduleViewPerm={freeStaffScheduleViewPerm}
        orderScheduleViewPerm={orderScheduleViewPerm}
        orderListViewPerm={orderListViewPerm}
        clientsListViewPerm={clientsListViewPerm}
        taskScheduleViewYourPerm={taskScheduleViewYourPerm}
        techStaffViewPerm={techStaffViewPerm}
        adminStaffViewPerm={adminStaffViewPerm}
        freeStaffViewPerm={freeStaffViewPerm}
        marginIncViewPerm={marginIncViewPerm}
        statusesViewPerm={statusesViewPerm}
        notificationsViewPerm={notificationsViewPerm}
        paymentViewPerm={paymentViewPerm}
        knowledgeBaseViewPerm={knowledgeBaseViewPerm}
        logout={logout}
        opened={opened}
        user={user || {}}
        branchesOpened={branchesOpened}
        toggleOpened={toggleOpened}
        company={company}
        changeBranch={changeBranch}
        setBranchesOpened={setBranchesOpened}
        openBranchesHandler={openBranchesHandler}
        branches={branches}
        logotypeHeight={logotypeHeight}
        // tariff permissions
        knowledgeBaseViewTariffPerm={knowledgeBaseViewTariffPerm}
        branchesTariffPerm={branchesTariffPerm}
        workScheduleTariffPerm={workScheduleTariffPerm}
        marginalIncomeTariffPerm={marginalIncomeTariffPerm}
      />
      {tariffInfo.name && !tariffInfo.tariffOff &&
        <TemplateContent>{children}</TemplateContent>
      }
      <StyledUserMenu
        user={user}
        notifications={notifications}
        setUserNotification={setUserNotification}
        maxToShow={5}
        logout={logout}
        notificationsCount={unreadNotificationsCount}
        userMenuOpened={userMenuOpened}
        toggleUserMenuOpened={toggleUserMenuOpened}
      />
      {opened && <TemplateHider onClick={toggleOpened} />}
      {tariffInfo.tariffOff &&
        <TempClose>
          Для дальнейшего использования платформы, необходимо
          {(tariffInfo.name === 'Нет тарифа') ? ' подключить тариф' : ' продлить срок использования тарифа'}
        </TempClose>
      }
    </PrivateTemplate>
  );
}

const PrivateTemplate = styled.section`
  position: relative;
  display: grid;
  min-height: 100vh;
  /* height: 100%; */
  grid-auto-flow: column;
  grid-template-columns: 286px 1fr;
  grid-template-rows: 86px 1fr;
  grid-template-areas:
    '. content'
    '. content';

  ${media.MD_DOWN} {
    grid-template-columns: 1fr;
    grid-template-rows: 86px 1fr;
    grid-template-areas:
      '.'
      'content';
  }
`;

const TemplateContent = styled.section`
  grid-area: content;
  display: flex;
  flex-direction: column;
  margin: 11px 16px 80px 0px;
  transition: 0.2s;
  border-radius: 6px;
  overflow: hidden;

  ${media.MD_DOWN} {
    margin-left: 16px;
  }

  ${media.XS_DOWN} {
    height: auto;
    margin: 0 0 80px;
  }
`;

const TemplateHider = styled.div`
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: transparent;
  opacity: 0.3;
`;

const StyledUserMenu = styled(UserMenu)`
  position: fixed;
  right: 24px;
  top: 18px;

  ${media.MD_DOWN} {
    right: 18px;
    top: 10px;
  }
`


const TempClose = styled.div`
  background: ${({ theme }) => `linear-gradient(90deg, ${theme.color.violetBlue} 0%, ${theme.color.littleBoyBlue} 100%)`};
  height: 100vh;
  z-index: 10;
  color: white;
  font-weight: bold;
  font-size: 24px;
  padding-left: 300px;
  padding-right: 50px;
  line-height: 30px;
  text-align: center;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  ${media.SM_DOWN} {
    padding-left: 0;
    padding-right: 0;
  }
`
