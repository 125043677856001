// Utils
import { account } from 'utils/axiosInstances';
import { camelize, snakeify } from 'utils/objectUtilities';
import { parseRequestError } from './utils/requestErrorParse';
// Types
import { RoomSettings } from 'core/models/entityTariffs.model';
import { successResponseType } from './types/requestsResponses';

const URLHabitation = 'habitation-setting';

export const getHabitationSettings = async (id: number) => {
  try {
    const { data } = await account.get(`${URLHabitation}/${id}/all?type_service=11`);
    return camelize(data.data, true) as RoomSettings[];
  } catch (error) {
    parseRequestError(error);
  }
};

export const createHabitationSetting = async (settingData: RoomSettings) => {
  try {
    const { data = {} } = await account.post(`${URLHabitation}/create`, snakeify({
      ...settingData,
      "str_staff_salary": 1800,
      "str_additional_staff_salary": 0,
      "str_tax_for_salary": 1000,
      "str_consumable_detergent": 600,
      "str_contractors": 0,
      "str_tax_sum": 0,
      "ind_head_salary": 0,
      "ind_driver_salary": 0,
      "ind_coveralls": 0,
      "ind_inventory": 0,
      "ind_equipment": 0,
      "ind_fuel_expenses": 0,
      extra_charge: 0,
    }, true));

    return camelize(data, true) as successResponseType;
  } catch (error) {
    parseRequestError(error);
  }
};

export const editHabitationSetting = async (settingId: number, settingData: RoomSettings) => {
  try {
    const { data = {} } = await account.put(`${URLHabitation}/update/${settingId}`, snakeify({
      ...settingData,
      "str_staff_salary": 1800,
      "str_additional_staff_salary": 0,
      "str_tax_for_salary": 1000,
      "str_consumable_detergent": 600,
      "str_contractors": 0,
      "str_tax_sum": 0,
      "ind_head_salary": 0,
      "ind_driver_salary": 0,
      "ind_coveralls": 0,
      "ind_inventory": 0,
      "ind_equipment": 0,
      "ind_fuel_expenses": 0,
      extra_charge: 0,
    }, true));

    return camelize(data, true) as successResponseType;
  } catch (error) {
    parseRequestError(error);
  }
};
