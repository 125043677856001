import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const Icon = ({ icon, className = '', ...other }) => {
  const props = {
    ...other,
    className: classNames(
      {
        mi: typeof icon === 'string' && icon.includes('mi'),
      },
      icon,
      className
    ),
  };
  return <StyledIcon {...props} />;
};

Icon.defaultProps = {
  className: '',
  size: 14,
};

export default Icon;

const StyledIcon = styled.i`
  ${({ size }) => (size ? `font-size: ${size}px` : '')};
  ${({ color }) => (color ? `color: ${color}` : '')}
`;
