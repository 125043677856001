import {
  fetchApplication,
  updateApplication,
  deleteApplication,
  fetchApplicationPdf,
} from 'api/application.api';
// Helpers
import history from 'utils/history';
import { createDuck } from './utils/createDuck';
import { downloadFile, printDocument } from './utils/parseFiles';
import { toast } from 'react-toastify';

export const options = {
  name: 'application',
  initialState: {
    application: null,
    errorMessage: '',
    isLoading: false,
  },
  actions: {
    fetchApplicationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    fetchApplicationSuccess: application => state => ({
      ...state,
      isLoading: false,
      application,
    }),
    fetchApplicationFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    updateApplicationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    updateApplicationSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    updateApplicationFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    deleteApplicationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    deleteApplicationSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    deleteApplicationFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    fetchApplicationPdfRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    fetchApplicationPdfSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    fetchApplicationPdfFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
  },
  effects: {
    fetchApplication: ({ orderId }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchApplicationRequest());
      try {
        const { data } = await fetchApplication(orderId);
        dispatch(duckActions.fetchApplicationSuccess(data));
      } catch (error) {
        dispatch(duckActions.fetchApplicationFailure(error));
      }
    },
    updateApplication: ({ applicationId, orderId, payload, backToOrders, }) => async (
      dispatch,
      getState,
      duckActions,
      // duckEffects
    ) => {
      dispatch(duckActions.updateApplicationRequest());
      try {
        await updateApplication(applicationId, payload);
        dispatch(duckActions.updateApplicationSuccess());
        if (backToOrders) {
          history.push('/orders')
          toast.success('Бланк-заявка сохранена');
        }
        // await dispatch(duckEffects.fetchApplication({ orderId }));
      } catch (error) {
        dispatch(duckActions.updateApplicationFailure(error));
      }
    },
    deleteApplication: ({ applicationId, orderId }) => async (
      dispatch,
      getState,
      duckActions,
      duckEffects
    ) => {
      dispatch(duckActions.deleteApplicationRequest());
      try {
        await deleteApplication(applicationId);
        dispatch(duckActions.deleteApplicationSuccess());
        await dispatch(duckEffects.fetchApplication({ orderId }));
      } catch (error) {
        dispatch(duckActions.deleteApplicationFailure(error));
      }
    },
    fetchApplicationPdf: ({ applicationId, isDownload = false, name = '' }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchApplicationPdfRequest());
      try {
        const response = await fetchApplicationPdf(applicationId);
        const objUrl = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );

        if (isDownload) downloadFile(objUrl, name);
        else printDocument(objUrl);

        dispatch(duckActions.fetchApplicationPdfSuccess());
      } catch (error) {
        dispatch(duckActions.fetchApplicationPdfFailure(error));
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.isLoading),
    getApplication: (getState, createSelector) => createSelector([getState], s => s.application),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
