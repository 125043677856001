import React, { useEffect } from 'react';
import { Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

import { getCookie } from 'utils/cookie';
import { actions as authActions } from 'redux/ducks/auth.duck';
import PublicRoute from './components/PublicRoute';
import Cabinet from './components/Cabinet';
import PublicRoutes from './publicRoutes';

const Routes = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname: locationPathname } = useLocation();
  const lastDeletedLocation = useSelector(state => state.auth.lastDeletedLocation);

  useEffect(() => {
    if (lastDeletedLocation === locationPathname)
      dispatch(authActions.pushHistoryState(locationPathname))

    dispatch(authActions.clearLastDeletedLocation())
  }, [lastDeletedLocation]);

  useEffect(() => {
    dispatch(authActions.pushHistoryState(locationPathname));

    history.listen((location, action) => {
      if (action === 'POP') {
        dispatch(authActions.popHistoryState())
      }
      else if (action === 'PUSH') {
        dispatch(authActions.pushHistoryState(location.pathname))
      }
    });
  }, [])

  const accessToken = getCookie(`token`);
  return (
    <Switch location={location}>
      <Redirect
        exact
        from="/"
        to={accessToken ? { pathname: '/orders' } : { pathname: '/login' }}
      />

      <PublicRoute path="/login" exact component={PublicRoutes.Login} />
      <PublicRoute path="/password-recovery" exact component={PublicRoutes.PasswordRecovery} />
      <PublicRoute path="/new-password" exact component={PublicRoutes.NewPassword} />
      <PublicRoute path="/signup" exact component={PublicRoutes.Registration} />
      <PublicRoute path="/new-employees" exact component={PublicRoutes.CreateEmployees} smallMargin />
      <PublicRoute path="/new-company" exact component={PublicRoutes.CreateCompany} smallMargin />
      <PublicRoute path="/new-company-manually" exact component={PublicRoutes.CreateCompanyManually} smallMargin />
      <PublicRoute path="/guest/order" exact component={PublicRoutes.PaymentCard} />
      <PublicRoute path="/paymentSuccess" exact component={PublicRoutes.PaymentSuccess} />
      <PublicRoute path="/paymentFailure" exact component={PublicRoutes.PaymentFailure} />
      <PublicRoute path="/documents" exact component={PublicRoutes.Documents} />
      <PublicRoute path="/new-branch" exact component={PublicRoutes.CreateBranch} />
      <Cabinet path="/" />
      <Redirect to="/404" />
    </Switch>
  );
};

export default Routes;
