import {
  getEmployees,
  createEmployee,
  editEmployee,
  getOneEmployee,
  deleteEmployee,
  getEmployeeCompanies
} from 'api/employees.api';
import history from 'utils/history';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';

import { effects as positionsEffects } from './positions.duck';
import { effects as modalEffects } from './modal.duck';

const parseUnprocessableEntityError = error => {
  let errorName = '';
  let errorMessage = error.message

  switch (error.name) {
    case 'telegram_id': errorName = 'Телеграм ID: '; errorMessage = errorMessage.toLowerCase(); break;
    case 'companies': errorName = ''; break;
    case 'email': errorName = 'Email: '; errorMessage = errorMessage.toLowerCase(); break;
    case 'role_id': errorName = ''; break;
    default: errorMessage = 'Произошла ошибка, попробуйте позже';
  }
  toast.error(`${errorName}${errorMessage}`)
}

const initialState = {
  employees: [],
  employeesCount: 0,
  employee: {},
  errorMessage: '',
  companies: [],
  loading: false,
  created: false,
}

export const options = {
  name: 'employees',
  initialState,
  actions: {
    getEmployeesRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getEmployeesSuccess: ({ items, count }) => state => ({
      ...state,
      loading: false,
      employees: items,
      employeesCount: count,
    }),
    getEmployeesFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    //
    getEmployeeCompaniesRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getEmployeeCompaniesSuccess: data => state => ({
      ...state,
      loading: false,
      companies: data,
    }),
    getEmployeeCompaniesFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    //
    createEmployeesRequest: () => state => ({
      ...state,
      loading: true,
      created: false,
    }),
    createEmployeesSuccess: ({ data }) => state => ({
      ...state,
      loading: false,
      created: true,
      employees: [...state.employees, data],
    }),
    createEmployeesFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message || '',
      loading: false,
      created: false,
    }),
    createEmployeesReset: () => state => ({
      ...state,
      errorMessage: '',
      loading: false,
      created: false,
    }),
    editEmployeesRequest: () => state => ({
      ...state,
      loading: true,
      created: false,
    }),
    editEmployeesSuccess: () => state => ({
      ...state,
      loading: false,
      created: true,
    }),
    editEmployeesFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message || '',
      loading: false,
      created: false,
    }),
    editEmployeesReset: () => state => ({
      ...state,
      created: false,
    }),
    getOneEmployeeRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getOneEmployeeSuccess: ({ data }) => state => ({
      ...state,
      loading: false,
      employee: data,
    }),
    getOneEmployeeFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    deleteEmployeeRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteEmployeeSuccess: id => state => ({
      ...state,
      loading: false,
      employees: state.employees.filter(employee => employee.id !== id)
    }),
    deleteEmployeeFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    saveEmployeelocally: (employee) => state => ({
      ...state,
      employee,
    }),
    resetEmployeesState: () => () => initialState,
  },
  effects: {
    doGetEmployees: (params) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getEmployeesRequest());
      try {
        const response = await getEmployees(params);
        dispatch(duckActions.getEmployeesSuccess(response));
      } catch (error) {
        dispatch(duckActions.getEmployeesFailure(error));
      }
    },
    createEmployee: ({ updateData = true, ...data }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.createEmployeesRequest());
      try {
        let { positionId } = data;
        const { isNewCompany } = data;

        if (positionId && !Number.isInteger(positionId)) {
          const positionResponse = await dispatch(
            positionsEffects.createPosition({ name: positionId })
          );
          if (positionResponse) positionId = positionResponse.id;
        }

        const { data: responseData } = await createEmployee({ ...data, positionId });

        if (responseData.id) {
          dispatch(duckActions.createEmployeesSuccess(responseData));
          toast.success('Сотрудник добавлен');

          if (updateData) {
            await dispatch(duckEffects.doGetEmployees());
          }

          dispatch(duckActions.createEmployeesReset());

          if (!isNewCompany) {
            history.push({ pathname: '/employees', search: `?tab=${data.isTechnicalStaff + 1}` })
          };

          return { success: true, };
        } else throw new Error('Произошла какая-то ошибка');
      } catch (error) {
        if (error.status === 422)
          parseUnprocessableEntityError(error)
        else if (error.status === 403)
          toast.error(error.message)
        dispatch(duckActions.createEmployeesFailure(error));
      }
    },
    editEmployee: ({ id, updateData = true, ...data }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.editEmployeesRequest());
      try {
        let { positionId } = data;
        const { isNewCompany } = data;
        if (positionId && !Number.isInteger(positionId)) {
          const positionResponse = await dispatch(
            positionsEffects.createPosition({ name: positionId })
          );
          if (positionResponse) positionId = positionResponse.id;
        }
        const response = await editEmployee(id, { ...data, positionId });
        dispatch(duckActions.editEmployeesSuccess(response));
        toast.success('Изменения сохранены');
        if (updateData) {
          await dispatch(duckEffects.doGetEmployees());
          await dispatch(duckEffects.getOneEmployee(id));
        }
        dispatch(duckActions.editEmployeesReset());
        if (!isNewCompany) {
          history.push({ pathname: '/employees', search: `?tab=${data.isTechnicalStaff + 1}` });
        }
      } catch (error) {
        if (error.status === 422)
          parseUnprocessableEntityError(error)
        else if (error.status === 403)
          toast.error(error.message)
        dispatch(duckActions.editEmployeesFailure(error));
      }
    },
    getEmployeeCompanies: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getEmployeeCompaniesRequest());
      try {
        const response = await getEmployeeCompanies(id);
        dispatch(duckActions.getEmployeeCompaniesSuccess(response.data))
      } catch (error) {
        dispatch(duckActions.getEmployeeCompaniesFailure(error))
      }
    },
    getOneEmployee: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getOneEmployeeSuccess({}));
      dispatch(duckActions.getOneEmployeeRequest());
      try {
        const response = await getOneEmployee(id);
        dispatch(duckActions.getOneEmployeeSuccess(response));
      } catch (error) {
        dispatch(duckActions.getOneEmployeeFailure(error));
      }
    },
    deleteEmployee: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deleteEmployeeRequest());
      try {
        const response = await deleteEmployee(id);
        if (response.success) {
          dispatch(duckActions.deleteEmployeeSuccess(id));
          toast.success('Сотрудник перемещен в корзину');
          dispatch(modalEffects.closeModal());
        }
      } catch (error) {
        dispatch(duckActions.deleteEmployeeFailure(error));
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
    isCreated: (getState, createSelector) => createSelector([getState], s => s.created),
    getEmployees: (getState, createSelector) => createSelector([getState], s => s.employees),
    getEmployeesCount: (getState, createSelector) => createSelector([getState], s => s.employeesCount),
    getEmployeeCompanies: (getState, createSelector) => createSelector([getState], s => s.companies),
    getOneEmployee: (getState, createSelector) => createSelector([getState], s => s.employee),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
