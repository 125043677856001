import { createDuck } from './utils/createDuck';

const initialState = {
  type: '',
  data: {},
  content: {},
  actions: {
    onSubmit: null,
    onClose: null,
  },
  contentRef: null,
};

export const options = {
  name: 'modal',
  initialState,
  actions: {
    openModal: payload => state => ({
      ...state,
      ...payload,
      isOpen: true,
    }),
    closeModal: () => () => initialState,
    setContentRef: (contentRef) => (state) => ({
      ...state,
      contentRef
    })
  },
  effects: {
    openModal: payload => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.openModal(payload));
    },
    closeModal: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.closeModal());
    },
  },
  selectors: {
    getModal: (getState, createSelector) => createSelector([getState], s => s),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
