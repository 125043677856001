import React, { useState } from 'react';
import { getCookie, createCookie } from './cookie'
import styled from 'styled-components';
import { media } from 'styles';


const CookiesRequest = () => {
    const [isAccept, setAccept] = useState(getCookie('DIGITAL_CLEANING_COOKIE_KEY'))
    if (isAccept) {
        return null;
    }

    const handleSetCookie = () => {
        // const days = parseInt(ttl, 10) / 60 / 24;
        setAccept(true)
        createCookie('DIGITAL_CLEANING_COOKIE_KEY', true, 365)
    }

    return (
        <CookiesRequestWrapper>
            <h1>ПРИМЕНЕНИЕ ФАЙЛОВ COOKIE</h1>
            <CookieBlock>
                <p>
                    Мы используем файлы cookie чтобы сделать наш веб-сайт максимально удобным для Вас. <StyledLink href="/documents?termsOfTheService" target="_blank"> Узнать больше</StyledLink>
                </p>
                <button type='button' onClick={() => handleSetCookie()} >
                    Принять
                </button>
            </CookieBlock>
        </CookiesRequestWrapper>
    );
}

export default CookiesRequest


const CookiesRequestWrapper = styled.div`
    background: ${({ theme }) => theme.color.littleBoyBlue};
    position: fixed;
    width: 100%;
    padding: 25px 50px 25px 25px;
    color: #FFFFFF;
    left: 0;
    bottom: 0;
    z-index: 9999;
    height: auto;
    border-radius: 12px;

    ${media.SM_DOWN} { 
        padding: 12px;
        font-size: 12px;
        bottom: initial;
        top: 0;
        & h1 {
            font-size: 14px;
        }
    }
`

const CookieBlock = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    align-items: center;

    & button {
        border: 1px solid #FFFFFF;
        padding: 10px 25px;
        font-size: 18px;
    }
`


const StyledLink = styled.a`
  font-size: weight;
  border-bottom: 1px solid ${({ theme }) => theme.color.white};
`
