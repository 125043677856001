import {
  getSchedule,
  setDayoff,
  resetDayoff,
  editScheduleWeekend,
  editFreelancersWeekend,
  getFreelancersSchedule,
} from 'api/schedule.api';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';

import type {
  ActionCreator,
  EffectCreator,
  SelectorCreator,
  DuckOptions,
} from './utils/createDuck';

export type State = {
  schedule: Array<Object>,
  errorMessage: string,
  loading: boolean,
};

type Actions = {
  getScheduleRequest: ActionCreator<void>,
  getScheduleSuccess: ActionCreator<Object>,
  getScheduleFailure: ActionCreator<{ message: string }>,
  setDayoffRequest: ActionCreator<void>,
  setDayoffSuccess: ActionCreator<Object>,
  setDayoffFailure: ActionCreator<{ message: string }>,
  resetDayoffRequest: ActionCreator<void>,
  resetDayoffSuccess: ActionCreator<Object>,
  resetDayoffFailure: ActionCreator<{ message: string }>,
};

type Effects = {
  getSchedule: EffectCreator<void>,
  setDayoff: EffectCreator<void>,
  resetDayoff: EffectCreator<void>,
  toggleDayoff: EffectCreator<void>,
};

type Selectors = {
  isLoading: SelectorCreator<boolean>,
  getErrorMessage: SelectorCreator<string>,
  getSchedule: SelectorCreator<Array<Object>>,
  getDayoffSchedule: SelectorCreator<Array<Object>>,
};

type Options = DuckOptions<State, Actions, Effects, Selectors>;

export const options: Options = {
  name: 'schedule',
  initialState: {
    schedule: [],
    errorMessage: '',
    loading: false,
    oldGetQuery: {},
  },
  actions: {
    getScheduleRequest: (payload) => state => ({
      ...state,
      oldGetQuery: payload,
      loading: true,
    }),
    getScheduleSuccess: data => state => ({
      ...state,
      loading: false,
      schedule: data,
    }),
    getScheduleFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    setDayoffRequest: () => state => ({
      ...state,
      loading: true,
    }),
    setDayoffSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    setDayoffFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    resetDayoffRequest: () => state => ({
      ...state,
      loading: true,
    }),
    resetDayoffSuccess: () => state => ({
      ...state,
      loading: false,
    }),
    resetDayoffFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
  },
  effects: {
    getSchedule: payload => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getScheduleRequest(payload));
      try {
        const { data } = await getSchedule(payload);
        dispatch(duckActions.getScheduleSuccess(data));
      } catch (error) {
        dispatch(duckActions.getScheduleFailure(error));
      }
    },
    getFreelancersSchedule: payload => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getScheduleRequest(payload));
      try {
        const { data } = await getFreelancersSchedule(payload);
        dispatch(duckActions.getScheduleSuccess(data));
      } catch (error) {
        dispatch(duckActions.getScheduleFailure(error));
      }
    },

    editScheduleWeekend: (props) => async (dispatch,getState,duckActions,duckEffects) => {
      dispatch(duckActions.setDayoffRequest());
      try {
        const { data } = await editScheduleWeekend(props.id,props.data);
        toast.success('Время установлено');
        dispatch(duckActions.setDayoffSuccess(data));
        await dispatch(duckEffects.getSchedule(getState().schedule.oldGetQuery));
        
      } catch (error) {
        dispatch(duckActions.setDayoffFailure(error))
      }
    },
    editFreelancersWeekend: (props) => async (dispatch,getState,duckActions,duckEffects) => {
      dispatch(duckActions.setDayoffRequest());
      try {
        const { data } = await editFreelancersWeekend(props.id,props.data);
        toast.success('Время установлено');
        dispatch(duckActions.setDayoffSuccess(data));
        await dispatch(duckEffects.getFreelancersSchedule(getState().schedule.oldGetQuery));
        
      } catch (error) {
        dispatch(duckActions.setDayoffFailure(error))
      }
    },

    setDayoff: ({ getQuery, ...payload }) => async (
      dispatch,
      getState,
      duckActions,
      duckEffects
    ) => {
      dispatch(duckActions.setDayoffRequest());
      try {
        const { data } = await setDayoff(payload);
        dispatch(duckActions.setDayoffSuccess(data));
        await dispatch(duckEffects.getSchedule(getQuery));
      } catch (error) {
        dispatch(duckActions.setDayoffFailure(error));
      }
    },

    resetDayoff: ({ getQuery, ...payload }) => async (
      dispatch,
      getState,
      duckActions,
      duckEffects
    ) => {
      dispatch(duckActions.resetDayoffRequest());
      try {
        const { data } = await resetDayoff(payload);
        dispatch(duckActions.resetDayoffSuccess(data));
        await dispatch(duckEffects.getSchedule(getQuery));
      } catch (error) {
        dispatch(duckActions.resetDayoffFailure(error));
      }
    },

    toggleDayoff: ({ isReset, ...data }) => async (
      dispatch,
      getState,
      duckActions,
      duckEffects
    ) => {
      if (isReset) {
        dispatch(duckEffects.resetDayoff(data));
      } else {
        dispatch(duckEffects.setDayoff(data));
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
    getSchedule: (getState, createSelector) => createSelector([getState], s => s.schedule),
    getDayoffSchedule: (getState, createSelector) =>
      createSelector([getState], s => {
        const formattedSchedule = [];
        s.schedule.forEach(({ user, userId, date, hoursCount, activityId, activityName }) => {
          const userCell = formattedSchedule.find(el => el.userId === userId);
          const newDay = {
            date,
            hoursCount,
            activityId,
            activityName,
          };
          if (userCell) {
            userCell.days.push(newDay);
          } else {
            formattedSchedule.push({
              userId,
              user,
              days: [newDay],
            });
          }
        });
        return formattedSchedule;
      }),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
