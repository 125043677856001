import { NODE_ENV } from 'constants';

const LOCAL_HOST = 'localhost';
const LOCAL_ALIASES = [LOCAL_HOST, '127.0.0.1'];

const getCookieDomain = (host) => {
  if (NODE_ENV === 'development' && host === '192.168.0.200') {
    return host;
  }

  if (LOCAL_ALIASES.includes(host)) {
    return LOCAL_HOST;
  }

  return `.${host}`;
};

export default getCookieDomain;
