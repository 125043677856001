import { account } from 'utils/axiosInstances';
import { camelize, snakeify } from 'utils/objectUtilities';
import { toast } from 'react-toastify';

const URL = '/notification-templates';

class UnprocessableEntityError extends Error {
  constructor(params) {
    super(params.message);
    this.name = 'Unprocessable';
    this.notificationId = params.notificationId;
    this.userId = params.userId;
    this.status = 422;
  }
}

class ForbiddenError extends Error {
  constructor(message) {
    super(message);
    this.name = 'Forbidden';
    this.status = 403;
  }
}

const parseNotificationsError = (error, payload = { notificationTemplates: [] },) => {
  if (error.status === 422) {
    const { errors } = error.data;
    const errorsNames = Object.keys(errors);
    const [notificationIndex, userIndex] = errorsNames[0].match(/\.[0-9]+/gi);
    const errorNotification = payload.notificationTemplates[Number(notificationIndex.replace('.', ''))];
    const errorNotificationUserId = errorNotification?.push_users[Number(userIndex.replace('.', ''))];

    throw new UnprocessableEntityError({
      message: errors ? errors[errorsNames[0]][0] : 'Произошла ошибка, попробуйте снова',
      notificationId: Number(errorNotification.id),
      userId: errorNotificationUserId,
    })
  }
  else if (error.status === 403) {
    throw new ForbiddenError(error.message || 'Недостаточно прав')
  }
  else throw new Error(error.message, error.status)
}

export const getNotifications = async () => {
  try {
    const response = await account.get(URL);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    if (response.status === 403) {
      toast.warning('Нет разрешения у вашей роли')
    }
    throw new Error(response.message, response.status);
  }
};

export const editNotifications = async payload => {
  try {
    const response = await account.put(URL, snakeify(payload));
    return camelize(response.data, true);
  } catch ({ response }) {
    parseNotificationsError(response, payload,);
    throw response.data;
  }
};

export const setNotificationsNobody = async () => {
  try {
    const response = await account.post(`${URL}/set-nobody`, {});
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    if (response.status === 403) {
      toast.warning('Нет разрешения у вашей роли')
    }
    throw new Error(response.message, response.status);
  }
};
