import { account } from 'utils/axiosInstances';
import { snakeify, camelize } from 'utils/objectUtilities';

const URL = '/legal-entities';

class OptionsError extends Error {
  constructor(message) {
    super(message);
    this.name = "OptionsError";
  }
}
class ContractError extends Error {
  constructor(propertyName) {
    super('Ошибка договора');
    this.name = 'ContractError';
    this.propertyName = propertyName;
  }
}
class AddressError extends Error {
  constructor(propertyName) {
    super('Ошибка адреса');
    this.name = 'AddressError';
    this.propertyName = propertyName;
  }
}

const parseErrors = (errorResponse = {}) => {
  const { errors, } = errorResponse.data || {};

  if (errors) {
    const keyNames = Object.keys(errors);
    const firstKey = keyNames[0];
    if (firstKey.includes('contract_data'))
      throw new ContractError(keyNames[0].split('.')[1])
    else if (firstKey === 'options')
      throw new OptionsError(errors[firstKey][0])
    else if (firstKey.includes('address'))
      throw new AddressError(keyNames[0].split('.')[1])
    else throw new Error(errors[firstKey][0])
  } else {
    const message = errorResponse.data?.message || errorResponse.message;
    throw new Error(message || 'Произошла какая-то ошибка, попробуйте позже');
  }
}

export const getEntities = async () => {
  try {
    const response = await account.get(URL);

    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const getOneEntity = async id => {
  try {
    const { data } = await account.get(`${URL}/${id}`);
    return camelize(data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const createEntity = async (data, documentOn = false) => {
  try {
    const response = await account.post(`${URL}/?document_on=${Number(documentOn)}`, snakeify(data, true));

    return camelize(response.data, true);
  } catch ({ response }) {
    parseErrors(response);
  }
};

export const deleteEntity = async id => {
  try {
    const response = await account.delete(`${URL}/${id}`);

    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const updateEntity = async (data, documentOn = false) => {
  try {
    const response = await account.put(`${URL}/${data.id}/?document_on=${Number(documentOn)}`, snakeify(data, true));

    return camelize(response.data, true);
  } catch ({ response }) {
    parseErrors(response);
  }
};
