import React from 'react';
import styled from 'styled-components';
// Styles
import { media } from 'styles';
// Components
import { Link } from 'react-router-dom';
import Icon from 'components/UI/Icon';

export default function LogotypeComponent({
  className,
  toggleOpened,
  company = {},
  toggleOpenedBranches,
  branchesTariffPerm
}) {

  return (
    <Logotype className={className}>
      <LogotypeLinkContainer>
        <LogotypeLink to="/">ВОЛСТАР</LogotypeLink>
        <LogotypeBurger onClick={toggleOpened}>
          <LogotypeIcon icon="mi-close" />
        </LogotypeBurger>
      </LogotypeLinkContainer>
      <ToggleBranchesButton onClick={toggleOpenedBranches} type="button" disabled={!branchesTariffPerm}>
        <LogotypeBorder style={{ padding: '8px 0' }}>
          <p>{company.name || ''}</p>
        </LogotypeBorder>
        <LogotypeLinkContainer style={{ padding: '8px 0' }}>
          <p>{company.address?.cityName || ''}</p>
        </LogotypeLinkContainer>
      </ToggleBranchesButton>
    </Logotype>
  );
}

const Logotype = styled.div`
  display: none;
  padding: 21px 21px 0px 26px;

  ${media.MD_DOWN} {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 21px 25px 0px 25px;
  }
`;

const LogotypeLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const LogotypeBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.darkGray};
`;

const LogotypeLink = styled(Link)`
  text-transform: uppercase;
  line-height: 150%;
  font-weight: ${({ theme }) => theme.fontWeight.BOLD};
  font-size: ${({ theme }) => theme.fontSize.L};
  white-space: nowrap;
`;

const LogotypeBurger = styled.button`
  display: none;
  justify-self: center;
  ${media.MD_DOWN} {
    display: block;
  }
`;

const LogotypeIcon = styled(Icon)`
  font-size: ${({ theme }) => theme.fontSize.XL};
  padding: 5px;
`;

const ToggleBranchesButton = styled.button`
  width: 100%;

  &:hover {
    background-color: ${({ theme, disabled }) => disabled ? 'inherit' : theme.color.sashaGray};
  }
`;
