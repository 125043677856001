import {
  getPurchaseOrder,
  createSupply,
  editSupply,
  deleteSupply,
  updateReceivers,
  deletePurchaseOrder,
  editTechnologySupplyCost,
  recalcPurchaseOrder,
} from 'api/purchase.api';
import { toast } from 'react-toastify';
import { effects as modalEffects } from './modal.duck';
import { effects as ordersEffects } from './orders.duck';
import { createDuck } from './utils/createDuck';

export const options = {
  name: 'purchase',
  initialState: {
    purchaseOrder: {},
    supplyPercents: {},
    isLoading: false,
    errorMessage: ''
  },
  actions: {
    getPurchaseOrderRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getPurchaseOrderSuccess: ({ data: purchaseOrder }) => state => ({
      ...state,
      purchaseOrder,
      isLoading: false,
    }),
    getPurchaseOrderFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    createSupplyRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    createSupplySuccess: supply => state => ({
      ...state,
      purchaseOrder: {
        ...state.purchaseOrder,
        purchaseOrderItems: [
          ...state.purchaseOrder.purchaseOrderItems,
          supply
        ]
      },
      isLoading: false,
    }),
    createSupplyFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    editSupplyRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    editSupplySuccess: supply => state => ({
      ...state,
      purchaseOrder: {
        ...state.purchaseOrder,
        purchaseOrderItems: state.purchaseOrder.purchaseOrderItems.reduce((prev, item) => [
          ...prev,
          item.id === supply.id ? { ...item, ...supply } : item,
        ], [])
      },
      isLoading: false,
    }),
    editSupplyFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    updateReceiversRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    updateReceiversSuccess: receiversObj => state => ({
      ...state,
      purchaseOrder: {
        ...state.purchaseOrder,
        ...receiversObj
      },
      isLoading: false,
    }),
    updateReceiversFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    deleteSupplyRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    deleteSupplySuccess: id => state => ({
      ...state,
      purchaseOrder: {
        ...state.purchaseOrder,
        purchaseOrderItems: state.purchaseOrder.purchaseOrderItems.filter(item => item.id !== id)
      },
      isLoading: false,
    }),
    deleteSupplyFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    deletePurchaseOrderRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    deletePurchaseOrderSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    deletePurchaseOrderFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    technologySupplyRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    technologySupplySuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    technologySupplyFailure: () => state => ({
      ...state,
      isLoading: false,
    }),
    setSupplyPercents: data => state => ({
      ...state,
      supplyPercents: data
    }),

    recalcPurchaseOrderRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    recalcPurchaseOrderSuccess: ({ data: purchaseOrder }) => state => ({
      ...state,
      purchaseOrder,
      isLoading: false,
    }),
    recalcPurchaseOrderFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
  },
  effects: {
    getPurchaseOrder: ({ orderId, openDepreciationModalHandler }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getPurchaseOrderRequest());
      try {
        const response = await getPurchaseOrder(orderId);
        dispatch(duckActions.getPurchaseOrderSuccess(response));

        if (openDepreciationModalHandler) openDepreciationModalHandler(response);
      } catch (error) {
        dispatch(duckActions.getPurchaseOrderFailure(error));
      }
    },
    createSupply: purschaseObj => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.createSupplyRequest());
      try {
        const { data } = await createSupply(purschaseObj);
        dispatch(duckActions.createSupplySuccess(data));
        toast.success('Новая позиция добавлена');
      } catch (error) {
        dispatch(duckActions.createSupplyFailure(error));
      }
    },
    editSupply: purchaseObj => async (dispatch, getState, duckActions) => {

      dispatch(duckActions.editSupplyRequest());
      try {
        await editSupply(purchaseObj.id, purchaseObj.data);
        dispatch(duckActions.editSupplySuccess({ id: purchaseObj.id, ...purchaseObj.data }));
        toast.success('Позиция изменена');
      } catch (error) {
        dispatch(duckActions.editSupplyFailure(error));
      }
    },
    updateReceivers: ({ id, receiversObj }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.updateReceiversRequest());
      try {
        await updateReceivers(id, {
          objectReceiverId: receiversObj.objectReceiver.id || null,
          storageReceiverId: receiversObj.storageReceiver.id || null
        });
        dispatch(duckActions.updateReceiversSuccess(receiversObj));
      } catch (error) {
        dispatch(duckActions.updateReceiversFailure(error));
      }
    },
    editSupplyQuantity: ({ orderId, purchaseItemId, purchaseObj, receiversObj }) => async (dispatch, getState, duckActions, duckEffects) => {
      await dispatch(duckEffects.editSupply({ id: purchaseItemId, data: purchaseObj }));
      await dispatch(duckEffects.updateReceivers({ id: orderId, receiversObj }));
    },
    deleteSupply: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deleteSupplyRequest());
      try {
        await deleteSupply(id);
        dispatch(duckActions.deleteSupplySuccess(id));
        toast.success('Позиция удалена из заказ-наряда');
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteSupplyFailure(error));
      }
    },
    deletePurchaseOrder: orderId => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.deletePurchaseOrderRequest());
      try {
        await deletePurchaseOrder(orderId);
        dispatch(duckActions.deletePurchaseOrderSuccess());
        toast.success('Заказ-наряд удален');
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deletePurchaseOrderFailure(error));
      }
    },
    recalcPurchaseOrder: ({ orderId, openDepreciationModalHandler }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.recalcPurchaseOrderRequest());
      try {
        const response = await recalcPurchaseOrder(orderId);
        dispatch(duckActions.recalcPurchaseOrderSuccess(response));
        toast.success('Заказ-наряд перерассчитан');
        dispatch(modalEffects.closeModal());

        if (openDepreciationModalHandler) openDepreciationModalHandler(response);
      } catch (error) {
        dispatch(duckActions.recalcPurchaseOrderFailure(error));
      }
    },

    editTechnologySupplyCost: ({ orderId, orderItemId, supplyCost, initSupplyCost, initPercent }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.technologySupplyRequest());
      try {
        await editTechnologySupplyCost(orderItemId, {
          suppliesCost: supplyCost,
          suppliesCostChanged: true
        });
        dispatch(ordersEffects.fetchOneOrder(orderId));
        toast.success('Cумма изменена');

        dispatch(duckEffects.setSupplyPercent({ orderItemId, supplyCost, initSupplyCost, initPercent }))
        dispatch(duckActions.technologySupplySuccess());
      } catch (error) {
        dispatch(duckActions.technologySupplyFailure(error));
      }
    },
    setSupplyPercent: ({ orderItemId, initPercent, initSupplyCost, supplyCost }) => async (dispatch, getState, duckActions, duckEffects) => {
      const supplyPercents = localStorage.getItem('suppliesPercents');
      if (supplyPercents) {
        const supplyPercentsObj = JSON.parse(supplyPercents);
        supplyPercentsObj[orderItemId] = (initPercent * supplyCost / initSupplyCost).toFixed(2);
        localStorage.setItem('suppliesPercents', JSON.stringify(supplyPercentsObj));
      } else
        localStorage.setItem('suppliesPercents',
          JSON.stringify({ [orderItemId]: (initPercent * supplyCost / initSupplyCost).toFixed(2) })
        );
      dispatch(duckEffects.readSupplyPercents());
    },
    readSupplyPercents: () => async (dispatch, getState, duckActions) => {
      const supplyPercents = JSON.parse(localStorage.getItem('suppliesPercents'));
      if (supplyPercents)
        dispatch(duckActions.setSupplyPercents(supplyPercents));
    }
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    getIsLoading: (getState, createSelector) => createSelector([getState], s => s.isLoading),
    getPurchaseOrder: (getState, createSelector) => createSelector([getState], s => s.purchaseOrder),
    getSupplyPercents: (getState, createSelector) => createSelector([getState], s => s.supplyPercents)
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
