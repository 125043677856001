import { account } from 'utils/axiosInstances';
import { camelize, snakeify } from 'utils/objectUtilities';

const URL = '/companies';

export const getBranches = async () => {
  try {
    const response = await account.get(URL);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const createBranch = async payload => {
  try {
    const response = await account.post(URL, snakeify(payload, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const changeBranch = async (id, payload) => {
  try {
    const response = await account.patch(`${URL}/${id}/move`, payload);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const editBranch = async (id, payload) => {
  try {
    const response = await account.put(`${URL}/${id}`, snakeify(payload, true));
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const deleteBranch = async (id, payload) => {
  try {
    const response = await account.delete(`${URL}/${id}`, snakeify({ data: payload }, true));
    return response.data;
  } catch (error) {
    const { response } = error;
    return response
  }
}

export const getBranch = async (id) => {
  try {
    const response = await account.get(`${URL}/${id}`);
    return camelize(response.data, true);
  } catch (error) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};
