export const reduceMarginReportValues = report => [
  {
    plan: report.plan.income,
    fact: report.fact.income,
    calcResult: function() {
      return Number(this.fact) - Number(this.plan)
    },
    editable: true,
    fieldName: 'income',
    bigArticle: true,
    name: 'Выручка',
    id: 1,
  },
  {
    plan: report.plan.strExpenses,
    fact: report.fact.strExpenses,
    calcResult: function() {
      return Number(this.plan) - Number(this.fact)
    },
    bigArticle: true,
    name: 'Прямые затраты',
    id: 2,
  },
  {
    plan: report.plan.strTotalSalary,
    fact: report.fact.strTotalSalary,
    calcResult: function() {
      return Number(this.plan) - Number(this.fact)
    },
    name: 'Зарплата',
    id: 3,
  },
  {
    plan: report.plan.strStaffSalary,
    fact: report.fact.strStaffSalary,
    calcResult: function() {
      return Number(this.plan) - Number(this.fact)
    },
    subArticle: true,
    editable: true,
    fieldName: 'strStaffSalary',
    name: 'Зарплата сотрудников',
    id: 4,
  },
  {
    plan: report.plan.strAdditionalStaffSalary,
    fact: report.fact.strAdditionalStaffSalary,
    calcResult: function() {
      return Number(this.plan) - Number(this.fact)
    },
    subArticle: true,
    editable: true,
    fieldName: 'strAdditionalStaffSalary',
    name: 'Зарплата доп. сотрудников',
    id: 5,
  },
  {
    plan: report.plan.strTaxForSalary,
    fact: report.fact.strTaxForSalary,
    calcResult: function() {
      return Number(this.plan) - Number(this.fact)
    },
    name: 'Налоги на ЗП',
    editable: true,
    fieldName: 'strTaxForSalary',
    id: 6,
  },
  {
    plan: report.plan.strConsumableDetergent,
    fact: report.fact.strConsumableDetergent,
    calcResult: function() {
      return Number(this.plan) - Number(this.fact)
    },
    name: 'Расходные материалы и моющие средства',
    id: 7,
  },
  {
    plan: report.plan.strContractors,
    fact: report.fact.strContractors,
    calcResult: function() {
      return Number(this.plan) - Number(this.fact)
    },
    name: 'Подрядчики',
    editable: true,
    fieldName: 'strContractors',
    id: 8,
  },
  {
    plan: report.plan.strTaxSum,
    fact: report.fact.strTaxSum,
    calcResult: function() {
      return Number(this.plan) - Number(this.fact)
    },
    name: 'Налог (НДС, ЕНВД)',
    id: 9,
  },
  {
    plan: report.plan.grossProfit,
    fact: report.fact.grossProfit,
    calcResult: function() {
      return Number(this.fact) - Number(this.plan)
    },
    bigArticle: true,
    name: 'Валовая прибыль',
    id: 10,
  },
  {
    plan: report.plan.profitabilityPercent,
    fact: report.fact.profitabilityPercent,
    bigArticle: true,
    suffix: ' %',
    name: 'Рентабельность 1, %',
    id: 11,
  },
  {
    plan: report.plan.indirectCosts,
    fact: report.fact.indirectCosts,
    calcResult: function() {
      return Number(this.plan) - Number(this.fact)
    },
    bigArticle: true,
    name: 'Косвенные затраты',
    id: 12,
  },
  {
    plan: report.plan.indTotalSalary,
    fact: report.fact.indTotalSalary,
    calcResult: function() {
      return Number(this.plan) - Number(this.fact)
    },
    name: 'Зарплата',
    id: 13,
  },
  {
    plan: report.plan.indHeadSalary,
    fact: report.fact.indHeadSalary,
    calcResult: function() {
      return Number(this.plan) - Number(this.fact)
    },
    subArticle: true,
    name: 'Зарплата руководителя бригады',
    id: 14,
  },
  {
    plan: report.plan.indDriverSalary,
    fact: report.fact.indDriverSalary,
    calcResult: function() {
      return Number(this.plan) - Number(this.fact)
    },
    subArticle: true,
    name: 'Зарплата водителя',
    editable: true,
    fieldName: 'indDriverSalary',
    id: 15,
  },
  {
    plan: report.plan.indCoveralls,
    fact: report.fact.indCoveralls,
    calcResult: function() {
      return Number(this.plan) - Number(this.fact)
    },
    name: 'Спецодежда',
    id: 16,
  },
  {
    plan: report.plan.indInventory,
    fact: report.fact.indInventory,
    calcResult: function() {
      return Number(this.plan) - Number(this.fact)
    },
    name: 'Инвентарь',
    id: 17,
  },
  {
    plan: report.plan.indEquipment,
    fact: report.fact.indEquipment,
    calcResult: function() {
      return Number(this.plan) - Number(this.fact)
    },
    name: 'Оборудование',
    id: 18,
  },
  {
    plan: report.plan.indFuelExpenses,
    fact: report.fact.indFuelExpenses,
    calcResult: function() {
      return Number(this.plan) - Number(this.fact)
    },
    name: 'Транспортные расходы',
    editable: true,
    fieldName: 'indFuelExpenses',
    id: 19,
  },
  {
    plan: report.plan.marginalIncome,
    fact: report.fact.marginalIncome,
    calcResult: function() {
      return Number(this.fact) - Number(this.plan)
    },
    bigArticle: true,
    name: 'Маржинальный доход',
    id: 20,
  },
  {
    plan: report.plan.totalProfitabilityPercent,
    fact: report.fact.totalProfitabilityPercent,
    bigArticle: true,
    suffix: ' %',
    name: 'Рентабельность общая, %',
    id: 21,
  },
];