// Api
import {
  fetchCategories,
  createCategory,
  editCategory,
  deleteCategory,
  createFileToBase64,
  createFile,
  editFile,
  deleteFile,
  editFileName,
} from 'api/knowledge.api';
import { getFile, } from 'api/downloadFile.api';
import { createTag, } from 'api/tags.api';
// Helpers
import { toBase64 } from 'utils/data/toBase64';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';
// Ducks
import { effects as modalEffects } from './modal.duck';
import { effects as tagsEffects } from './tags.duck';


export const options = {
  name: 'knowledge',
  initialState: {
    categories: [],
    volume: 0,
    search: null,
    errorMessage: '',
    isLoading: false,
    fileSizeLimit: 150,
  },
  actions: {
    // Get categories
    fetchCategoriesRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    fetchCategoriesSuccess: ({ items: categories, volume }) => state => ({
      ...state,
      isLoading: false,
      categories,
      volume,
    }),
    fetchCategoriesFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    // Create categories
    createCategoryRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    createCategorySuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    createCategoryFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    // Update categories
    editCategoryRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    editCategorySuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    editCategoryFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    // Delete categories
    deleteCategoryRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    deleteCategorySuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    deleteCategoryFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    // Create files
    createFileToBase64Request: () => state => ({
      ...state,
      isLoading: true,
    }),
    createFileToBase64Success: () => state => ({
      ...state,
      isLoading: false,
    }),
    createFileToBase64Failure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    createFileRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    createFileSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    createFileFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    // Update files
    editFileRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    editFileSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    editFileFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    // Delete files
    deleteFileRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    deleteFileSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    deleteFileFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    // Move files folders
    moveItemsRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    moveItemsSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    moveItemsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    // Search
    setSearch: search => state => ({
      ...state,
      search,
    }),
    createTagsRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    createTagsSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    createTagsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    setFileTagsRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    setFileTagsSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    setFileTagsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    deleteManyFilesRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    deleteManyFilesSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    deleteManyFilesFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false
    }),
    getFileRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getFileSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    getFileFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
  },
  effects: {
    // Categories
    fetchCategories: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchCategoriesRequest());
      try {
        const data = await fetchCategories();
        dispatch(duckActions.fetchCategoriesSuccess(data));
      } catch (error) {
        dispatch(duckActions.fetchCategoriesFailure(error));
      }
    },
    createCategory: payload => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.createCategoryRequest());
      try {
        const data = await createCategory(payload);
        dispatch(duckActions.createCategorySuccess(data));
        toast.success('Папка создана');
        await dispatch(duckEffects.fetchCategories());
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.createCategoryFailure(error));
      }
    },
    editCategory: ({ id, ...payload }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.editCategoryRequest());
      try {
        const data = await editCategory(id, payload);
        dispatch(duckActions.editCategorySuccess(data));
        toast.success('Изменения сохранены');
        await dispatch(duckEffects.fetchCategories());
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.editCategoryFailure(error));
      }
    },
    deleteCategory: ({ id }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteCategoryRequest());
      try {
        const data = await deleteCategory(id);
        dispatch(duckActions.deleteCategorySuccess(data));
        toast.success('Папка удалена');
        await dispatch(duckEffects.fetchCategories());
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteCategoryFailure(error));
      }
    },
    // Files
    createTags: formTagIds => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.createTagsRequest());
      try {
        const existTagIds = formTagIds.filter(({ id }) => Number.isInteger(id)).map(({ id }) => id);
        const promises = formTagIds
          .filter(({ id }) => !Number.isInteger(id))
          .map(async ({ name }) => {
            const { data } = await createTag({ name });
            return data;
          });
        const promiseResponse = await Promise.all(promises);
        const newTagIds = promiseResponse.map(({ id }) => id);
        if (newTagIds.length)
          await dispatch(tagsEffects.fetchTags());

        dispatch(duckActions.createTagsSuccess());
        return [...existTagIds, ...newTagIds];
      } catch (error) {
        dispatch(duckActions.createTagsFailure(error));
      }
    },
    createFileToBase64: ({ categoryId, ...payload }) => async (
      dispatch,
      getState,
      duckActions,
      duckEffects
    ) => {
      dispatch(duckActions.createFileToBase64Request());
      try {
        const { name: formName, content: formContent, extension, tag_ids: formTagIds } = payload;
        const tag_ids = await dispatch(duckEffects.createTags(formTagIds));
        const content = await toBase64(formContent);
        const name = `${formName}.${extension}`;
        const finalData = { ...payload, name, content, tag_ids };
        const data = await createFileToBase64(categoryId, {
          new_files: [finalData],
        });
        dispatch(duckActions.createFileToBase64Success(data));
        toast.success('Файл загружен');
        await dispatch(tagsEffects.fetchTags());
        await dispatch(duckEffects.fetchCategories());
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.createFileToBase64Failure(error));
      }
    },
    setFileTags: ({ categoryId, fileId, data, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.setFileTagsRequest());
      try {
        await editFile(categoryId, fileId, data);
        dispatch(duckActions.setFileTagsSuccess());
      } catch (error) {
        dispatch(duckActions.setFileTagsFailure(error));
      }
    },
    createFile: ({ categoryId, payload, }) => async (dispatch, getState, duckActions, duckEffects) => {
      const { tag_ids: formTagIds, } = payload;
      dispatch(duckActions.createFileRequest());
      try {
        const { uploaded, oversized, failed } = await createFile(categoryId, payload);
        dispatch(duckActions.createFileSuccess());

        if (oversized.length)
          toast.warn('Размер файла слишком велик!');
        else if (failed.length)
          toast.warn('Произошла какая-то ошибка, попробуйте снова');
        else {
          const tag_ids = await dispatch(duckEffects.createTags(formTagIds));
          await dispatch(duckEffects.setFileTags({ categoryId, fileId: uploaded[0].id, data: { tag_ids } }));
          await dispatch(duckEffects.fetchCategories());
          dispatch(modalEffects.closeModal());
          toast.success('Файл загружен');
        }
      } catch (error) {
        dispatch(duckActions.createFileFailure(error));
        toast.error('Произошла ошибка, попробуйте снова');
      }
    },
    editFile: ({ categoryId, fileId, ...payload }) => async (
      dispatch,
      getState,
      duckActions,
      duckEffects
    ) => {
      dispatch(duckActions.editFileRequest());
      try {
        const { name: formName, content: formContent, tag_ids: formTagIds } = payload;
        const tag_ids = formTagIds ? await dispatch(duckEffects.createTags(formTagIds)) : [];
        const { extension } = formContent;
        const extensionInName = formName.match(/\.[0-9a-z]+$/i);
        const name =
          extensionInName && extensionInName.length > 0 ? formName : `${formName}.${extension}`;
        const finalData = { name, tag_ids };

        await editFileName(categoryId, fileId, name.replace(`.${extension}`, ''));
        const data = await editFile(categoryId, fileId, finalData);
        dispatch(duckActions.editFileSuccess(data));
        toast.success('Изменения сохранены');
        await dispatch(tagsEffects.fetchTags());
        await dispatch(duckEffects.fetchCategories());
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.editFileFailure(error));
      }
    },
    deleteFile: ({ categoryId, id }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteFileRequest());
      try {
        const data = await deleteFile(categoryId, id);
        dispatch(duckActions.deleteFileSuccess(data));
        toast.success('Документ удален');
        await dispatch(duckEffects.fetchCategories());
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteFileFailure(error));
      }
    },
    deleteManyFiles: ({ categoryId, items }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteManyFilesRequest());
      try {
        for (let i = 0; i < items.length; i += 1) {
          if (items[i].isCategory)
            await deleteCategory(items[i].id);
          else
            await deleteFile(categoryId, items[i].id)
        }
        dispatch(duckActions.deleteManyFilesSuccess());
        toast.success('Папки/документы удалены');
        await dispatch(duckEffects.fetchCategories());
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.deleteManyFilesFailure(error));
      }
    },
    getFile: ({ categoryId, fileId, convertToFile, downloadFile = false, filename, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getFileRequest());
      try {
        const response = await getFile(categoryId, fileId, convertToFile);
        dispatch(duckActions.getFileSuccess());

        if (downloadFile && response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        } else {
          return response;
        }
      } catch (error) {
        toast.warn(error.message);
        dispatch(duckActions.getFileFailure(error));
      }
    },
    // Move files folders
    moveItems: ({ items }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.moveItemsRequest());
      try {
        const promises = items.map(el => {
          const { categoryId, id, ...rest } = el;
          if (el.isCategory) return editCategory(id, rest);
          return editFile(categoryId, id, rest);
        });
        const data = await Promise.all(promises);
        dispatch(duckActions.moveItemsSuccess(data));
        toast.success('Изменения сохранены');
        await dispatch(duckEffects.fetchCategories());
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.moveItemsFailure(error));
      }
    },
    // Search
    setSearch: payload => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.setSearch(payload));
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.isLoading),
    getCategories: (getState, createSelector) => createSelector([getState], s => s.categories),
    getSearch: (getState, createSelector) => createSelector([getState], s => s.search),
    getVolume: (getState, createSelector) => createSelector([getState], s => s.volume),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
