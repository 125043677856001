import { fetchContractorTypes, createContractorType } from 'api/contractorTypes.api';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';
// Ducks
import { effects as modalEffects } from './modal.duck';

export const options = {
  name: 'contractorTypes',
  initialState: {
    contractorTypes: [],
    contractor: null,
    errorMessage: '',
    loading: false,
  },
  actions: {
    fetchContractorTypesRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchContractorTypesSuccess: ({ items }) => state => ({
      ...state,
      loading: false,
      contractorTypes: items,
    }),
    fetchContractorTypesFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    createContractorTypeRequest: () => state => ({
      ...state,
      loading: true,
    }),
    createContractorTypeSuccess: contractor => state => ({
      ...state,
      contractor,
      contractorTypes:[...state.contractorTypes,contractor],
      loading: false,
    }),
    removeNewContractor: () => state => ({
      ...state,
      contractor:null
    }),
    createContractorTypeFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
  },
  effects: {
    fetchContractorTypes: params => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchContractorTypesRequest());
      try {
        const response = await fetchContractorTypes(params);
        dispatch(duckActions.fetchContractorTypesSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchContractorTypesFailure(error));
      }
    },
    createContractorType: payload => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.createContractorTypeRequest());
      try {
        const { data } = await createContractorType(payload);
        toast.success('Подрядчик создан.');
        dispatch(duckActions.createContractorTypeSuccess(data));
        await dispatch(duckEffects.fetchContractorTypes());
        dispatch(modalEffects.closeModal());
      } catch (error) {
        dispatch(duckActions.createContractorTypeFailure(error));
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
    getContractorTypes: (getState, createSelector) =>
      createSelector([getState], s => s.contractorTypes),
    getContractor: (getState, createSelector) => createSelector([getState], s => s.contractor),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
