import { login, register, resetPassword, forgotPassword, registerCheck } from 'api/auth.api';
import { allowRoles } from 'api/role.api';
import { setNotificationsNobody } from 'api/notificationsSettings.api';

import { toast } from 'react-toastify';
import { setScheduleDefault } from 'api/schedule.api';
import history from 'utils/history';
import { createCookie, removeCookie } from 'utils/cookie';
import { createDuck } from './utils/createDuck';

const options = {
  name: 'auth',
  initialState: {
    errorMessage: '',
    isLoading: false,
    isChnaged: false,
    isUsingTemplate: true,
    registerValues: {
      email: '',
      password: '',
      phone: '',
      name: '',
      surname: '',
      regionIds: [],
      company: {
        name: '',
        phone: '',
        staffes_count: 0,
        information: {
          info_services: [

          ],
          client_type: '',
          info_roles: [

          ],
          staff_count: 0,
          users_count: 0,
          is_using_template: true
        },
        child_companies_enabled: true
      }
    },
    historyState: [],
    lastDeletedLocation: null,
  },
  actions: {
    loginRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    loginSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    loginFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),
    logoutRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    logoutSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    logoutFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),
    registerRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    registerSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    registerFailure: errors => state => ({
      ...state,
      isLoading: false,
      errorMessage: errors,
    }),
    registerCheckRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    registerCheckSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    registerCheckFailure: errors => state => ({
      ...state,
      isLoading: false,
      errorMessage: errors,
    }),
    resetPasswordRequest: () => state => ({
      ...state,
      isLoading: true,
      isChnaged: false,
    }),
    resetPasswordSuccess: () => state => ({
      ...state,
      isLoading: false,
      isChnaged: true,
    }),
    resetPasswordFailure: errorMessage => state => ({
      ...state,
      isLoading: false,
      isChnaged: false,
      errorMessage,
    }),
    forgotPasswordRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    forgotPasswordSuccess: () => state => ({
      ...state,
      isLoading: false,
      successMessage: 'На ваш почтовый ящик выслана ссылка для восстановления пароля',
      errorMessage: '',
    }),
    forgotPasswordFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),
    setRegisterValue: (registerValues) => state => ({
      ...state,
      registerValues: registerValues
    }),
    allowRolesRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    allowRolesSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    allowRolesFailure: errorMessage => state => ({
      ...state,
      errorMessage
    }),
    scheduleDefaultRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    scheduleDefaultSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    scheduleDefaultFailure: errorMessage => state => ({
      ...state,
      errorMessage
    }),
    setNotificationsNobodyRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    setNotificationsNobodySuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    setNotificationsNobodyFailure: errorMessage => state => ({
      ...state,
      errorMessage
    }),
    setIsUsingTemplate: payload => state => ({
      ...state,
      isUsingTemplate: payload
    }),
    pushHistoryState: pathname => state => ({
      ...state,
      historyState: [...state.historyState, pathname]
    }),
    popHistoryState: () => state => ({
      ...state,
      historyState: state.historyState.slice(0, state.historyState.length - 1),
      lastDeletedLocation: state.historyState[state.historyState.length - 1]
    }),
    clearLastDeletedLocation: () => state => ({
      ...state,
      lastDeletedLocation: null
    })
  },
  effects: {
    login: data => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.loginRequest());
      try {
        const { token, ttl } = await login(data);
        const days = parseInt(ttl, 10) / 60 / 24;
        createCookie(`token`, token, days);
        dispatch(duckActions.loginSuccess());
        history.push('/orders');
      } catch (error) {
        dispatch(duckActions.loginFailure(error));
      }
    },
    logout: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.logoutRequest());
      try {
        removeCookie(`token`);
        dispatch(duckActions.logoutSuccess());
        sessionStorage.clear();
        localStorage.clear();
        history.push('/login');
      } catch (error) {
        dispatch(duckActions.logoutFailure(error));
      }
    },
    register: data => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.registerRequest());
      try {
        const { token, ttl, currentCompanyId } = await register(data);
        const days = parseInt(ttl, 10) / 60 / 24;
        createCookie(`token`, token, days);
        localStorage.setItem('currentCompanyId', currentCompanyId);
        dispatch(duckActions.registerSuccess());
        if (data.company.information.is_using_template) {
          localStorage.setItem('isUsingTemplate', 1)
        } else {
          await dispatch(duckEffects.allowRoles())
          dispatch(duckActions.setIsUsingTemplate(false))
          localStorage.setItem('isUsingTemplate', 0)
        }
      } catch (error) {
        if (error?.phone && error?.phone[0])
          toast.error('Набранный вами номер уже зарегистрирован')

        dispatch(duckActions.registerFailure(error));
      }
    },
    backToReadySolution: (finishRegister = true) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.registerRequest());
      try {
        dispatch(duckActions.registerSuccess());
        await dispatch(duckEffects.allowRoles())
        await dispatch(duckEffects.setNotificationsNobody())
        await dispatch(duckEffects.setScheduleDefault(true))
        if (finishRegister)
          history.push({ pathname: '/orders' });
      } catch (error) {
        dispatch(duckActions.registerFailure(error));
      }
    },
    registerCheck: data => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.registerCheckRequest());
      try {
        await registerCheck(data);
        dispatch(duckActions.registerCheckSuccess());
        history.push({ pathname: '/signup', state: { step: 2 } });
      } catch (error) {
        dispatch(duckActions.registerCheckFailure(error));
      }
    },
    resetPassword: data => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.resetPasswordRequest());
      try {
        const response = await resetPassword(data);
        dispatch(duckActions.resetPasswordSuccess(response));
      } catch ({ message }) {
        dispatch(duckActions.resetPasswordFailure(message));
      }
    },
    forgotPassword: data => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.forgotPasswordRequest());
      try {
        const response = await forgotPassword(data);
        dispatch(duckActions.forgotPasswordSuccess(response));
      } catch (error) {
        dispatch(duckActions.forgotPasswordFailure(error));
      }
    },
    allowRoles: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.allowRolesRequest());
      try {
        await allowRoles();
        dispatch(duckActions.allowRolesSuccess())
      } catch ({ data }) {
        dispatch(duckActions.allowRolesFailure(data));
        toast.error('Ошибка!');
      }
    },
    setScheduleDefault: (isForce = false) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.scheduleDefaultRequest());
      try {
        await setScheduleDefault(isForce);
        dispatch(duckActions.scheduleDefaultSuccess())
      } catch ({ data }) {
        dispatch(duckActions.scheduleDefaultFailure(data));
        toast.error('Ошибка!');
      }
    },
    setNotificationsNobody: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.setNotificationsNobodyRequest());
      try {
        await setNotificationsNobody();
        dispatch(duckActions.setNotificationsNobodySuccess())
      } catch ({ data }) {
        dispatch(duckActions.setNotificationsNobodyFailure(data));
        toast.error('Ошибка!');
      }
    },

  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    getSuccessMessage: (getState, createSelector) =>
      createSelector([getState], s => s.successMessage),
    getIsLoading: (getState, createSelector) => createSelector([getState], s => s.isLoading),
    getIsChnaged: (getState, createSelector) => createSelector([getState], s => s.isChnaged),
    getRegisterValues: (getState, createSelector) => createSelector([getState], s => s.registerValues),
    getIsUsingTemplate: (getState, createSelector) => createSelector([getState], s => s.isUsingTemplate),
    gethistoryState: (getState, createSelector) => createSelector([getState], s => s.historyState),
    getLastDeletedLocation: (getState, createSelector) => createSelector([getState], s => s.lastDeletedLocation),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
