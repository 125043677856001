import { getOrdersSchedule,getOrderSchedule } from 'api/ordersSchedule.api';
import { createDuck } from './utils/createDuck';

import type {
  ActionCreator,
  EffectCreator,
  SelectorCreator,
  DuckOptions,
} from './utils/createDuck';

export type State = {
  schedule: Array<Object>,
  errorMessage: string,
  loading: boolean,
};

type Actions = {
  getScheduleRequest: ActionCreator<void>,
  getScheduleSuccess: ActionCreator<Object>,
  getScheduleFailure: ActionCreator<{ message: string }>,
  getOrderScheduleRequest: ActionCreator<void>,
  getOrderScheduleSuccess: ActionCreator<Object>,
  getOrderScheduleFailure: ActionCreator<{ message: string }>,
};

type Effects = {
  getSchedule: EffectCreator<void>,
  getOrderSchedule: EffectCreator<void>,
};

type Selectors = {
  isLoading: SelectorCreator<boolean>,
  getErrorMessage: SelectorCreator<string>,
  getSchedule: SelectorCreator<Array<Object>>,
  getOrderSchedule: SelectorCreator<void>
};

type Options = DuckOptions<State, Actions, Effects, Selectors>;

export const options: Options = {
  name: 'ordersSchedule',
  initialState: {
    schedule: [],
    errorMessage: '',
    loading: false,
    orderSchedule: [],
    schedulePayload: {},
  },
  actions: {
    getScheduleRequest: payload => state => ({
      ...state,
      loading: true,
      scheduleDate: payload,
    }),
    getScheduleSuccess: data => state => ({
      ...state,
      loading: false,
      schedule: data,
    }),
    getScheduleFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    getOrderScheduleRequest: () => state => ({
      ...state,
      loading: true,
    }),
    getOrderScheduleSuccess: data => state => ({
      ...state,
      loading: false,
      orderSchedule: data,
    }),
    getOrderScheduleFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
  },
  effects: {
    getSchedule: payload => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getScheduleRequest(payload));
      try {
        const {
          data: { data },
        } = await getOrdersSchedule(payload);
        dispatch(duckActions.getScheduleSuccess(data));
      } catch (error) {
        dispatch(duckActions.getScheduleFailure(error));
      }
    },
    getOrderSchedule: payload => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getOrderScheduleRequest());
      try {
        const {
          data: { data },
        } = await getOrderSchedule(payload);
        dispatch(duckActions.getOrderScheduleSuccess(data));
      } catch (error) {
        dispatch(duckActions.getOrderScheduleFailure(error));
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
    getSchedule: (getState, createSelector) => createSelector([getState], s => s.schedule),
    getOrderSchedule: (getState, createSelector) => createSelector([getState], s => s.orderSchedule)
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
