import React from 'react';
import { toast } from 'react-toastify';
import { Toast } from 'components/toasts';

const dcToast = (props = {}) => {
  const { options, ...rest } = props;
  toast(<Toast {...rest} />, options);
};

export default dcToast;
