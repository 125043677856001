import {
  getUserNotifications,
  setUserNotifications,
  setUserNotification,
  setUserNotificationChecked,
} from 'api/userNotifications.api';
import { createDuck } from './utils/createDuck';
import { paginationResponseParse } from './utils/paginationParse';

export const options = {
  name: 'userNotifications',
  initialState: {
    userMenuNotifications: [],
    userNotifications: [],
    notificationsCount: 0,
    unreadCount: 0,
    errorMessage: '',
    isLoading: false,
    // pagination: {
    //   offset: 1,
    //   limit: 10,
    // },
  },
  actions: {
    getUserNotificationsRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getUserNotificationsSuccess: ({ items, unreadCount, count, userMenuNotifications, }) => state => ({
      ...state,
      [userMenuNotifications ? 'userMenuNotifications' : 'userNotifications']: items,
      unreadCount,
      notificationsCount: count,
      isLoading: false,
    }),
    getUserNotificationsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    setUserNotificationsRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    setUserNotificationsSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    setUserNotificationsFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    setUserNotificationRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    setUserNotificationSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    setUserNotificationFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      isLoading: false,
    }),
    // setPagination: pagination => state => ({ ...state, pagination }),
  },
  effects: {
    getUserNotifications: ({ sorting, pagination, setPagination, userMenuNotifications = false, }) => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.getUserNotificationsRequest());
      try {
        const { data = {} } = await getUserNotifications({ ...sorting, ...pagination });
        dispatch(duckActions.getUserNotificationsSuccess({ ...data, userMenuNotifications, }));

        paginationResponseParse({ response: data, pagination, setPagination, });
      } catch (error) {
        dispatch(duckActions.getUserNotificationsFailure(error));
      }
    },
    setUserNotifications: () => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.setUserNotificationsRequest());
      try {
        await setUserNotifications();
        dispatch(duckActions.setUserNotificationsSuccess());
        await dispatch(duckEffects.getUserNotifications());
      } catch (error) {
        dispatch(duckActions.setUserNotificationsFailure(error));
      }
    },
    setUserNotification: notificationId => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.setUserNotificationRequest());
      try {
        await setUserNotification(notificationId);
        dispatch(duckActions.setUserNotificationSuccess());
        await dispatch(duckEffects.getUserNotifications());
      } catch (error) {
        dispatch(duckActions.setUserNotificationFailure(error));
      }
    },
    setUserNotificationChecked: notificationId => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.setUserNotificationRequest());
      try {
        await setUserNotificationChecked(notificationId);
        dispatch(duckActions.setUserNotificationSuccess());
        await dispatch(duckEffects.getUserNotifications());
      } catch (error) {
        dispatch(duckActions.setUserNotificationFailure(error));
      }
    },
  },
  selectors: {
    getIsLoading: (getState, createSelector) => createSelector([getState], s => s.isLoading),
    getError: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    getUserNotifications: (getState, createSelector) => createSelector([getState], s => s.userNotifications),
    getUserMenuNotifications: (getState, createSelector) => createSelector([getState], s => s.userMenuNotifications),
    getUserUnreadCount: (getState, createSelector) => createSelector([getState], s => s.unreadCount),
    getNotificationsCount: (getState, createSelector) => createSelector([getState], s => s.notificationsCount),
    // getPagination: (getState, createSelector) => createSelector([getState], s => s.pagination),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
