import { fetchBrigades, createBrigade, deleteBrigade } from 'api/brigades.api';
import { toast } from 'react-toastify';
import { createDuck } from './utils/createDuck';

import { effects as employeesEffects } from './employees.duck';
import { effects as modalEffects } from './modal.duck';

export const options = {
  name: 'brigades',
  initialState: {
    brigades: [],
    count: 0,
    errorMessage: '',
    loading: false,
  },
  actions: {
    fetchBrigadesRequest: () => state => ({
      ...state,
      loading: true,
    }),
    fetchBrigadesSuccess: ({ items, count }) => state => ({
      ...state,
      loading: false,
      brigades: items,
      count,
    }),
    fetchBrigadesFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    createBrigadeRequest: () => state => ({
      ...state,
      loading: true,
    }),
    createBrigadeSuccess: data => state => ({
      ...state,
      brigades: [
        ...state.brigades,
        data
      ],
      loading: false,
    }),
    createBrigadeFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
    deleteBrigadeRequest: () => state => ({
      ...state,
      loading: true,
    }),
    deleteBrigadeSuccess: id => state => ({
      ...state,
      brigades: state.brigades.filter(item => item.id !== id),
      loading: false,
    }),
    deleteBrigadeFailure: ({ message }) => state => ({
      ...state,
      errorMessage: message,
      loading: false,
    }),
  },
  effects: {
    fetchBrigades: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchBrigadesRequest());
      try {
        const response = await fetchBrigades();
        dispatch(duckActions.fetchBrigadesSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchBrigadesFailure(error));
      }
    },
    createBrigade: data => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.createBrigadeRequest());
      try {
        const response = await createBrigade(data);
        dispatch(duckActions.createBrigadeSuccess(response.data));
        toast.success('Бригада создана');
      } catch (error) {
        dispatch(duckActions.createBrigadeFailure(error));
      }
    },
    deleteBrigade: ({ id, onSuccessAction, ...data }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.deleteBrigadeRequest());
      try {
        const { success } = await deleteBrigade(id, data);
        if (success) {
          dispatch(duckActions.deleteBrigadeSuccess(id));
          await dispatch(employeesEffects.doGetEmployees());
          toast.success('Бригада удалена');
          dispatch(modalEffects.closeModal());
          if (onSuccessAction) onSuccessAction(data.newBrigadeId);
        } else throw new Error('Произошла какая-то ошибка')
      } catch (error) {
        dispatch(duckActions.deleteBrigadeFailure(error));
      }
    },
  },
  selectors: {
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.loading),
    getBrigades: (getState, createSelector) => createSelector([getState], s => s.brigades),
    getCount: (getState, createSelector) => createSelector([getState], s => s.count),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
