import React from 'react';
import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
// Components
import Icon from 'components/UI/Icon';
import Button from 'components/UI/Button';
// Styles
import { media } from 'styles';

const SideMenuBranches = ({ company, changeBranch, close, branches }) => {
  const { push } = useHistory();

  if (!company) {
    return null;
  }

  const handleChangeBranch = branch => {
    close();
    changeBranch({ branch, });
  }

  return (
    <Container>
      <SimpleBar>
        <BackButton onClick={close} type="button">
          <Icon icon="mi-close" size="24" />
        </BackButton>

        <List>
          <li>
            <Item style={{ padding: '15px 25px 10px', background: '#f2f2f2' }}>
              <ItemValue>{get(company, 'name', '-')}</ItemValue>
              <ItemName>Текущий филиал</ItemName>
              <ItemValue>{get(company, 'addresses.cityName')}</ItemValue>
              <ItemName>Город</ItemName>
            </Item>
          </li>
          {branches
            .filter(el => el.id !== company.id)
            .map(el => (
              <li key={el.id} style={{ position: 'relative' }}>
                {el.companyOwnerId === null && (
                  <MainCompanyIcon>
                    <Icon icon="mi-star" size="22" color="orange" />
                  </MainCompanyIcon>
                )}
                <hr style={{ margin: '0 25px' }} />
                <BranchButton
                  type="button"
                  onClick={() => handleChangeBranch(el)}
                  style={{ width: '100%' }}
                >
                  <Item>
                    <ItemValue>{el.name || ''}</ItemValue>
                    <ItemName>Название</ItemName>
                    <ItemValue>{el.city || ''}</ItemValue>
                    <ItemName>Город</ItemName>
                  </Item>
                </BranchButton>
              </li>
            ))}
          <li>
            <Item>
              <Button
                buttonStyle="inverted"
                onClick={() => {
                  close();
                  push('/new-branch');
                }}
              >
                <Icon icon="mi-add" size="20" />
                Добавить филиал
              </Button>
            </Item>
          </li>
        </List>
      </SimpleBar>
    </Container>
  );
};

export default SideMenuBranches;

const Container = styled.div`
  position: absolute;
  overflow: scroll;
  top: -86px;
  z-index: 999;
  padding-top: 20px;
  height: 100vh;
  width: 100%;
  background-color: white;
  color: ${({ theme }) => theme.color.black};

  ${media.MD_DOWN} {
    top: 0;
    padding-top: 0;
  }
`;

const BackButton = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 1;
`;

const List = styled.ul`
  padding-bottom: 30px;

  li {
    margin-bottom: 10px;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 25px;
  white-space: nowrap;
`;

const ItemValue = styled.strong`
  display: inline-block;
  margin: 6px 25px 6px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  text-align: left;
`;

const ItemName = styled.span`
  color: ${({ theme }) => theme.color.gray};
  text-align: left;

  & span {
    color: ${({ theme }) => theme.color.black};
  }
`;

const BranchButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 0;
  padding: 10px 0;

  &:hover {
    background-color: ${({ theme }) => theme.color.whiteSmoke};
  }
`;

const MainCompanyIcon = styled.div`
  position: absolute;
  top: 22px;
  right: 25px;
  z-index: 1;
`;
