import { account } from 'utils/axiosInstances';

const URL = '/roles';

export const fetchRoles = async () => {
  try {
    const response = await account.get(URL);
    return response.data.items;
  } catch ({ response }) {
    throw new Error('Что-то пошло не так, попробуйте позже', response.status);
  }
};

export const editRole = async (roleId, payload) => {
  try {
    const response = await account.put(`${URL}/${roleId}`, payload);
    return response;
  } catch ({ error }) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};

export const allowRoles = async () => {
  try {
    const response = await account.put(`${URL}/all/allow`,);
    return response;
  } catch ({ error }) {
    const { response } = error;
    throw new Error(response.message, response.status);
  }
};
