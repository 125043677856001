export const downloadFile = (objUrl, name) => {
  const link = document.createElement('a');
  link.href = objUrl;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click()
  document.body.removeChild(link);
}

export const printDocument = objUrl => {
  const printFrame = document.createElement('iframe');
  printFrame.src = objUrl;
  printFrame.style.display = 'none';
  printFrame.onload = () => {
    printFrame.contentWindow.focus();
    printFrame.contentWindow.print();
  };
  document.body.appendChild(printFrame);
}