import { hot } from 'react-hot-loader/root';
import React from 'react';
import { connect } from 'react-redux';

import {
  effects as modalEffects,
  selectors as modalSelectors,
} from 'redux/ducks/modal.duck';

import Modal from './Modal';

const ModalContainer = props => {
  return <Modal {...props} />;
};

const mapStateToProps = state => ({
  ...modalSelectors.getModal(state),
});

const mapDispatchToProps = {
  ...modalEffects,
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(ModalContainer));
